import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { Dialog, WhiteButton } from '../../../shared_components'
import t from '../../../config/translate'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

function ErrorImportImagesDialog(props) {

    const { setOpen, data, info } = props
    

    const columns = [
        {
            headerStyle: (colum, colIndex) => {
                return { verticalAlign: 'initial' };
            },
            sort: true,
            editable: false,
            dataField: 'error',
            formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (<div contentEditable='true' dangerouslySetInnerHTML={{ __html: value.error }}></div>),
            text: t('error_import_dialog.error_message')
        },
    ]

    return (
        <Dialog
            setOpen={setOpen}
            Children={(
                <div>
                    <div className='dialog_title_container'>
                        <WhiteButton className='close_button'
                            type='button'
                            onClick={() => setOpen(false)}
                            icon={'close-outline'} />
                        <h3 style={{textTransform: 'lowercase'}} className='dialog_title'>{`${info.notImportedTotal} ${t('error_import_image_dialog.title')}`}</h3>
                    </div>

                    <div className='dialog_content_container'>
                        <BootstrapTable
                            keyField="id"
                            data={data}
                            columns={columns}
                            striped
                            pagination={paginationFactory()}
                        />
                    </div>
                </div>



            )} />
    )
}

export default ErrorImportImagesDialog