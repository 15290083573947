/* eslint-disable import/no-anonymous-default-export */
import base from './base'

export default {
	getEstoque(companyID, page = 1, perPage = 10) {
		return base.get(`/api-con/company/${companyID}/reports/saleitemspospgroupposuserfull?pag=${page}&range=${perPage}`)
	},
	getProductEstoque(companyID, productID) {
		return base.get(`/api-con/company/${companyID}/reports/saleitemspospgroupposuserfull/product?product=${productID}`)
	},
	insertStock(companyID, data) {
		return base.post(`/api-con/company/${companyID}/insertStock`, data)
	}
}