/* eslint-disable import/no-anonymous-default-export */
import base from './base'

export default {
	getIngressPassword(companyGroupID) {
		return base.get(`/api-con/companygroupID/${companyGroupID}/productGroup`)
	},
	updateSeedHash(companyGroupID, data) {
		return base.put(`/api-con/companygroupID/${companyGroupID}/productGroup/Seedhash`, data)
	}
}