/* eslint-disable array-callback-return */
import moment from 'moment'

export function formatReportSalesPerGroup(data) {

	const groups = [...new Set(data.map((d) => d.ProductGroupName))]
	let result = []
	let auxTotal = 0
	groups.map((g) => {
		let auxGroup = [{
			ProductGroupName: g,
			ProductName: "",
			Quantidade: "",
			Total: '',
			Unidade: "",
			Unitario: '',
			bold: true,
		}]
		auxGroup = auxGroup.concat(data.filter((d) => d.ProductGroupName === g))
		let auxGroupTotal = auxGroup.reduce((a, b) => {
			if (b.Total) {
				return a + (b.Total || 0)
			}
			return a + 0
		}, 0)
		auxGroup = auxGroup.concat([{
			ProductGroupName: "",
			ProductName: "",
			Quantidade: "",
			Total: auxGroupTotal,
			Unidade: "",
			Unitario: 'Subtotal',
			bold: true,
		}])
		auxTotal += auxGroupTotal
		result = result.concat(auxGroup)
	})
	result = result.concat({
		ProductGroupName: "",
		ProductName: "",
		Quantidade: "",
		Total: auxTotal,
		Unidade: "",
		Unitario: 'Total',
		bold: true,
	})
	return result

}

export function formatReportSalesPerDay(data) {
	let days = {
		0: 'Dom',
		1: 'Seg',
		2: 'Ter',
		3: 'Qua',
		4: 'Qui',
		5: 'Sex',
		6: 'Sab'
	}

	let result = []

	let auxGross = 0
	let auxDiscount = 0
	let auxLiquid = 0
	let auxSales = 0
	let auxItems = 0
	let auxQuantity = 0
	let auxAcumulated = 0
	let auxAvgTicket = 0
	let auxCanceled = 0

	data.map((g) => {
		auxGross += g.Bruto || 0
		auxDiscount += g.Desconto || 0
		auxLiquid += g.Liquido || 0
		auxSales += g.Vendas || 0
		auxItems += g.ItensVendidos || 0
		auxQuantity += g.Quantidade || 0
		auxAvgTicket += g.TicketMédio || 0
		auxAcumulated += g.Acumulado || 0
		auxCanceled += g.VendasCanceladas || 0
		if (g.Data) {
			let auxDate = new Date(g.Data)
			auxDate.setDate(auxDate.getDate() + 1)
			g.Data = moment(auxDate).format('DD/MM/YYYY')
			g.WeekDay = days[auxDate.getDay()]
		} else {
			g.Data = ''
			g.WeekDay = ''
		}


		result = result.concat([g])
	})

	result = result.concat({
		"Data": "",
		"Liquido": auxLiquid,
		"Desconto": auxDiscount,
		"Vendas": auxSales,
		"ItensVendidos": auxItems,
		"Quantidade": auxQuantity,
		"Bruto": auxGross,
		"Acumulado": auxAcumulated,
		"TicketMédio": auxAvgTicket,
		"VendasCanceladas": auxCanceled,
		'WeekDay': '',
		bold: true,
	})

	return result

}

export function formatClosingRegister(data) {
	let result = []

	data.map((d, key) => {
		result = result.concat([{ ...d, uniqueTableKey: key }])
	})

	return result
}

export function formatReportBillingDowloaderProductsPerUser(data) {
	const result = formatClosingRegister(data).map(({ QTInv, Unitario, ...rest }) => ({ ...rest, QTInv, Unitario, Total: QTInv * Unitario }))

	return result
}

export function formatReportDetailedSales(data) {

	let result = []
	data.map((d, key) => {

		result = result.concat({
			'PropertyNumber': d.PropertyNumber,
			'Data': moment(d.DataHoraJS).format('DD/MM/YYYY'),
			'Hora': moment(d.DataHoraJS).format('HH:mm'),
			'ProductName': d.SaleItems ? d.SaleItems[0].Name : '',
			'ProductUnitType': 'Un',
			'ProductAmount': d.SaleItems ? d.SaleItems[0].Quantity : '',
			'ProductPrice': d.SaleItems ? d.SaleItems[0].UnitPrice : '',
			'Total': d.SaleItems ? d.SaleItems[0].PriceAmount : '',
			'bold': false,
			id: key,
		})

		if (d.SaleItems && d.SaleItems.length > 1) {
			d.SaleItems.shift()
			d.SaleItems.map((i, key2) => {
				result = result.concat({
					'PropertyNumber': '',
					'Data': '',
					'Hora': '',
					'ProductName': i.Name,
					'ProductUnitType': 'Un',
					'ProductAmount': i.Quantity,
					'ProductPrice': i.UnitPrice,
					'Total': i.PriceAmount,
					'bold': false,
					id: key + '_item' + key2
				})
			})
		}

		result = result.concat({
			'PropertyNumber': '',
			'Data': '',
			'Hora': '',
			'ProductName': '',
			'ProductUnitType': '',
			'ProductAmount': 'Pagamento',
			'ProductPrice': d.SalePaymentType ? d.SalePaymentType[0].Name : '',
			'Total': d.SalePaymentType ? d.SalePaymentType[0].PaidAmount : '',
			'bold': true,
			id: key + '_-1'
		})

		if (d.SalePaymentType.length > 1) {
			d.SalePaymentType.shift()
			d.SalePaymentType.map((i, key3) => {
				result = result.concat({
					'PropertyNumber': '',
					'Data': '',
					'Hora': '',
					'ProductName': '',
					'ProductUnitType': '',
					'ProductAmount': '',
					'ProductPrice': i.Name,
					'Total': i.PaidAmount,
					'bold': true,
					id: key + '_0' + key3
				})
			})
		}

		result = result.concat({
			'PropertyNumber': '',
			'Data': '',
			'Hora': '',
			'ProductName': '',
			'ProductUnitType': '',
			'ProductAmount': '',
			'ProductPrice': '',
			'Total': '',
			'bold': false,
			id: key + '_1'
		})
	})

	result.pop()

	return result

}

export function formatCurrency(number, locale = 'pt-br', currency = 'BRL') {
	if (number === '') { return '' }
	if (!number) { return '   0,00' }
	return Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(number)
}

export function formatCurrencyWithoutSymbol(number, locale = 'pt-br') {
	if (number === '') { return '' }
	if (!number) { return '0,00' }
	return (number).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

export function formatQuantity(number, unitType, locale = 'pt-br') {
	if (number === '') { return '' }
	if (!number) {
		if (unitType.toUpperCase() === "KG") return '0,000'
		return '0'
	}
	if (unitType.toUpperCase() === "KG") return (number).toLocaleString('locale', { maximumFractionDigits: 3, minimumFractionDigits: 3 })
	return (number).toLocaleString('locale', { maximumFractionDigits: 0, minimumFractionDigits: 0 })
}

export function formatProductRevenue(data) {
	let result = {
		totalSales: data.qtdVendas,
		totalRevenue: data.valorTotal,
		products: data.itens,
	}
	return result
}

export const formatPendingData = (data) => {
	let result = {
		sales: data.reduce((a, b) => {
			if (b.QTDSalesNotSynced) {
				return a + (b.QTDSalesNotSynced || 0)
			}
			return a + 0
		}, 0),
		total: data.reduce((a, b) => {
			if (b.ValSalesNotSynced) {
				return a + (b.ValSalesNotSynced || 0)
			}
			return a + 0
		}, 0),
		pos: data.length,
		data: data
	}

	return result
}

export const formatErrorProductTable = (data) => {

	return data.map((d, key) => ({
		id: key,
		error: d.error,
		column: d.column,
		row: d.row
	}))
}
// export const formatErrorProductTable = (data) => {

// 	let result = []

// 	data.map((d, key1) => {
// 		d.validacao.errors && d.validacao.errors.map((e, key2) => {
// 			result = result.concat({
// 				id: key1 + key2 + e.errorMessage + d.linha,
// 				error: e.errorMessage,
// 				column: e.formattedMessagePlaceholderValues.propertyName,
// 				row: d.linha
// 			})
// 		})
// 	})

// 	return result
// }


export const formatErrorProductImages = (data) => {

	let result = []

	data.map((d, key1) => {
		d.validacao.errors && d.validacao.errors.map((e, key2) => {
			result = result.concat({
				id: key1 + key2 + e.errorMessage + d.name,
				error: e.errorMessage,
				column: e.propertyName,
			})
		})
	})

	return result
}

export const formatSpacedCNPJ = (val = '') => {

	if (val.length <= 11) {
		return val.replace(/\D/g, '')
			.replace(/(\d{3})(\d)/, '$1 $2')
			.replace(/(\d{3})(\d)/, '$1 $2')
			.replace(/(\d{3})(\d{1,2})/, '$1 $2')
			.replace(/( \d{2})\d+?$/, '$1')
	} else if (val.length <= 14) {
		return val.replace(/\D/g, '')
			.replace(/(\d{2})(\d)/, '$1 $2')
			.replace(/(\d{3})(\d)/, '$1 $2')
			.replace(/(\d{3})(\d)/, '$1 $2')
			.replace(/(\d{4})(\d)/, '$1 $2')
			.replace(/(\d{2})\d+?$/, '$1')
	} else if (val.length <= 18) {
		return val.replace(/\D/g, '')
			.replace(/(\d{2})(\d)/, '$1 $2')
			.replace(/(\d{3})(\d)/, '$1 $2')
			.replace(/(\d{3})(\d)/, '$1 $2')
			.replace(/(\d{6})(\d{1,2})/, '$1 $2')
			.replace(/(\d{4})(\d)/, '$1 $2')


	}

}

export const formatTerminalPassword = (val) => {
	let key = ''
	for (let position in val) {
		key += (position % 2 === 0 && position !== 0) ? ` ${val[position]}` : val[position]
	}
	return key.trim()
}