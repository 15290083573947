import Styles from 'styled-components';

export const Container = Styles.div`
  height: 500px;
  width: 85%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 4px;
  box-shadow: 5px 5px 25px gray;
`;

export const Title = Styles.div`
  margin-top: 10%;
  font-size: 24px;
  font-weight: bold;
  padding: 5px;
  margin-left: 30px;

`;

export const Image = Styles.img`
    width:100%;
    height:100%;
    border-radius: 4px;

`;

export const InputFile = Styles.input`
  width:100%;
  height:100%;
  border: 1px solid red;
  background: #000;

`;
export const LabelFile = Styles.label`
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 5px;
  background: #00a9d4;
  color: #fff;
  border-radius: 4px;
  &:hover {
    opacity: 0.6;
  }
`;

export const DivForm = Styles.div`
  display: flex;
  flex-direction: row;
  width: 96%;
  height: 390px;
  border-radius: 4px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5px;
`;

export const DivFile = Styles.div`
  width: 23%;
  height: 60%;
  margin: 10px;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 4px;

`;
export const InputsDiv = Styles.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 6fr);
  margin-top: 8px;
`;

export const ButtonSubmit = Styles.button`
  margin-left: 16px;
  width: 100px;
  height: 70%;
  font: 150px;
  text-align: center;
`;

export const CheckDiv = Styles.div`
  display:flex;
  font-size: 14px;
  margin-top: 5px;
`;

export const Subtitles = Styles.p`
  font-size: 78%;
  font-weight: bold;
`;

export const Error = Styles.div`
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3%;
  margin-bottom: -3%;
`;