import React, { Component } from "react";
import { DropdownInput } from "../../../shared_components";

class TableEditDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: "",
    };
  }

  componentDidMount() {
    const { items, outputKey } = this.props;
    this.setPreview(items.filter((i) => i[outputKey] === this.props.value)[0]);
  }

  setPreview = (val) => {
    this.setState({ preview: val });
  };

  render() {
    const { onUpdate, items, itemKey, outputKey, auxKey, isLastElement } =
      this.props;
    const { preview } = this.state;
    return (
      <DropdownInput
        isLastElement={isLastElement}
        value={preview}
        auxKey={auxKey}
        onChange={(i) => onUpdate(i[outputKey])}
        items={items}
        itemKey={itemKey}
      />
    );
  }
}

export default TableEditDropdown;
