/* eslint-disable import/no-anonymous-default-export */
import base from './base'

export default {
	getGroups(companyGroupID) {
		return base.get(`/api-con/companygroup/${companyGroupID}/productgroups`)
	},
	editGroup(companyGroupID, ProductGroupID, field, value) {
		return base.put(`/api-con/companygroup/${companyGroupID}/productgroups`, {
			ProductGroupID,
			[field]: value,
		})
	},
	createGroup(companyGroupID, StatusID, Name, ImageGroupBase64) {
		return base.post(`/api-con/companygroup/${companyGroupID}/productgroups`, {
			Name,
			StatusID,
			ImageGroupBase64,
		})
	}
}