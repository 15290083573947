import React from 'react'
import {Container, Background, DialogPopUp} from './styles'

export default function Dialog({Children, setOpen}){
    return (
        <Container>
            <Background onClick={() => setOpen(false)}/>
            <DialogPopUp>
                {Children}
            </DialogPopUp>
        </Container>
    )
}