import styled from 'styled-components'

export const Container = styled.button`
    border-radius: var(--border-radius-large);
    border: none;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    font-weight: var(--font-weigth-large);
    font-family: var(--font-family-main);
    background-color: var(--color-light);
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    ion-icon {
        color: var(--color-secondary);
        font-size: 22px;
    }

    img {
        max-height: 30px;
        margin-right: 10px;
    }

    &:hover {
        cursor: pointer;
        transition: 0.3s ease;
        opacity: 0.8;
    }
    &:active {
        user-select: none;
        transition: 0.3s ease;
        opacity: 0.6;
    }
`