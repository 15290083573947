/* eslint-disable import/no-anonymous-default-export */
import base from './base'

export default {
	getQrConfig(companyID) {
		return base.get(`/api-con/company/${companyID}/qrcodecardapio`)
	},
	saveQrConfig(companyID, logo, qrValue) {
		return base.put(`/api-con/company/${companyID}/qrcodecardapio`, {
			"LogoCardapioBase64": logo,
			'URLPed': qrValue,
		})
	},
	getPrintConfig(companyID) {
		return base.get(`/api-con/company/${companyID}/pospdvconfig?id=13&id=5&id=21&id=6&id=7&id=8`)
	},
	savePrintConfig(companyID, data) {
		return base.put(`/api-con/company/${companyID}/pospdvconfig`, data)
	},
	getUsers(companyID) {
		return base.get(`/api-con/company/${companyID}/usersconfig`)
	},
	updateUser(companyID, userID, field, value) {
		return base.put(`/api-con/company/${companyID}/usersconfig/${userID}`, {
			[field]: value
		})
	},
	getTerminalInfo(companyID) {
		return base.get(`/api-con/company/${companyID}/info`)
	},
	getTheme(host) {
		if (process.env.NODE_ENV === 'development') {
			return base.get(`/api-con/themeconfig?s=app.redegestaofacil.com.br`)
			// return base.get(`/api-con/themeconfig?s=dataoffice.pdv.mobi`)
		}
		return base.get(`/api-con/themeconfig?s=${host.replace(/.v\d+/, '')}`)
	},
	getAcessLevel() {
		return base.get(`/api-con/accesslevel`)
	}
}