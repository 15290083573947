import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
`

export const Background = styled.div`
    min-height: 100vh;
    background: lightgray;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100vw;
    height: 100%;
    z-index: 998;
`

export const PopUp = styled.div`
    min-width: 300px;
    min-height: 200px;
    background: white;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    .bar {
        height: 10px;
        width: 100%;
        
        .in {
          animation: fill 10s linear 1;
          height: 100%;
          background: var(--color-accent-gradient);
        }
      }
      
    @keyframes fill {
        0% {
          width: 100%;
        }
        100% {
          width: 0%;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 0 40px;
        justify-content: center;
        align-items: center;
        flex: 1;
        
        p {
          font-size: 24px;
        }

        button {
          background: var(--color-primary);
          box-shadow: none;
          border: none;
        }
    }
`