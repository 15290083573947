import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useSelector } from "react-redux"
import { Table, Toast } from "../../../shared_components"
import { Container } from "./styles"
import { Nav, TopNav } from "../../components"
import t from "../../../config/translate"
import estoqueAPI from "../../../services/estoque"
import { formatQuantity } from "../../../utils/formatters"

function Report() {
	const whitelabel_name = useSelector((state) => state.theme.whitelabel_name)
	const pageTitle = t("report.stock")
	const companyID = useSelector((state) => state.auth.authedStore.CompanyID)
	const [data, setData] = useState([])
	const [perPage, setPerPage] = useState(10)
	const [totalRows, setTotalRows] = useState(10)
	const [perPageOptions, setPerPageOptions] = useState([5, 10, 15, 20])
	const [loading, setLoading] = useState(false)

	const fetchData = (companyID, page = 0, perPage = 10) => {
		setLoading(true)
		estoqueAPI.getEstoque(companyID, page, perPage)
			.then((res) => res.data)
			.then(data => {
				setData(data)
				setTotalRows(data[0].totalRows)

				const hasQtdInOptions = perPageOptions.find(q => q === data[0].totalRows)
				if (!hasQtdInOptions) setPerPageOptions([5, 10, 15, 20, data[0].totalRows])

				setLoading(false)
			})
			.catch((err) => {
				setLoading(false)
				Toast('Atenção', 'Tente novamente mais tarde!', 'info')
				console.error("[EstoqueReport]", err.message)
			})
	}

	useEffect(() => {
		let componentMounted = true
		if (componentMounted) fetchData(companyID)
	}, [companyID])

	const handlePageChange = (page) => {
		fetchData(companyID, page, perPage)
	}
	const handlePerRowsChange = async (newPerPage, page) => {
		// console.log(newPerPage, page)
		fetchData(companyID, page, newPerPage)
		setPerPage(newPerPage)
	}
	const createRow = (row, field) => (
		<div className="table_row">{formatQuantity(row[field], row.UnitType)}</div>
	)

	const search = {
		show: true,
		searchPlaceHolder: t("search"),
		searchButtonClearText: t("clear"),
	}
	const colums = React.useMemo(
		() => [
			{
				name: <div className="table_header">Produto</div>,
				selector: (row) => row["ProductName"],
				cell: (row) => createRow(row, "ProductName"),
				sortable: true,
			},
			{
				name: <div className="table_header">Grupo</div>,
				selector: (row) => row["ProductGroupName"],
				cell: (row) => createRow(row, "ProductGroupName"),
				sortable: true,
			},
			{
				name: <div className="table_header">Quantidade</div>,
				selector: (row) => row["Quantity"],
				cell: (row) => createRow(row, "Quantity"),
				sortable: true,
			},
		],
		[]
	)

	return (
		<Container>
			<Helmet>
				<title>
					{pageTitle} | {whitelabel_name}
				</title>
			</Helmet>
			<Nav />
			<div className="content_container">
				<TopNav pageTitle={pageTitle} />
				<div className="form_container">
					<Table
						data={data}
						colums={colums}
						title={t("nav.estoque")}
						search={search}
						paginationServer={true}
						onChangePage={handlePageChange}
						onChangeRowsPerPage={handlePerRowsChange}
						totalRows={totalRows}
						perPageOptions={perPageOptions}
						loading={loading}
					/>
				</div>
			</div>
		</Container>
	)
}

export default Report
