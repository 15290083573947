/* eslint-disable import/no-anonymous-default-export */
import base from './base'

export default {
	updateAllPosPdvConfig(companyID, payload) {
		return base.put(`/api-con/company/${companyID}/pospdvconfig/updateAll`, payload)
	},
	getAllPosPdvConfig(companyID) {
		return base.get(`/api-con/company/${companyID}/pospdvconfig/all`)
	},
	getPosPdvConfig(companyID, id) {
		return base.get(`/api-con/company/${companyID}/pospdvconfig/keyNumber?${id}`)
	}
}