import pt_br from './pt-br'
import {store} from '../store'

export const availableLanguages = [
    {name: 'Português', id: 'pt'},
]

const texts = {
    'pt': {...pt_br},
}


function translate(key) {
    const {language} = store.getState()
    
    const lang = language || navigator.language

    let auxTranslated = texts[lang] ? texts[lang][key] : pt_br[key]

    if(auxTranslated)  {
        return auxTranslated
    } else  {
        return 'Error translating this, we are sorry.'
    }

}

export default translate