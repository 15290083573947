import React, { useState, useEffect, useCallback } from 'react'
import { AddLogo } from '../../assets/images'
import Toast from '../toast'
import t from '../../config/translate'
import { Button } from '../'

function PhotoInput(props) {

	const {
		setPreview,
		preview,
		styles,
		right,
		className,
		noInfo,
		width = 300,
		height = 300,
		textStyles
	} = props

	//For the image
	const [selectedFile, setSelectedFile] = useState()

	const getBase64 = useCallback((file) => {
		let reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = function () {
			setPreview(reader.result.substring(22))
		}
		reader.onerror = function (error) {
			// console.log('Error on getBase64: ', error);
		}
	}, [setPreview])

	useEffect(() => {
		if (!selectedFile && preview === null) {
			setPreview(undefined)
			return
		} else if (selectedFile) {
			getBase64(selectedFile)
		}

	}, [selectedFile, preview, setPreview, getBase64])

	const onSelectFile = e => {

		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined)
			return
		}
		// I've kept this example simple by using the first image instead of multiple
		let file = e.target.files[0]
		let fileName = e.target.files[0].name
		let idxDot = fileName.lastIndexOf(".") + 1
		let extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
		if (extFile === "png") {
			let reader = new FileReader()
			reader.readAsDataURL(e.target.files[0])
			reader.onload = function (e) {
				let image = new Image()
				image.src = e.target.result
				image.onload = function () {
					let heightA = this.height
					let widthA = this.width
					if (heightA > width || widthA > height) {
						Toast('', t('register.error.img_too_large'), 'danger')
						document.getElementById("upload-photo").value = ''
						return false
					} else {
						setSelectedFile(file)
						return true
					}
				}
			}

		} else {
			Toast('', t('register.error.img_unsupported'), 'danger')
			document.getElementById("upload-photo").value = ''
		}
	}


	const removeImage = () => {
		setPreview(undefined)
		setSelectedFile()
	}

	return (
		<div>
			<div style={{ ...styles }} className={`${className} image_selector`}>

				<div className='logo_container' style={{ order: right ? 2 : 1 }}>

					<label className='select_image' htmlFor="upload-photo">
						{preview ?
							<img id='logo' src={"data:image/png;base64," + preview} alt={'Imagem'} /> :
							<img className='add_photo' src={AddLogo} alt='Add' />}
					</label>

					<input type="file" accept="image/*" name="photo" onChange={onSelectFile} id="upload-photo" />

				</div>

				{!noInfo &&
					<p style={{ color: 'rgba(0,0,0, 0.54)', fontSize: '14px', margin: '20px 10px 0', order: right ? 1 : 2, ...textStyles }}>
						Altura Max: {width}px<br />
						Largura Max: {height}px<br />
						Tipo: PNG<br />
						<br />{t('photo_picker.helper_message')}<br />
					</p>
				}

			</div>
			<Button onClick={() => removeImage()} text={t('print_config.removeImage')} />
			{/* <p style={{ margin: '0 0 20px' }}>{t('photo_picker.helper_message')}</p> */}
		</div>

	)
}

export default PhotoInput