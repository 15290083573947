import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    background: var(--color-background);
    font-family: var(--font-family-primary);

	.add_container{
		display: flex;
        align-items: center;
        justify-content: flex-start;
		padding: 10px;
	}

	.send_container{
		display: flex;
        align-items: center;
        justify-content: flex-start;
		padding: 10px;
	}

    .table_row{
        font-size: 14px;
    }

    .table_header{
        font-weight: var(--font-weight-medium);
        font-size: 13px;
		width: 135px;
    }

    .content_container{
        height: 100vh;
        overflow: auto;
        flex: 1;
    }

    .form_container {
        background: var(--color-light);
        padding: 20px;
        margin: 0 30px 30px 30px;
        border-radius: var(--border-radius-medium);
        box-shadow: var(--box-shadow-card);
        overflow: visible;
    }

	 .table_search_container {
        display: flex;
        flex: 1;
		justify-content: flex-end;
		margin: 0px 0px 30px 0px;
    }
`
export const GroupHeaderTable = styled.thead