/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Nav, TopNav } from '../../components'
import { Container } from './styles'
import reportsApi from '../../../services/reports'
import t from "../../../config/translate"
import { DateRange, Table } from '../../../shared_components'
import moment from "moment"
import generatePagOptions from '../../../utils/generatePageOptions'

function ReportCanceledSales() {
	const CompanyID = useSelector(state => state.auth.authedStore.CompanyID)
	const whitelabel_name = useSelector(state => state.theme.whitelabel_name)
	const pageTitle = 'Venda Canceladas'

	const [totalRows, setTotalRows] = useState(10)
	const [perPageOptions, setPerPageOptions] = useState([5, 10, 15, 20, 30, 40])
	const [loading, setLoading] = useState(false)

	const [dateIni, setDateIni] = useState(moment())
	const [dateEnd, setDateEnd] = useState(moment())
	const [perPage, setPerPage] = useState(10)
	const [sales, setSales] = useState([])

	const getSales = (CompanyID, dateIni, dateEnd, page, perPage) => {
		setLoading(true)
		const initialDateFormated = moment(dateIni).format("YYYY-MM-DD")
		const finalDateFormated = moment(dateEnd).format("YYYY-MM-DD")

		reportsApi.getCanceledSales(CompanyID, initialDateFormated, finalDateFormated, page, perPage)
			.then(rst => {
				setSales(rst.data.sales)
				setTotalRows(rst.data.totalRows)
				setPerPageOptions(generatePagOptions(rst.data.totalRows))
				setLoading(false)
			})
			.catch(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		getSales(CompanyID, dateIni, dateEnd, 1, perPage)
		return () => setSales([])
	}, [])

	const handlePageChange = (page) => {
		getSales(CompanyID, dateIni, dateEnd, page, perPage)
	}

	const handlePerRowsChange = async (newPerPage, page) => {
		getSales(CompanyID, dateIni, dateEnd, page, newPerPage)
		setPerPage(newPerPage)
	}

	const updateRange = () => {
		getSales(CompanyID, dateIni, dateEnd, 1, perPage)
	}

	const createRow = (row, field) => (
		<div className="table_row">{row[field]}</div>
	)
	const search = {
		show: true,
		searchPlaceHolder: t("search"),
		searchButtonClearText: t("clear"),
	}

	const colums = React.useMemo(
		() => [
			{
				name: <div className="table_header">POS</div>,
				selector: (row) => row["PropertyNumber"],
				cell: (row) => createRow(row, "PropertyNumber"),
				sortable: true,
			},
			{
				name: <div className="table_header">Data/Hora</div>,
				selector: (row) => row["DatetimeSale"],
				cell: (row) => createRow(row, "DatetimeSale"),
				sortable: true,
			},
			{
				name: <div className="table_header">Serie</div>,
				selector: (row) => row["serienf"],
				cell: (row) => createRow(row, "serienf"),
				sortable: true,
			},
			{
				name: <div className="table_header">Número</div>,
				selector: (row) => row["numnf"],
				cell: (row) => createRow(row, "numnf"),
				sortable: true,
			},
			{
				name: <div className="table_header">Cód. Venda</div>,
				selector: (row) => row["Quantity"],
				cell: (row) => createRow(row, "Quantity"),
				sortable: true,
			},
			{
				name: <div className="table_header">Produto</div>,
				selector: (row) => row["ProductName"],
				cell: (row) => createRow(row, "ProductName"),
				sortable: true,
			},
			{
				name: <div className="table_header">Quantidade</div>,
				selector: (row) => row["Quantity"],
				cell: (row) => createRow(row, "Quantity"),
				sortable: true,
			},
			{
				name: <div className="table_header">Preço</div>,
				selector: (row) => row["PriceAmount"],
				cell: (row) => createRow(row, "PriceAmount"),
				sortable: true,
			},
			{
				name: <div className="table_header">Total</div>,
				selector: (row) => row["AmntTotal"],
				cell: (row) => createRow(row, "AmntTotal"),
				sortable: true,
			},
		],
		[]
	)

	return (
		<Container>
			<Helmet><title>{pageTitle} | {whitelabel_name}</title></Helmet>
			<Nav />
			<div className='content_container'>
				<TopNav pageTitle={pageTitle} />
				<div className="form_container">
					<div className="table_search_container">
						<DateRange
							initialDate={dateIni}
							finalDate={dateEnd}
							setFinalDate={setDateEnd}
							setInitialDate={setDateIni}
							submit={updateRange}
						/>
					</div>

					<Table
						data={sales}
						colums={colums}
						title={'Vendas Canceladas'}
						search={search}
						paginationServer={true}
						onChangePage={handlePageChange}
						onChangeRowsPerPage={handlePerRowsChange}
						totalRows={totalRows}
						perPageOptions={perPageOptions}
						loading={loading}
					/>
				</div>
			</div>
		</Container>
	)
}

export default ReportCanceledSales