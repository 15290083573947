import {createGlobalStyle} from 'styled-components'

export default createGlobalStyle`
    :root {
        --color-primary: ${props => props.theme.colorPrimary};
        --color-secondary: ${props => props.theme.colorSecondary};
        --color-terciary: ${props => props.theme.colorTerciary};

        --color-accent: ${props => props.theme.colorAccent};
        --color-accent-secondary: ${props => props.theme.colorAccentSecondary};
        --color-accent-secondary-text: ${props => props.theme.colorAccentSecondaryText};
        --color-primary-gradient: linear-gradient(45deg, ${props => props.theme.colorPrimary}, ${props => props.theme.colorSecondary});
        --color-accent-gradient: linear-gradient(45deg, ${props => props.theme.colorAccent}, ${props => props.theme.colorAccentSecondary});

        --color-dark: rgba(0,0,0, 0.87);
        --color-dark-medium: rgba(0,0,0,0.54);
        --color-background: #F5F8F9;
        --color-light: #fff;
        --color-green: #31AE17;
        --color-red: #AE1717;
        --color-lightOpacity: rgba(255, 255, 255, 0.3);

        --box-shadow-card: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
        --box-shadow-nav: 0px 6px 10px 2px rgba(0, 0, 0, 0.06);

        --box-shadow-button-up: 0px 10px 20px 3px rgba(0, 0, 0, 0.20);
        --box-shadow-button-hover: 0px 5px 20px 3px rgba(0, 0, 0, 0.20);
        --box-shadow-button-acive: 0px 0px 20px 3px rgba(0, 0, 0, 0.20);

        --font-family-primary: Raleway, sans-serif;
        --font-family-secondary: Roboto, sans-serif;

        --border-radius-large: 12px;
        --border-radius-medium: 10px;
        --border-radius-small: 8px;
        
        --font-weight-extra-large: 700;
        --font-weight-large: 600;
        --font-weight-medium: 500;
        --font-weight-small: 400;
    }

`