import React, { useEffect, useState } from "react";
import { PieChart } from "../../../components";
import t from "../../../../config/translate";
import monitorAPI from "../../../../services/monitor";

export function PaymentType({ companyID, ranges }) {
  const [paymentTypesData, setPaymentTypesData] = useState([]);
  const [paymentTypesRange, setPaymentTypesRange] = useState("bynow");

  useEffect(() => {
    let componentMounted = true;

    monitorAPI
      .getMonitorData(companyID, "paymenttype", paymentTypesRange)
      .then((res) => res.data)
      .then((data) => componentMounted && setPaymentTypesData(data))
      .catch((err) => console.error("[PaymentType]", err.message));

    return () => {
      componentMounted = false;
    };
  }, [companyID, paymentTypesRange]);

  return (
    <PieChart
      data={paymentTypesData}
      title={t("monitor.graph_payment")}
      categoryX="XLine"
      valueY="AmntTotal"
      id={"paymenttype"}
      ranges={ranges}
      selectedRange={paymentTypesRange}
      setRange={(v) => setPaymentTypesRange(v)}
    />
  );
}
