/* eslint-disable import/no-anonymous-default-export */
import base from './base'

export default {
	get(companyGroupId) {
		return base.get(`/api-con/fiscal/${companyGroupId}`)
	},
	saveUpdate(dados, companyGroupId) {
		return base.post(`/api-con/fiscal/${companyGroupId}/save`, dados)
	},
	addDadosFiscais(dados, companyGroupId) {
		return base.post(`/api-con/fiscal/${companyGroupId}/add`, dados)
	}
}