import React, { useState, useEffect, Fragment } from 'react'
import {
	Dialog, TextInput, SelectDropdown, Button, WhiteButton,
	Dropdown, PhotoInput, CurrencyInput, Toast, DropdownInput,
} from '../../../shared_components'
import productsAPI from '../../../services/products'
import { units, status } from '../../../utils/constants'
import t from '../../../config/translate'
import dadosFiscais from '../../../services/dadosFiscais'

function AddProductDialog(props) {
	const { setOpen, companies, groups, companyID, companyGroupID, addProduct, nfcType, atualizaTable } = props

	//For the image
	const [preview, setPreview] = useState()
	const [selectedCompanies, setSelectedCompanies] = useState([])
	const [companyPrices, setCompanyPrices] = useState([])
	const [unit, setUnit] = useState(units()[0])
	const [group, setGroup] = useState('')
	const [dados, setDados] = useState([])
	const [selecionado, setSelecionado] = useState({})
	
	const getDadosFiscais = () => {
		dadosFiscais.get(companyGroupID)
			.then(rst => {
				console.log(rst)
				if (rst.data.length !== 0) {
					rst.data.map(ncm => ncm.data = `${ncm.NCM}-${ncm.description}`)
					setDados(rst.data)
					setSelecionado(rst.data[0])
				}
			})
	}

	useEffect(() => {
		if (companies.length === 1) setSelectedCompanies([companies[0]])
		getDadosFiscais()
	}, [companies])

	const updatePricePerCompany = (v, key, companyID) => {
		setSelectedCompanies((s) => {
			s[key].SalePrice = v
			return s
		})
		setCompanyPrices({ ...companyPrices, [companyID]: v })
	}

	const submitProduct = (e) => {
		e.preventDefault()
		e.persist()
		// console.log(group)
		const form = e.target

		let data = {
			"CompanyGroupID": companyGroupID,
			"StatusID": status['on'],
			"ProductTypeID": '55550E77-10D1-40DA-A067-075CB2124577',
			"ProductGroupID": group["ProductGroupID"],
			"UnitTypeID": unit["UnitTypeID"],
			"Name": form['product_name'].value,
			"Description": form['product_description'].value,
			"ImageBase64": preview,
			"InternalCode": form['product_codigo'].value,
			"BarCode": form['product_cod_bar'].value,
			"NFCeNCM": '',
			"NFCeCFOP": '',
			"NFCeCST": '',
			"NFCeCEST": '',
			"NFCeAliqICMS": 0.00,
			"NFCeCSTPIS": '',
			"NFCeAliqPIS": 0.00,
			"NFCeCSTCOFINS": '',
			"NFCeAliqCOFINS": 0.00,
			"NFCeCodBenef": '',
			"NFCeBaseRedICMS": 0.00,
			"Companies": Object.keys(companyPrices).map((c) => { return { "CompanyID": c, "SalePrice": formatAPINumber(companyPrices[c]) } })

		}

		productsAPI.addProduct(companyID, data).then((res) => {
			if (res.status === 201) {
				addProduct(res.data)
				const dados = { productID: res.data.ProductID, ...selecionado }
				console.log(dados)
				setSelectedCompanies((oldValue) => oldValue.map( e => e.SalePrice = 0));
				atualizaTable();
				if (nfcType === 'S' && dados.ncm) {
					dadosFiscais.addDadosFiscais(dados, companyGroupID)
						.then((rst) => { Toast('', t('success.create_product'), 'success') })
						.catch(() => { Toast('', t('error.create_product'), 'danger') })
				} else Toast('', t('success.create_product'), 'success')
			}
		}).catch(() => Toast('', t('error.create_product'), 'danger'))
		
	}

	const formatAPINumber = (val) => {
		return parseFloat(String(val).replace(",", ".")).toFixed(2)
	}

	const onChange = (e) => { setSelecionado(e) }

	return (
		<Dialog
			setOpen={setOpen}
			Children={(
				<div>
					<div className='dialog_title_container'>
						<WhiteButton className='close_button'
							type='button'
							onClick={() => setOpen(false)}
							icon={'close-outline'} />
						<h3 className='dialog_title'>{t('product_register_dialog.title')}</h3>
					</div>
					<form onSubmit={(e) => submitProduct(e)}>
						<div className='dialog_content_container product_dialog_content'>

							<div className='dialog_input_container'>

								<div style={{ flex: companies.length > 1 ? 3 : 1, minWidth: 280 }} className='column'>
									<TextInput id={'product_codigo'} regex={/^[0-9a-zA-Z+,-_% ]+$/} regexMessage={t('error.codigo_regex')} maxLength={"50"} title={t('product_register.codigo')} />
									<TextInput id={'product_name'} regex={/^[0-9a-zA-Z+,-_% ]+$/} regexMessage={t('error.product_name_regex')} maxLength={"50"} title={t('product_register.product_name')} />
									<DropdownInput value={group} onChange={setGroup} items={groups} itemKey={'Name'} title={t('group')} />

									{companies.length === 1 && selectedCompanies.length === 1 &&
										<Fragment>
											<div className='input_row'>

												<div className='column'>
													<div style={{ marginBottom: 3 }}>
														<p className='input_title'>{t('price')}</p>
														<CurrencyInput placeholder={'0,00'}
															locale='pt-br'
															style={{ boxShadow: 'none', border: '1.5px solid lightgray', marginBottom: 10 }}
															id={selectedCompanies[0].CompanyID + 'salePriceNewProduct'}
															value={selectedCompanies[0].SalePrice ? selectedCompanies[0].SalePrice : 0}
															onChange={(v) => updatePricePerCompany(v, 0, selectedCompanies[0].CompanyID)}
															countryCode={'BRL'} />
													</div>

												</div>

												<div className='column'>
													<DropdownInput value={unit} auxKey={'Description'} onChange={setUnit} items={units()} itemKey={'Name'} title={t('unit')} />
												</div>

											</div>
											<TextInput maxLength={"20"} id={'product_cod_bar'} title={t('barcode')} />
										</Fragment>
									}
									{companies.length > 1 &&
										<div className='input_row'>
											<div className='column'>
												<DropdownInput value={unit} auxKey={'Description'} onChange={setUnit} items={units()} itemKey={'Name'} title={t('unit')} />
											</div>
											<div className='column'>
												<TextInput maxLength={"20"} id={'product_cod_bar'} title={t('barcode')} />
											</div>
										</div>
									}

									{companies.length > 1 && <TextInput maxLength={"50"} id={'product_description'} title={t('description')} />}

									{nfcType === 'S' &&
										<DropdownInput
											placeholder={t('search')}
											items={dados}
											value={selecionado}
											itemKey={'data'}
											onChange={onChange}
											title={t('dados.fiscal.NCM')}
										/>
									}
								</div>

								{companies.length > 1 && <div className='column company_select_column'>
									<SelectDropdown selected={selectedCompanies}
										items={companies}
										itemID={'CompanyID'}
										itemKey={'Trademark'}
										title={t('stores')}
										setSelected={setSelectedCompanies} />
								</div>}

								<div style={{ flex: 1 }} className='column'>
									{companies.length === 1 && <TextInput maxLength={"50"} id={'product_description'} title={t('description')} />}

									<PhotoInput
										preview={preview}
										setPreview={setPreview}
										className={'photo_multiple_companies'}
									/>

								</div>
							</div>

							{companies.length > 1 && selectedCompanies.length > 0 &&
								<Fragment>
									<p className='dialog_section_title'>{t('product_register_dialog.price_per_store')}</p>
									<div className='dialog_input_container'>
										{selectedCompanies.map((c, key) =>
											<div className='price_row' key={key}>
												<p>{c['Trademark']}</p>
												<CurrencyInput placeholder={'0,00'}
													locale='pt-br'
													style={{ boxShadow: 'none', border: '1.5px solid lightgray' }}
													id={c['CompanyID'] + 'salePriceNewProduct'}
													value={c.SalePrice ? c.SalePrice : 0}
													onChange={(v) => updatePricePerCompany(v, key, c.CompanyID)}
													countryCode={'BRL'} />
											</div>
										)}
									</div>
								</Fragment>
							}
							<Button type={'submit'} styles={{ margin: '20px 0 0' }} text={t('save')} />
						</div>
					</form>
				</div>



			)} />
	)
}

export default AddProductDialog