import React from 'react'
import { GraphContainer } from '../../components'
import t from '../../../config/translate'
import BootstrapTable from 'react-bootstrap-table-next'
import { formatCurrencyWithoutSymbol } from '../../../utils/formatters'

function PendingData(props) {

	const { title, data } = props
	const columns = [

		{
			headerStyle: () => {
				return { verticalAlign: 'initial' }
			},
			dataField: 'PropertyNumber',
			text: t('pending_data.pos')
		},
		{
			headerStyle: () => {
				return { verticalAlign: 'initial', textAlign: 'right' }
			},
			formatter: (editorProps, value, row, column, rowIndex, columnIndex) => {
				return (
					<p style={{ width: '100%', textAlign: 'right' }}>{value.QTDSalesNotSynced}</p>
				)
			},
			dataField: 'QTDSalesNotSynced',
			text: t('pending_data.amount')
		},
		{
			headerStyle: () => {
				return { verticalAlign: 'initial', textAlign: 'right' }
			},
			formatter: (editorProps, value, row, column, rowIndex, columnIndex) => {
				return (
					<p style={{ width: '100%', textAlign: 'right' }}>{formatCurrencyWithoutSymbol(value.ValSalesNotSynced)}</p>
				)
			},
			dataField: 'ValSalesNotSynced',
			text: t('pending_data.value') + ' (R$)'
		},
		{
			headerStyle: () => {
				return { verticalAlign: 'initial', textAlign: 'right' }
			},
			formatter: (editorProps, value, row, column, rowIndex, columnIndex) => {
				if (value.Charging) {
					return (
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
							<p style={{ margin: '0 10px 0 0' }}>{value.BattLevel}</p>
							<ion-icon style={{ color: 'var(--color-accent-secondary)' }} name="flash"></ion-icon>
						</div>
					)
				} else { return <p>{value.BattLevel}</p> }


			},
			dataField: 'BattLevel',
			text: `${t('pending_data.battery')} (%)`
		},
	]


	return (
		<GraphContainer
			title={title}
			subTitle={t('pending_data.subtitle')}
			Children={
				(
					<div className='bar_graph_container'>
						<div className='graph_row_items'>
							<div>
								<p>{t('pending_data.sales')}</p>
								<h5>{data.sales || 0}</h5>
							</div>
							<div>
								<p>{t('pending_data.total')} (R$)</p>
								<h5>{data.total ? formatCurrencyWithoutSymbol(data.total) : 0}</h5>
							</div>
							<div>
								<p>{t('pending_data.pos')}</p>
								<h5>{data.pos ? formatCurrencyWithoutSymbol(data.pos) : 0}</h5>
							</div>
						</div>

						{data ?
							<BootstrapTable
								hover
								keyField="DatetimeSale"
								data={data.data || []}
								columns={columns}
							/>
							: <p className='bar_graph_container'>{t('pending_data.no_sent')}</p>
						}


					</div>
				)
			}
		/>
	)
}

export default PendingData