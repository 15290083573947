import styled from 'styled-components'

export const CONTAINER = styled.div`
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-main);
    cursor: pointer;

    img {
        max-height: 16px;
        margin-right: 10px;
    }

    .dropdown_container{
        position: relative;
        max-height: 50px;
    }
   

    .dropdown_title {
        display: flex;
        max-height: 50px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        max-width: 50px;
        padding: 4px 10px 0;
        font-size: 16px;
    }
    

    .dropdown_item_container {
        position: absolute;
        margin-top: 6px;
        min-width: 135px;
        max-width: 165px;
        box-shadow: var(--box-shadow-card);
        border-radius: 12px;
        background-color: var(--color-light);
        max-height: 550px;
        top: 100%;
        left: 0%;
        transform:translate(0, 0);
        z-index: 99;
        transition: 0.5s;
        visibility: show;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        align-items: center;
    }

    .dropdown_item_container_closed{
        position: absolute;
        background-color: var(--color-accent);
        margin-top: 2px;
        visibility: hidden;
        min-width: 135px;
        max-width: 165px;
        border: 1px solid var(--color-gray);
        border-radius: var(--border-radius-medium);
        background: white;
        max-height: 0px;
        top: 100%;
        left: 0%;
        transform:translate(0, 0);
        z-index: 99;
        transition: 0.5s;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    input  {
        font-weight: var(--font-weight-small);
        font-size: 16x;
        color: var(--color-dark);
        max-width: 80%;
        outline: none;
        background: none;
        border: none;

        &:hover {
            cursor: pointer;
        }
    }

    input::placeholder {
        color: var(--color-darkgray);
    }

    .dropdown_item {
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-transform: uppercase;
        width: 160px;
        text-align: left;
        font-size: 12px;
        background: var(--color-light);
        color: var(--color-dark);
        cursor: pointer;
        padding: 0 10px;
        border-bottom: 1.5px solid var(--color-accent-secundary);

        &:hover {
            filter: brightness(90%);
            transition: 0.3s;
        }
    }

    .dropdown_item:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }

    .filter_option_title {
        width: 100%;
        background: lightgray;
        color: gray;
    }
    
    .filter_radio_item {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        ion-icon {
            color: var(--color-accent-secondary);
            font-size: 18px;
            margin-right: 10px;
        }
    }


`