import React, { useState, useEffect, memo, useMemo } from 'react'
import { Container } from './styles'
import { Nav, TopNav, PrintFicha, PrintClientTicket, PrintStoreTicket } from '../../components'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import t from '../../../config/translate'
import printAPI from '../../../services/config'
import { withRouter } from 'react-router-dom'
import { Button, Dropdown, PhotoInput, TextInput, Toast } from '../../../shared_components'
import { PrintOrderPassword1, PrintOrderPassword2 } from '../../../assets/images'


function PrintConfig({ companyID, whitelabel_name }) {
	const printOptions = useMemo(() => [{ Value: '0', text: 'Desabilitada' }, { Value: '1', text: 'Fichas' }, { Value: '8', text: 'Pedido' }, { Value: '2', text: 'Pedido e Senha' }], [])

	const [selectedPrintOption, setSelectedPrintOption] = useState(printOptions[0])
	const [printLogo, setPrintLogo] = useState('')
	const [inputValues, setInputValues] = useState({
		header1: '',
		header2: '',
		message1: '',
		message2: '',
		message3: '',
	})



	useEffect(() => {
		let isMounted = true

		printAPI.getPrintConfig(companyID).then((res) => {
			const data = res.data.texts
			isMounted && res.data && res.data.ImageCompanyBase64 && res.data.ImageCompanyBase64 !== '0' && setPrintLogo(res.data.ImageCompanyBase64)

			const auxPrintOption = data.filter((d) => d.Id === '13')[0]
			if (printOptions.filter((o) => o.Value === auxPrintOption)) {
				let auxVal = printOptions.filter((o) => o.Value === auxPrintOption.Value)[0]
				setSelectedPrintOption({ ...auxPrintOption, text: auxVal.text })
			}
			setInputValues({
				header1: data.filter((d) => d.Id === '5')[0],
				header2: data.filter((d) => d.Id === '21')[0],
				message1: data.filter((d) => d.Id === '6')[0],
				message2: data.filter((d) => d.Id === '7')[0],
				message3: data.filter((d) => d.Id === '8')[0],
			})
		}).catch(() => Toast('', t('error.get_print_config'), 'danger'))

		return () => {
			isMounted = false
		}
	}, [companyID, printOptions])

	const handleSelectedOption = (v) => {
		let auxVal = printOptions.filter((o) => o.Value === v.Value)[0]
		setSelectedPrintOption((s) => { return { ...s, text: auxVal.text, Value: v.Value } })
	}

	const submitConfig = () => {
		let printTexts = Object.keys(inputValues).map((v) => { return { "Value": inputValues[v].Value, "Id": inputValues[v].Id } })
		let auxPrintOption = { ...selectedPrintOption }
		printTexts = printTexts.concat([{ "Value": auxPrintOption.Value, "Id": auxPrintOption.Id }])
		const data = {
			texts: printTexts,
			ImageCompanyBase64: printLogo
		}
		printAPI.savePrintConfig(companyID, data)
			.then((res) => res.status === 200 ? Toast('', t('success.save_print_config'), 'success') : Toast('', t('error.save_print_config'), 'danger'))
			.catch(() => Toast('', t('error.save_print_config'), 'danger'))
	}

	return (
		<Container>
			<Helmet>
				<title>{t('print_config.title')} | {whitelabel_name}</title>
			</Helmet>

			<Nav />

			<div className='content_container'>
				<TopNav pageTitle={t('print_config.title')} />
				<div className='form_container'>
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>

						<div className='column'>
							<p className='section_title'>{t('print_config.print')}</p>


							<div style={{ flex: 2 }} className='column'>
								<Dropdown title={t('print_config.print_mode')} value={selectedPrintOption || ''} onChange={handleSelectedOption} items={printOptions} itemKey={'text'} />

								{selectedPrintOption.Value !== '0' &&
									<div className='column'>
										{selectedPrintOption.Value === '1' &&
											<PrintFicha printValues={{ ...inputValues, logo: printLogo }} />
										}

										{selectedPrintOption.Value === '2' &&
											<div style={{ display: 'flex', flexWrap: 'wrap' }}>
												<PrintStoreTicket style={{ marginRight: 20 }} printValues={{ ...inputValues, logo: printLogo }} />
												<PrintClientTicket printValues={{ ...inputValues, logo: printLogo }} />
											</div>
										}

										{selectedPrintOption.Value === '8' &&
											<PrintStoreTicket printValues={{ ...inputValues, logo: printLogo }} />
										}
									</div>}



							</div>


						</div>

						<div className='column'>
							<p className='section_title'>{t('print_config.print_info')}</p>
							<p className='input_title'>{t('print_config.logo')}</p>
							<PhotoInput styles={{ display: 'flex', marginBottom: 20 }} textStyles={{ marginLeft: 20 }} preview={printLogo} setPreview={setPrintLogo} />
							<br />

							<div className='column'>
								<div className='input_row'>
									<TextInput maxLength={22} value={inputValues.header1.Value} onChange={(v) => setInputValues((i) => { return { ...i, header1: { ...i.header1, Value: v } } })} className='row_item' id='print_header_1' title={t('print_config.header1')} />
									<TextInput maxLength={22} value={inputValues.header2.Value} onChange={(v) => setInputValues((i) => { return { ...i, header2: { ...i.header2, Value: v } } })} className='row_item' id='print_header_2' title={t('print_config.header2')} />
								</div>
								<div className='input_row'>
									<TextInput maxLength={22} value={inputValues.message1.Value} onChange={(v) => setInputValues((i) => { return { ...i, message1: { ...i.message1, Value: v } } })} className='row_item' id='print_message_1' title={t('print_config.message1')} />
									<TextInput maxLength={22} value={inputValues.message2.Value} onChange={(v) => setInputValues((i) => { return { ...i, message2: { ...i.message2, Value: v } } })} className='row_item' id='print_message_2' title={t('print_config.message2')} />
									<TextInput maxLength={22} value={inputValues.message3.Value} onChange={(v) => setInputValues((i) => { return { ...i, message3: { ...i.message3, Value: v } } })} className='row_item' id='print_message_3' title={t('print_config.message3')} />
								</div>
							</div>
						</div>

					</div>

					<Button onClick={() => submitConfig()} text={t('save')} />
				</div>
			</div>

		</Container>
	)
}


function mapStateToProps({ auth, theme }) {
	return {
		companyID: auth.authedStore ? auth.authedStore.CompanyID : '',
		companyGroupID: auth.authedStore ? auth.authedStore.CompanyGroupID : '',
		token: auth.tokens ? auth.tokens.token : '',
		whitelabel_name: theme.whitelabel_name || 'POS CONTROLE',
	}
}

export default withRouter(connect(mapStateToProps)(PrintConfig))
