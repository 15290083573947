/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from './styles'
import loginApi from '../../../services/login'
import { handleGetTokens } from '../../../config/store/actions/auth'
import { useDispatch } from 'react-redux'
import { Button } from '../../../shared_components'
import { Helmet } from 'react-helmet'

/**
http://localhost:3000/redirectLogin/token
*/

function RedirectLogin() {
	const dispatch = useDispatch()
	const history = useHistory()

	const [error, setError] = useState(false)

	useEffect(() => {
		const redirectBack = () => { history.push('/monitor') }
		const token = window.location.pathname.split('/')[2]
		loginApi.validate(token)
			.then(rst => { dispatch(handleGetTokens(rst.data, redirectBack)) })
			.catch((err) => {
				setError(true)
			})
	}, [dispatch, history])

	return (
		<Container>
			<Helmet>
				<title>Login</title>
			</Helmet>
			<div className='content_container'>
				<div className='form_container'>
					{error
						? (
							<div className='input_container'>
								<center><p>Ocorreu erro, por favor realizar login novamente</p></center>
								<Button
									text='voltar ao login'
									onClick={() => { history.push('/login') }}
								/>
							</div>
						)
						: <p>logando</p>
					}
				</div>
			</div>
		</Container>
	)
}

export default RedirectLogin