import React from "react";
import { DateRange } from "../../../shared_components";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import t from "../../../config/translate";
import { connect } from "react-redux";
import moment from "moment";
import { formatCurrency } from "../../../utils/formatters";
import Loading from "../../../shared_components/loading";

const { SearchBar, ClearSearchButton } = Search;

function ReportTable({
//   companyID,
  data,
//   setData,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  updateRange,
  isLoading
}) {

  const columns = [
    {
      dataField: "CompanyName",
      text: t("report_cashless_extrato_online.comany_name"),
    },
    {
      dataField: "MifareCardCode",
      text: t("report_cashless_extrato_online.mifare_card_code"),
      sort: true,
    },
    {
      dataField: "CPFCNPJ",
      text: t("report_cashless_extrato_online.cpfcnpj"),
    },
    {
      dataField: "Saldo",
      text: t("report_cashless_extrato_online.saldo")  + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <p>{`${t('current_currency')} ${formatCurrency(value.Saldo).substring(2)}`}</p>
      ),
    },
    {
      dataField: "PreviousBalance",
      text: t("report_cashless_extrato_online.previous_balance")  + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <p>{`${t('current_currency')} ${formatCurrency(value.PreviousBalance).substring(2)}`}</p>
      ),
    },
    {
      dataField: "CardBalance",
      text: t("report_cashless_extrato_online.card_balance") + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <p>{`${t('current_currency')} ${formatCurrency(value.CardBalance).substring(2)}`}</p>
      ),
    },
    {
      dataField: "SaldoReset",
      text: t("report_cashless_extrato_online.saldo_reset") + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <p>{`${t('current_currency')} ${formatCurrency(value.SaldoReset).substring(2)}`}</p>
      ),
    },
    {
      dataField: "ST",
      text: t("report_cashless_extrato_online.st"),
    },
  ];

  return (
    <div className="table_container">
      <ToolkitProvider
        keyField="uniqueTableKey"
        data={data}
        columns={columns}
        search
      >
        {(props) => {
          return (
            <div className="user_table">
              <div className="table_title">
                <div>
                  <h2>{t("report_cashless_extrato_online.title")}</h2>
                  <h3>{t("report_cashless_extrato_online.description")}</h3>
                </div>

                <div className="table_search_container">
                  <DateRange
                    initialDate={initialDate}
                    finalDate={finalDate}
                    setFinalDate={setFinalDate}
                    setInitialDate={setInitialDate}
                    submit={updateRange}
                  />

                  <div className="search_div">
                    <SearchBar
                      placeholder={t("search")}
                      {...props.searchProps}
                    />
                    <ClearSearchButton
                      text={t("clear")}
                      {...props.searchProps}
                    />
                  </div>
                </div>
              </div>

              <hr />
              { isLoading ? <Loading /> : <BootstrapTable {...props.baseProps} striped /> }
            </div>
          );
        }}
      </ToolkitProvider>
    </div>
  );
}

export default connect()(ReportTable);
