import React from 'react'
import {Container} from './styles'

export default function Button({icon, text, styles, onClick, type, grayIcon, className}){
    return(
        <Container className={className} type={type} onClick={onClick} style={{...styles}}>
            {icon && <ion-icon name={icon}/>}
            {text}
        </Container>
    )
}