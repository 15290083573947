import React, { useState } from 'react'
import { Dialog, TextInput, WhiteButton, PhotoInput, Button } from '../../../shared_components'
import t from '../../../config/translate'

function AddGroupDialog({ setOpen, addGroup }) {

	const [preview, setPreview] = useState()
	const [groupName, setGroupName] = useState('')

	return (
		<Dialog
			setOpen={setOpen}
			Children={(
				<div>
					<div className='dialog_title_container'>
						<WhiteButton className='close_button'
							type='button'
							onClick={() => setOpen(false)}
							icon={'close-outline'} />
						<h3 className='dialog_title'>{t('add_group.new_group')}</h3>
					</div>

					<div className='dialog_content_container product_dialog_content'>

						<div className='dialog_input_container'>
							<div >
								<PhotoInput styles={{ justifyContent: 'space-around', textAlign: 'center', maxWidth: '100%' }} preview={preview} setPreview={setPreview} />
								<TextInput
									maxLength={"20"}
									value={groupName}
									regex={/^[0-9a-zA-Z+,-_% ]+$/}
									regexMessage={t('error.group_name_type')}
									onChange={setGroupName}
									title={t('add_group.group_name')} />
							</div>

						</div>
						<Button onClick={() => addGroup(groupName, preview)} styles={{ margin: '20px 0 0' }} text={t('save')} />
					</div>


				</div>
			)} />
	)
}

export default AddGroupDialog