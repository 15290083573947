import React from "react";
import { DateRange } from "../../../shared_components";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import t from "../../../config/translate";
import { connect } from "react-redux";

import { formatCurrency } from "../../../utils/formatters";

const { SearchBar, ClearSearchButton } = Search;

function ReportTable({
//   companyID,
  data,
//   setData,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  updateRange,
}) {
  const columns = [
    {
      dataField: "ProductGroupName",
      text: t("report_sales_per_group.group"),
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b>{value.ProductGroupName}</b>
          </p>
        ) : (
          <p></p>
        ),
    },
    {
      dataField: "ProductName",
      text: t("report_sales_per_group.produto"),
    },
    {
      dataField: "Unidade",
      text: t("report_sales_per_group.unit"),
    },
    {
      dataField: "Quantidade",
      text: t("report_sales_per_group.amount"),
    },
    {
      dataField: "Unitario",
      text: t("report_sales_per_group.single") + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b>{value.Unitario}</b>
          </p>
        ) : (
          <p>{formatCurrency(value.Unitario).substring(2)}</p>
        ),
    },
    {
      dataField: "Total",
      text: t("report_sales_per_group.total") + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b>{formatCurrency(value.Total).substring(2)}</b>
          </p>
        ) : (
          <p>{formatCurrency(value.Total).substring(2)}</p>
        ),
    },
  ];

  return (
    <div className="table_container">
      <ToolkitProvider keyField="id" data={data} columns={columns} search>
        {(props) => {
          return (
            <div className="user_table">
              <div className="table_title">
                <h2>{t("report_sales_per_group.title")}</h2>

                <div className="table_search_container">
                  <DateRange
                    initialDate={initialDate}
                    finalDate={finalDate}
                    setFinalDate={setFinalDate}
                    setInitialDate={setInitialDate}
                    submit={updateRange}
                  />

                  <div className="search_div">
                    <SearchBar
                      placeholder={t("search")}
                      {...props.searchProps}
                    />
                    <ClearSearchButton
                      text={t("clear")}
                      {...props.searchProps}
                    />
                  </div>
                </div>
              </div>

              <hr />
              <BootstrapTable {...props.baseProps} striped />
            </div>
          );
        }}
      </ToolkitProvider>
    </div>
  );
}

export default connect()(ReportTable);
