/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import GroupTable from "./group_table";
import { Container } from "./styles";
import { Nav, TopNav } from "../../components";
import groupsAPI from "../../../services/groups";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import t from "../../../config/translate";
import { handleLogout } from "../../../config/store/actions/auth";
import { withRouter } from "react-router-dom";
import { Toast } from "../../../shared_components";

function GroupRegister({
  companyID,
  token,
  companyGroupID,
  dispatch,
  history,
  whitelabel_name,
}) {
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState([]);

  useEffect(() => {
    let isMounted = true;

    groupsAPI
      .getGroups(companyGroupID)
      .then((res) => res.data)
      .then((data) => {
        if (isMounted) {
          setGroups(data);
          setAllGroups(data);
        }
      })
      .catch(() => Toast("", t("error.get_groups"), "danger"));

    return () => {
      isMounted = false;
    };
  }, [companyGroupID, token]);

  return (
    <Container>
      <Helmet>
        <title>
          {t("group_register.title")} | {whitelabel_name}
        </title>
      </Helmet>

      <Nav />

      <div className="content_container">
        <TopNav pageTitle={t("group_register.title")} />
        <GroupTable
          data={groups}
          setData={setGroups}
          companyID={companyID}
          companyGroupID={companyGroupID}
          allData={allGroups}
        />
      </div>
    </Container>
  );
}

function mapStateToProps({ auth, theme }) {
  return {
    companyID: auth.authedStore ? auth.authedStore.CompanyID : "",
    companyGroupID: auth.authedStore ? auth.authedStore.CompanyGroupID : "",
    token: auth.tokens ? auth.tokens.token : "",
    whitelabel_name: theme.whitelabel_name || "POS CONTROLE",
  };
}

export default withRouter(connect(mapStateToProps)(GroupRegister));
