import React, { useEffect, useState } from "react"
import { BarGraph } from "../../../../templates/components"
import t from "../../../../config/translate"
import monitorAPI from "../../../../services/monitor"

export function SaleItems({ companyID, ranges }) {
	const [revenueData, setRevenueData] = useState([])
	const [revenueRange, setRevenueRange] = useState("bynow")

	useEffect(() => {
		let componentMounted = true

		monitorAPI
			.getMonitorData(companyID, "saleitems", revenueRange)
			.then((res) => res.data)
			.then((data) => componentMounted && setRevenueData(data))
			.catch((err) => console.error("[SaleItems]", err.message))

		return () => {
			componentMounted = false
		}
	}, [companyID, revenueRange])

	return (
		<BarGraph
			data={revenueData}
			title={t("monitor.graph_revenue")}
			categoryX="xline"
			valueY="income"
			id={"saleitems"}
			ranges={ranges}
			selectedRange={revenueRange}
			setRange={(v) => setRevenueRange(v)}
		/>
	)
}
