import React, { useState, useEffect } from 'react'
import ProductTable from './product_table'
import { Container } from './styles'
import { Nav, TopNav } from '../../components'
import productsAPI from '../../../services/products'
import groupsAPI from '../../../services/groups'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import t from '../../../config/translate'
import { handleLogout } from '../../../config/store/actions/auth'
import { withRouter } from 'react-router-dom'
import { status } from '../../../utils/constants'
import systemConfigApi from '../../../services/systemConfig'

function ProductRegister(props) {
	const { companyID, companyGroupID, token, companies, dispatch, history, whitelabel_name } = props
	const [nfcType, setNfcType] = useState('N')

	const [data, setData] = useState({ page: 1, totalSize: 0, sizePerPage: 10, products: [] })
	const [groups, setGroups] = useState([])
	const [search, setSearch] = useState('')
	const [sort, setSort] = useState('')
	const [statusFilter, setStatusFilter] = useState(null)

	const s2ab = (s) => {
		var buf = new ArrayBuffer(s.length)
		var view = new Uint8Array(buf)
		for (var i = 0;i != s.length;++i) view[i] = s.charCodeAt(i) & 0xFF
		return buf
	}

	const getProduts = () => {
		if (companyID && token) {
			productsAPI.getProducts(companyID, data.page, data.sizePerPage).then((res) => {
				if (res.status === 200) {
					let auxRes = res.data
					setData((d) => {
						return {
							...d,
							page: auxRes.page,
							data: auxRes.products,
							totalSize: auxRes.total_records
						}
					})
				}
			})

			systemConfigApi.getPosPdvConfig(companyID, `id='AMB.FISCAL'`)
				.then(rst => { setNfcType(rst.data[0].Value) })
		}
	}

	useEffect(() => {
		let isMounted = true
		getProduts()
		return () => isMounted = false
	}, [companyID, data.page, data.sizePerPage, token])



	useEffect(() => {
		let isMounted = true
		groupsAPI.getGroups(companyGroupID).then((res) => {
			if (res.status === 200) {
				isMounted && setGroups(res.data)
			}
		})

		return () => isMounted = false
	}, [companyGroupID, token])


	const handleTableChange = (type, innerDetails) => {

		const { filters } = innerDetails
		if (innerDetails.searchText) { setSearch(innerDetails.searchText) }
		if (innerDetails.sortField) { setSort(innerDetails.sortField) }
		if (filters.StatusID) { setStatusFilter(filters.StatusID.filterVal) }

		let searchText = innerDetails.searchText
		if (type !== 'search' && !innerDetails.searchText) { searchText = search }
		const sortField = innerDetails.sortField || sort
		const sortOrder = String(innerDetails.sortOrder).toUpperCase() || ''
		const filteredStatus = filters.StatusID ? filters.StatusID.filterVal : statusFilter

		let stats = null
		if (filteredStatus) { stats = filteredStatus }

		const { page, sizePerPage } = innerDetails

		if (type !== 'cellEdit') {
			productsAPI.getProducts(companyID, page, sizePerPage, searchText, sortField, sortOrder, stats).then((res) => {
				let auxRes = res.data
				setData((d) => {
					return {
						...d,
						page: auxRes.page,
						sizePerPage: sizePerPage,
						data: auxRes.products,
						totalSize: auxRes.total_records
					}
				})
			})
		}
	}

	return (
		<Container>
			<Helmet>
				<title>{t('product_register.title')} | {whitelabel_name}</title>
			</Helmet>
			<Nav />
			<div className='content_container'>
				<TopNav pageTitle={t('product_register.title')} />
				<ProductTable data={data.data || []}
					sizePerPage={data.sizePerPage}
					page={data.page}
					companies={companies}
					totalSize={data.totalSize}
					companyID={companyID}
					groups={groups}
					nfcType={nfcType}
					setData={(d) => setData((p) => { return { ...p, products: d } })}
					companyGroupID={companyGroupID}
					handleTableChange={handleTableChange}
					atualizaTable={getProduts}
				/>
			</div>
		</Container>
	)
}

function mapStateToProps({ auth, theme }) {
	return {
		companyID: auth.authedStore ? auth.authedStore.CompanyID : '',
		companyGroupID: auth.authedStore ? auth.authedStore.CompanyGroupID : '',
		token: auth.tokens ? auth.tokens.token : '',
		companies: auth.company ? auth.company.Companys : [],
		whitelabel_name: theme.whitelabel_name || 'POS CONTROLE',
	}
}

export default withRouter(connect(mapStateToProps)(ProductRegister))
