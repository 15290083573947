import React, { useState } from 'react'
import { CONTAINER } from './styles'
import {useWindowSize} from '../../hooks'

function DROPDOWN({ items, user, styles, id, onChange, itemOutputKey, itemInputKey }) {
    const [open, setOpen] = useState(false)
    const size = useWindowSize()

    return (
        <CONTAINER style={{ ...styles }}>
            <div className='dropdown_container'>
                <div className='dropdown_title' onClick={() => setOpen(!open)}>
                    {size.width > 500 
                        ? <input readOnly="readonly" id={id} value={user} />
                        : <ion-icon name="storefront-outline"></ion-icon>
                    }
                    <ion-icon name="chevron-down-outline"></ion-icon>
                </div>
                {items && items.length > 1 &&
                    <div className={open ? 'dropdown_item_container' : 'dropdown_item_container_closed'}>
                        {items.map((i) =>
                            <div key={i[itemOutputKey]} onClick={() => { onChange(i); setOpen(!open) }} className='dropdown_item'>
                                {i[itemInputKey]}
                            </div>
                        )}
                    </div>
                }
            </div>
        </CONTAINER>
    )


}

export default DROPDOWN