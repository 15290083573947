import { createStore } from 'redux'
import reducers from './reducers'
import middlewares from './middlewares'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'P0DC0NTR0L3',
    storage,
    blacklist: ['theme']
}

const persistedReducer = persistReducer(persistConfig, reducers)
const store = createStore(persistedReducer,middlewares)
let persistor = persistStore(store)

export {store,  persistor}