import { store } from 'react-notifications-component';

/**
 * types:
 *  danger
 *  info
 *  success
 */

function Toast(title = '', message='', type='info', duration = 5000, insert='bottom', container='bottom-right') {
    return store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: insert,
        container: container,
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: duration,
          onScreen: false
        }
      });
}

export default Toast