import styled from 'styled-components'

export const CONTAINER = styled.div`
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-primary);
    width: 100%;
    
    h5 {
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        color: var(--color-dark);
        margin: 10px;
    }


`

export const INPUT_CONTAINER = styled.div`
    position: relative;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    border-radius: 8px;
    border: 1px solid #66afe9;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    padding: 8px 12px;
    width: 100%;
    min-width: 100px;
    min-height: 41px;
    
    h5 {
        font-size: 14px;
        color: black;
        margin: 0;
    }

    input {
        border: none;
        outline: none;
        background: transparent;
        padding: 0 2px;
        width: 100%;
        font-family: var(--font-family-secondary);
        
        :read-only {
            border-bottom: none;
            padding: 0 2px 0;
        }
    
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: rgba(0,0,0, 0.54);
            opacity: 1; /* Firefox */
          }
          
          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: white;
          }
          
          ::-ms-input-placeholder { /* Microsoft Edge */
            color: white;
          }
    }

`

