import React from "react";
import { DateRange } from "../../../shared_components";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import t from "../../../config/translate";
import { connect } from "react-redux";
import moment from "moment";
import { formatCurrency } from "../../../utils/formatters";
import Loading from "../../../shared_components/loading";

const { SearchBar, ClearSearchButton } = Search;

function ReportTable({
//   companyID,
  data,
//   setData,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  updateRange,
  isLoading
}) {
  const columns = [
    {
      dataField: "PropertyNumber",
      text: t("report_products_billing.property_number"),
    },
    {
      dataField: "InternalCode",
      text: t("report_products_billing.internal_code"),
    },
    {
      dataField: "ProductName",
      text: t("report_products_billing.product_name"),
      sort: true,
    },
    {
      dataField: "Vendas",
      text: t("report_products_billing.sales"),
    },
    {
      dataField: "Quantidade",
      text: t("report_products_billing.amount"),
    },
    {
      dataField: "QuantidadeEstorno",
      text: t("report_products_billing.chargeback_amount"),
    },
    {
      dataField: "Unitario",
      text: t("report_products_billing.unit"),
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <p>{`${t('current_currency')} ${formatCurrency(value.Unitario).substring(2)}`}</p>
      ),
    },
    {
      dataField: "Total",
      text: t("report_products_billing.total"),
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <p>{`${t('current_currency')} ${formatCurrency(value.Total).substring(2)}`}</p>
      ),
    },
  ];

  return (
    <div className="table_container">
      <ToolkitProvider
        keyField="uniqueTableKey"
        data={data}
        columns={columns}
        search
      >
        {(props) => {
          return (
            <div className="user_table">
              <div className="table_title">
                <div>
                  <h2>{t("report_products_billing.title")}</h2>
                  <h3>{t("report_products_billing.description")}</h3>
                </div>

                <div className="table_search_container">
                  <DateRange
                    initialDate={initialDate}
                    finalDate={finalDate}
                    setFinalDate={setFinalDate}
                    setInitialDate={setInitialDate}
                    submit={updateRange}
                  />

                  <div className="search_div">
                    <SearchBar
                      placeholder={t("search")}
                      {...props.searchProps}
                    />
                    <ClearSearchButton
                      text={t("clear")}
                      {...props.searchProps}
                    />
                  </div>
                </div>
              </div>

              <hr />
              { isLoading ? <Loading /> : <BootstrapTable {...props.baseProps} striped /> }
            </div>
          );
        }}
      </ToolkitProvider>
    </div>
  );
}

export default connect()(ReportTable);
