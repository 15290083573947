import React, { useCallback, useEffect, useState } from "react"
import { Container } from "./styles"
import { Nav, TopNav } from "../../components"
import { connect } from "react-redux"
import Helmet from "react-helmet"
import t from "../../../config/translate"
import { withRouter } from "react-router-dom"
import ReportTable from "./report_table"
import reportAPI from "../../../services/reports"
import { formatReportSalesPerDay } from "../../../utils/formatters"
import moment from "moment"

function ReportSelPerGroup({ companyID, whitelabel_name }) {
	const [data, setData] = useState([])
	const [initialDate, setInitialDate] = useState(moment())
	const [finalDate, setFinalDate] = useState(moment())

	const fetchDataSalesByDay = useCallback(() => {
		const initialDateFormated = moment(initialDate).format("YYYY-MM-DD")
		const finalDateFormated = moment(finalDate).format("YYYY-MM-DD")

		return reportAPI
			.getSalesByDay(companyID, initialDateFormated, finalDateFormated)
			.then((res) => res.data)
			.then(formatReportSalesPerDay)
			.catch((err) => console.error(err.message))
	}, [companyID, finalDate, initialDate])

	useEffect(() => {
		let componentMounted = true

		fetchDataSalesByDay().then((data) => componentMounted && setData(data))

		return () => {
			componentMounted = false
		}
	}, [fetchDataSalesByDay])

	const updateRange = () => {
		fetchDataSalesByDay().then(setData)
	}

	return (
		<Container>
			<Helmet>
				<title>
					{t("report_title")} | {whitelabel_name}
				</title>
			</Helmet>

			<Nav />

			<div className="content_container">
				<TopNav pageTitle={t("report_title")} />
				<ReportTable
					data={data}
					// setData={setData}
					//   companyID={companyID}
					updateRange={updateRange}
					initialDate={initialDate}
					setInitialDate={setInitialDate}
					finalDate={finalDate}
					setFinalDate={setFinalDate}
				/>
			</div>
		</Container>
	)
}

function mapStateToProps({ auth, theme }) {
	return {
		companyID: auth.authedStore ? auth.authedStore.CompanyID : "",
		// companyGroupID: auth.authedStore ? auth.authedStore.CompanyGroupID : "",
		whitelabel_name: theme.whitelabel_name || "POS CONTROLE",
	}
}

export default withRouter(connect(mapStateToProps)(ReportSelPerGroup))
