import React, { useState } from "react"
import Input from "react-input-mask"
import LogoDefault from "../../../assets/images/semlogo.png"
import { Toast } from "../../../shared_components"
import whitelabel from "../../../services/whitelabel"

import {
  Container,
  Title,
  DivFile,
  Image,
  InputsDiv,
  ButtonSubmit,
  LabelFile,
  CheckDiv,
  Subtitles,
  DivForm,
} from "./styles";
import "bootstrap/dist/css/bootstrap.min.css"

function WhiteLabelForm() {
  const [imgData, setImgData] = useState(LogoDefault);
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [telefone, setTelefone] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [nomeCurto, setNomeCurto] = useState('')
  const [url, setUrl] = useState('')
  const [logo , setLogo] = useState('')
  const [siteWL , setSiteWL] = useState('')


  function onImgLoad({ target: img }) {
	  if (img.naturalWidth > 540 || img.naturalHeight > 90) {
	    Toast('Atenção', 'Dimensões, altura e/ou largura, da imagem excedidas.', 'danger', 5000, 'top','top-right')
      setImgData(LogoDefault)
      return
    }

    setLogo(img.src);
  }

	const register = (e) => {
		e.preventDefault();
		if (nome === '' || email === '' || telefone === '' || cnpj === '' || nomeCurto === '' || url === ''|| siteWL === '' || logo === LogoDefault) {
			Toast('Atenção', 'Por favor preencher todos os dados.', 'danger', 5000, 'top','top-right')
			return
		}

		if(url.match(/[^a-zA-Z0-9]+/g,'')){
			Toast('Atenção', 'Por favor não inserir na URL caracteres especiais ou espaços em brancos.', 'danger', 5000, 'top','top-right')
			return
		}

		if(url[0].match(/\d|,/g)){
			Toast('Atenção', 'A primeira caractere da URL deve ser uma letra.', 'danger', 5000, 'top','top-right')
			return
		}

		if(url.includes("pdv.mobi")){
			Toast('Atenção', 'Não precisa digitar pdv.mobi na URL.', 'danger', 5000, 'top','top-right')
			return
		}



		const data = {
			nome,
			email,
			telefone,
			cnpj,
			nome_curto: nomeCurto,
			url,
			logo,
			siteWL,
			type: "cadWL",
			q: "principal",
		}

		whitelabel
		.register(data)
		.then((rst) => {
				if (rst.data.ret.status === 'erro') {
					if (rst.data.ret.detalhe[0]) Toast('Ocorreu um problema',`${rst.data.ret.detalhe[0].msg}`, 'danger', 5000, 'top','top-right')
					else Toast('Ocorreu um problema', `${rst.data.ret.detalhe.msg}`, 'danger', 5000, 'top', 'top-right')
					return
				}

		setNome('')
		setEmail('')
		setNomeCurto('')
		setTelefone('')
		setCnpj('')
		setImgData(LogoDefault)
		setUrl('')
				Toast('', 'Cadastrado com sucesso!', 'success', 5000, 'top', 'top-right')

		})
		.catch(() => Toast('Atenção','Por favor tente novamente!', 'danger', 5000, 'top','top-right'))
  	}

    const onDeletePicture = (e) => {
      setImgData(LogoDefault);
    }
    const onChangePicture = (e) => {
      if (e.target.files[0]) {
        const size = Math.round(e.target.files[0].size / 1024);
        if (size > 500) {
		  Toast('Atenção', 'Tamanho da imagem não aceita.', 'danger', 5000, 'top','top-right')
        return
      }

        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        })
        reader.readAsDataURL(e.target.files[0])
      }
    };
    return (
      <>
        <Container>
          <Title>White Label</Title>
          <DivForm>
            <DivFile>
              <Image src={imgData} onLoad={onImgLoad} />
              <LabelFile>
                <input
                  style={{ display: "none" }}
                  type="file"
                  onChange={onChangePicture}
                  accept="image/png"
                  required
                />
                <ion-icon size="small" name="pencil-outline"></ion-icon>
              </LabelFile>

              <LabelFile style={{ background: "red" }}>
                <ion-icon
                  size="small"
                  name="trash-outline"
                  onClick={onDeletePicture}
                ></ion-icon>
              </LabelFile>
              <CheckDiv>
                <ion-icon
                  name="checkmark-circle-outline"
                  style={{ color: "green" }}
                ></ion-icon>
                <Subtitles>Altura 90px</Subtitles>
              </CheckDiv>
              <CheckDiv>
                <ion-icon
                  name="checkmark-circle-outline"
                  style={{ color: "green" }}
                ></ion-icon>
                <Subtitles>Largura 540px</Subtitles>
              </CheckDiv>
              <CheckDiv>
                <ion-icon
                  name="checkmark-circle-outline"
                  style={{ color: "green" }}
                ></ion-icon>
                <Subtitles>Tamanho Máximo 500kb</Subtitles>
              </CheckDiv>
              <CheckDiv>
                <ion-icon
                  name="checkmark-circle-outline"
                  style={{ color: "green" }}
                ></ion-icon>
                <Subtitles>Imagem PNG</Subtitles>
              </CheckDiv>
            </DivFile>

            <InputsDiv>
              <div className="form-group col-xs-9">
                <label>Nome Curto</label>
                <Input
                  required
                  type="text"
                  className="form-control"
                  onChange={(e) => setNomeCurto(e.target.value)}
                  maxLength="30"
                  value={nomeCurto}
                />
              </div>
              <div className="form-group col-xs-9">
                <label>Nome Contato</label>
                <Input required type="text" value={nome} className="form-control" onChange={(e) => setNome(e.target.value)} maxLength="75"/>
              </div>
              <div className="form-group col-xs-9">
                <label>E-mail Contato</label>
                <Input
                  required
                  type="email"
                  className="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  maxLength="80"
                  value={email}
                />
              </div>
              <div className="form-group col-xs-9">
                <label>Telefone Contato</label>
                <Input
                  required
                  type="text"
                  className="form-control"
                  onChange={(e) => setTelefone(e.target.value)}
                  mask="(99)99999-9999"
                  value={telefone}
                />
              </div>
              <div className="form-group col-xs-9">
                <label>CNPJ</label>
                <Input
                  required
                  placeholder="XX.XXX.XXX/0001-XX"
                  mask="99.999.999/9999-99"
                  type="text"
                  className="form-control col-xs-3"
                  onChange={(e) => setCnpj(e.target.value)}
                  value={cnpj}
                />
              </div>
              <div className="form-group col-xs-7" >
                <label>URL</label>
                <div style={{ display: 'flex'}}>
                  <Input type="text" className="form-control" id="" value={url} required onChange={(e) => setUrl(e.target.value)} maxLength="30"/>
                  <span style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '15px', marginTop: '10px'}}>pdv.mobi</span>
                </div>
              </div>
              <div className="form-group col-xs-9" >
                <label>SITE</label>
                <div style={{ display: 'flex'}}>
                  <Input type="text" className="form-control" id="" value={siteWL} required onChange={(e) => setSiteWL(e.target.value)} maxLength="26"/>
                </div>
              </div>
            <ButtonSubmit
              style={{ flex: "none", height: '50px', marginTop: '25px'}}
              type="button"
              className="btn btn-success"
              onClick={register}
            >
              Enviar
            </ButtonSubmit>
            </InputsDiv>
          </DivForm>
        </Container>
      </>
    )
  }

export default WhiteLabelForm
