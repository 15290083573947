import React, { useState, useEffect } from 'react'
import { Container } from './styles'
import { Nav, TopNav } from '../../components'
import { useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import t from '../../../config/translate'
import { DropdownInput, TextInput, Button, Toast } from '../../../shared_components'
import dadosFiscais from '../../../services/dadosFiscais'
import { ClipLoader } from "react-spinners"
import systemConfigApi from '../../../services/systemConfig'

function FiscalConfig() {
	const whitelabel_name = useSelector(state => state.theme.whitelabel_name)
	const CompanyID = useSelector(state => state.auth.authedStore.CompanyID)
	const CompanyGroupID = useSelector(state => state.auth.authedStore.CompanyGroupID)
	const pageTitle = t('dados.fiscal.title')
	const regexDec2Places = /^\d+(,|.\d{1,2})?$/ ///^[0-9]*(,|.[0-9]{0,2})?$/
	const override = `
		display: block;
		border-color: red;
	`

	const [processing, setProcessing] = useState(false)
	const [dados, setDados] = useState([])
	const [selecionado, setSelecionado] = useState({})
	const [enable, setEnable] = useState(false)

	const getDadosFiscais = () => {
		dadosFiscais.get(CompanyGroupID)
			.then(rst => {
				if (rst.data.length !== 0) {
					rst.data.map(ncm => ncm.data = `${ncm.NCM}-${ncm.description}`)
					setDados(rst.data)
					setSelecionado(rst.data[0])
				}
			})
	}

	useEffect(() => {
		let componentMounted = true
		if (componentMounted) {
			getDadosFiscais()
			systemConfigApi.getPosPdvConfig(CompanyID, `id='AMB.FISCAL'`)
				.then(rst => {
					if (rst.data[0].Value === 'S') setEnable(true)
				})
		}
		return () => componentMounted = false
	}, [CompanyID])

	const onChange = (e) => { setSelecionado(e) }

	const onChangeCSTICMS = (e) => {
		const data = selecionado
		data.CSTICMS = e.replace(',', '.')
		setSelecionado(data)
	}
	const onChangeAliqIcms = (e) => {
		const data = selecionado
		data.AliqICMS = e.replace(',', '.')
		setSelecionado(data)
	}
	const onChangeRedBaseIcms = (e) => {
		const data = selecionado
		data.RedBaseICMS = e.replace(',', '.')
		setSelecionado(data)
	}

	const onChangeCSTPIS = (e) => {
		const data = selecionado
		data.CSTPIS = e.replace(',', '.')
		setSelecionado(data)
	}
	const onChangeAliqPIS = (e) => {
		const data = selecionado
		data.AliqPIS = e.replace(',', '.')
		setSelecionado(data)
	}

	const onChangeCSTCONFINS = (e) => {
		const data = selecionado
		data.CSTCOFINS = e.replace(',', '.')
		setSelecionado(data)
	}
	const onChangeAliqCONFINS = (e) => {
		const data = selecionado
		data.AliqCOFINS = e.replace(',', '.')
		setSelecionado(data)
	}

	const onChangeCFOP = (e) => {
		const data = selecionado
		data.CFOP = e.replace(',', '.')
		setSelecionado(data)
	}
	const onChangeCEST = (e) => {
		const data = selecionado
		data.CEST = e.replace(',', '.')
		setSelecionado(data)
	}

	const onChangeNCM = (e) => {
		const data = selecionado
		data.NCM = e.replace(',', '.')
		setSelecionado(data)
	}
	const onChangeDescription = (e) => {
		const data = selecionado
		data.description = e.replace(',', '.')
		setSelecionado(data)
	}

	const handleSaveClick = () => {
		if (!enable) {
			Toast('', 'Dados Fiscais não esta habilitado', 'info')
			return
		}
		setProcessing(true)
		dadosFiscais.saveUpdate(selecionado, CompanyGroupID)
			.then(() => {
				Toast('Sucesso', 'Dados salvos com sucesso', 'success')
				getDadosFiscais()
				setProcessing(false)
			})
			.catch(() => {
				Toast('Falha', 'Por favor, tente novamente', 'danger')
				setProcessing(false)
			})
	}

	return (
		<Container>
			<Helmet>
				<title>{pageTitle} | {whitelabel_name}</title>
			</Helmet>
			<Nav />

			<div className='content_container'>
				<TopNav pageTitle={pageTitle} />
				<div className='form_container'>

					<DropdownInput
						placeholder={t('search')}
						items={dados}
						value={selecionado}
						itemKey='data'
						onChange={onChange}
						styles={{ margin: 5, width: 300 }}
						title={t('dados.fiscal.NCM')}
						showIconArrowDown={false}
					/>

					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						<div className='column'>
							<p className='section_title'>{t('dados.fiscal.General')}</p>
							<TextInput
								title={t('dados.fiscal.Description')}
								id='description'
								value={selecionado.description}
								onChange={onChangeDescription}
								disabled={!enable}
							/>
							<TextInput
								title={t('dados.fiscal.NCM')}
								id='ncm'
								value={selecionado.NCM}
								onChange={onChangeNCM}
								maxLength='10'
								disabled={!enable}
							/>
						</div>

						<div className='column'>
							<p className='section_titleEmpty'></p>
							<TextInput
								title={t('dados.fiscal.Cfop')}
								id='cfop'
								value={selecionado.CFOP}
								onChange={onChangeCFOP}
								maxLength='6'
								disabled={!enable}
							/>
							<TextInput
								title={t('dados.fiscal.Cest')}
								id='cest'
								value={selecionado.CEST}
								onChange={onChangeCEST}
								maxLength='8'
								disabled={!enable}
							/>
						</div>
					</div>

					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						<div className='column'>
							<p className='section_title'>{t('dados.fiscal.ICMS')}</p>
							<TextInput
								title={t('dados.fiscal.CST_CSOSN')}
								id='cstICMS'
								value={selecionado.CSTICMS}
								onChange={onChangeCSTICMS}
								maxLength='4'
								disabled={!enable}
							/>
							<TextInput
								title={t('dados.fiscal.Aliq')}
								id='aliqIcms'
								value={selecionado.AliqICMS}
								onChange={onChangeAliqIcms}
								regex={regexDec2Places}
								disabled={!enable}
							/>
							<TextInput
								title={t('dados.fiscal.baseRedICMS')}
								id='aliqIcms'
								value={selecionado.RedBaseICMS}
								onChange={onChangeRedBaseIcms}
								regex={regexDec2Places}
								disabled={!enable}
							/>
						</div>
						<div className='column'>
							<p className='section_title'>{t('dados.fiscal.Pis')}</p>
							<TextInput
								title={t('dados.fiscal.CST')}
								id='cstPis'
								value={selecionado.CSTPIS}
								onChange={onChangeCSTPIS}
								maxLength='4'
								disabled={!enable}
							/>
							<TextInput
								title={t('dados.fiscal.Aliq')}
								id='aliqPis'
								value={selecionado.AliqPIS}
								onChange={onChangeAliqPIS}
								regex={regexDec2Places}
								disabled={!enable}
							/>
						</div>
						<div className='column'>
							<p className='section_title'>{t('dados.fiscal.Cofins')}</p>
							<TextInput
								title={t('dados.fiscal.CST')}
								id='cstCofins'
								value={selecionado.CSTCOFINS}
								onChange={onChangeCSTCONFINS}
								maxLength='4'
								disabled={!enable}
							/>
							<TextInput
								title={t('dados.fiscal.Aliq')}
								id='aliqCofins'
								value={selecionado.AliqCOFINS}
								onChange={onChangeAliqCONFINS}
								regex={regexDec2Places}
								disabled={!enable}
							/>
						</div>
					</div>
					<p className='section_title'></p>
					<div className='group_buttons'>
						<Button text={t('save')} styles={{ width: 150, height: 50 }} onClick={handleSaveClick} />
						<Button text={t('cancel')} styles={{ margin: 5, width: 150, height: 50 }} />
						<ClipLoader color="#ffffff" loading={processing} css={override} size={20} />
					</div>
				</div>
			</div>
		</Container>
	)
}

export default FiscalConfig