import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask';
import { CONTAINER } from './styles'

function TEXT_INPUT({ icon, title, styles, placeholder, type, className,
	id, onChange, maxLength, value, disabled, max, regex, regexMessage ,mask}) {

	const [query, setQuery] = useState('')
	const [maxLengthError, setMaxLengthError] = useState(0)
	const [regexError, setRegexError] = useState(false)
	const [isText, setIsText] = useState(false)

	useEffect(() => {
		setQuery(value)
	}, [value])

	const handler = (val, e) => {
		let error = false
		if (maxLength) {
			if (val.length > parseInt(maxLength)) {
				setMaxLengthError(2)
				error = true
			} else if (val.length >= parseInt(maxLength) / 2) setMaxLengthError(1)
			else setMaxLengthError(0)
		}
		if (val && regex && !error) {
			if (!regex.test(val)) {
				if (!regex.test(val)) {
					setRegexError(true)
					error = true
				}
			}
		}

		if (!error) {
			setQuery(val)
			setRegexError(false)
			onChange && onChange(val, e)
		}
	}

	const getInputClassName = () => {
		if (maxLengthError === 2) return 'text_input_error'
		else if (maxLengthError === 1) return 'text_input_alert'
	}

	return (
		<CONTAINER className={className} >
			{title && <h5>{title}</h5>}
			<div className='text_input_container' style={{ ...styles }}>
				{icon && <ion-icon name={icon} />}
				<InputMask value={query}
					onChange={(e) => handler(e.target.value, e)}
					id={id}
					max={max}
					disabled={disabled}
					placeholder={placeholder}
					type={isText ? 'text' : type} 
					mask={mask} />

				{maxLengthError !== 0 &&
					<p className={getInputClassName()}>
						{query ? query.length : '0'}/{maxLength}
					</p>
				}
				{type === 'password' &&
					<ion-icon onClick={() => setIsText(!isText)} name={!isText ? "eye-outline" : "eye-off-outline"}></ion-icon>
				}
			</div>
			{regexError && <p className={'text_input_alert'}>{regexMessage}</p>}
		</CONTAINER>
	)

}

export default TEXT_INPUT