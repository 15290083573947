import React, { useState } from 'react'
import { Container } from './styles'
import { WhitePosLogo } from '../../../assets/images'
import { withRouter } from 'react-router-dom'
import { useWindowSize } from '../../../hooks'
import { Accordion } from '../../../shared_components'
import { connect, useSelector } from 'react-redux'
import t from '../../../config/translate'
import { useEffect } from 'react'

function Nav({ history, logo, menu_keys, whitelabel_name }) {
	const userPermission = useSelector(state => state.auth.permissions)
	const whiteLabel = useSelector(state => state.theme.whitelabel_name)
	const CompanyID = useSelector(state => state.auth.authedStore.CompanyID)

	const windowSize = useWindowSize()
	const [confItens, setConfItens] = useState([])
	const [reportItens, setReportItens] = useState([])

	const confMenuSelector = () => {
		let confMenu = [
			{ name: t('nav.print'), pathname: 'print' },
			{ name: t('nav.users'), pathname: 'users' },
			{ name: t('nav.system'), pathname: 'system' },
			{ name: t('nav.nfceTef'), pathname: 'nfceTef' },
			{ name: t('nav.fiscal'), pathname: 'fiscalConfig' },
			{ name: t('nav.terminals'), pathname: 'terminals' }
		]

		if (whiteLabel.toLowerCase() === 'rede') {
			confMenu = [
				{ name: t('nav.print'), pathname: 'print' },
				{ name: t('nav.users'), pathname: 'users' },
				{ name: t('nav.terminals'), pathname: 'terminalsJustTable' }
			]
		}
		setConfItens(confMenu)
	}

	const confReportSelector = () => {
		let confReport = [
			{
				title: t('nav.sales'),
				items: [
					{ name: t('nav.per_category'), pathname: 'sales_per_group' },
					{ name: t('nav.detailed'), pathname: 'sales_detailed' },
					{ name: t('nav.per_day'), pathname: 'sales_per_day' },
					{ name: t('nav.canceled'), pathname: 'sales_canceled' },
					{ name: t('nav.barcode'), pathname: 'sales_barcode' },
					{ name: t('nav.identificador'), pathname: 'sales_identificador' },
					{ name: t('nav.paymentType'), pathname: 'sales_payamentType' },
					{ name: t('nav.byId'), pathname: 'sales_byId' },
				]
			},
			{
				title: t('nav.closing'),
				items: [
					{ name: t('nav.of_cashier'), pathname: 'closing_register' },
				]
			},
			{
				title: t('nav.billing'),
				items: [
					{ name: t('nav.products_by_pos_billed'), pathname: 'products_by_pos_billed' },
					{ name: t('nav.products_by_pos_courtesy'), pathname: 'products_by_pos_courtesy' },
					{ name: t('nav.dowloader_products_per_user'), pathname: 'dowloader_products_per_user' },
					{ name: t('nav.pos_ranking'), pathname: 'pos_ranking' },
				]
			},
			{
				title: t('nav.cashless'),
				items: [
					{ name: t('nav.cashless_extrato_online'), pathname: 'cashless_extrato_online' },
					{ name: t('nav.cashless_listagem_credito'), pathname: 'cashless_listagem_credito' },
				]
			},
		]

		if (whiteLabel.toLowerCase() === 'rede') {
			confReport = [
				{
					title: t('nav.sales'),
					items: [
						{ name: t('nav.per_category'), pathname: 'sales_per_group' },
						{ name: t('nav.detailed'), pathname: 'sales_detailed' },
						{ name: t('nav.per_day'), pathname: 'sales_per_day' },
					]
				},
				{
					title: t('nav.closing'),
					items: [
						{ name: t('nav.of_cashier'), pathname: 'closing_register' },
					]
				}
			]
		}
		setReportItens(confReport)
	}

	useEffect(() => {
		confMenuSelector()
		confReportSelector()
	}, [CompanyID])

	const render = (requiredPermission, menu, show = true) => {
		if (!show) return ''
		if (requiredPermission.length === 0) return menu
		const hasPermission = () => requiredPermission.find(requiredPermission => userPermission && requiredPermission === userPermission.role) ? true : false
		if (hasPermission()) return menu
		return ''
	}

	const items = () => {
		let auxAns = [
			render([], {
				option: menu_keys.monitor || t('nav.monitor'),
				icon: 'tv-outline',
				pathname: 'monitor',
				id: 'monitor'
			}),
			render([], {
				option: menu_keys.register || t('nav.register'),
				innerOptions: [
					{
						items: [
							{ name: t('group') + 's', pathname: 'categorias' },
							{ name: t('nav.products'), pathname: 'produtos' }
						]
					}
				],
				icon: 'file-tray-full-outline',
				pathname: 'cadastro',
				id: 'register',
			}),
			render([], {
				option: menu_keys.report || t('reports'),
				innerOptions: reportItens,
				icon: 'newspaper-outline',
				pathname: 'report',
				id: 'report',
			}),
			render([],
				{
					option: menu_keys.help || t('nav.estoque'),
					innerOptions: [
						{
							items: [
								{ name: t('nav.estoque.position'), pathname: 'report' },
								{ name: t('nav.estoque.ajuste'), pathname: 'insert' }
							]
						}
					],
					icon: 'storefront-outline',
					pathname: 'estoque',
					id: 'estoque'
				},
				(whiteLabel.toLowerCase() !== 'rede')
			),
			render(['ADM', 'GER', 'GERRD', 'MST'],
				{
					option: menu_keys.config || t('nav.config'),
					innerOptions: [
						{
							items: confItens
						}
					],
					icon: 'settings-outline',
					pathname: 'config',
					id: 'config',
				}),
			render(['ADM', 'GER', 'GERRD', 'MST'],
				{
					option: menu_keys.digital_menu || t('digital_menu'),
					icon: 'qr-code-outline',
					pathname: 'qr_code',
					id: 'qr_code'
				}),
			render([],
				{
					option: menu_keys.help || t('nav.help'),
					icon: 'help-outline',
					pathname: 'help',
					id: 'help'
				},
				(whiteLabel.toLowerCase() === 'rede'))
		]

		let menus = auxAns.filter(menu => menu !== '')
		return menus
	}

	const closeNav = () => {
		const lateralNav = document.getElementById('lateral_nav')
		const lateralNavContainer = document.getElementById('lateral_nav_background')
		lateralNav.classList.remove('active')
		lateralNavContainer.classList.remove('active_lnav_background')
	}

	const navigate = (a, b) => {
		b ? history.push(`/${a}/${b}`) : history.push(`/${a}`)
	}

	return (
		<Container>
			<div onClick={() => closeNav()} id='lateral_nav_background' />

			<div id='lateral_nav' className={windowSize.width > 650 ? 'lateral_nav active' : 'lateral_nav'}>
				<div className={logo && 'white_logo_container'}>
					{logo ? <img src={logo} alt='Company Logo' className='company_logo' /> : <img src={WhitePosLogo} alt='Company Logo' className='company_logo' />}
				</div>

				<Accordion onChange={navigate} options={items()} />
			</div>
		</Container>
	)
}

function mapStateToProps({ theme }) {
	return {
		menu_keys: theme.menu_keys || {},
		logo: theme.logo,
		whitelabel_name: theme.whitelabel_name || 'POS CONTROLE',
	}
}

export default withRouter(connect(mapStateToProps)(Nav))