/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from './styles'
import { LoginImage, LoginLine, PosLogoSquared } from '../../../assets/images'
import { TextInput, Button } from '../../../shared_components'
import loginApi from '../../../services/login'
import { handleGetTokens } from '../../../config/store/actions/auth'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import t from '../../../config/translate'

function Login({ dispatch, location, logo, whitelabel_name }) {

	const [loginError, setLoginError] = useState(false)
	const history = useHistory()
	const redirectBack = () => { history.push('/monitor') }

	const submitLogin = (e) => {
		e.persist()
		e.preventDefault()
		const form = e.target
		let user = {
			email: form['login_user'].value,
			password: form['login_password'].value,
			url: window.location.hostname
		}

		loginApi.login(user)
			.then(res => res.data)
			.then(data => { dispatch(handleGetTokens(data, redirectBack)) })
			.catch(() => setLoginError(true))
	}

	return (
		<Container>
			<Helmet>
				<title>Login | {whitelabel_name}</title>
			</Helmet>
			{/* {console.log('location ...', window.location)} */}
			<img className='login_line' src={LoginLine} alt='login_line' />

			<div className='content_container'>

				<div className='form_container'>
					{logo ? <img src={logo} alt='Company Logo' className='logo' /> : <img src={PosLogoSquared} className='logo' />}

					<div>
						<h1>{t('login.title_1')}<br />
							{t('login.title_2')} <span>{t('login.title_3')}</span><br />
							{t('login.title_4')}</h1>
						<form onSubmit={submitLogin} className='input_container'>
							<TextInput id='login_user' placeholder={t('login.user')} />
							<TextInput type={'password'} id='login_password' placeholder={t('login.password')} />
							<Button type={'submit'} text={t('login.login')} />
							{loginError && <p className='error_message'>{t('login.error')}</p>}

						</form>
						<p onClick={() => history.push('/recoverPassword')} className='hiperlink_button'>{t('login.forgot_password')}</p>
					</div>

				</div>
				<div className='image_container'>
					<img src={LoginImage} alt='POS Machine' />
				</div>
			</div>


		</Container>
	)
}

function mapStateToProps({ auth, theme }) {
	return {
		tokens: auth.tokens || {},
		logo: theme.logo,
		whitelabel_name: theme.whitelabel_name || 'POS CONTROLE',
	}
}

export default withRouter(connect(mapStateToProps)(Login))