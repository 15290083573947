/* eslint-disable import/no-anonymous-default-export */
import base from './base'

export default {
	getMonitorData(companyID, type, range) {
		if (range) {
			return base.get(`/api-con/company/${companyID}/reports/${type}/${range}`)
		}
		return base.get(`/api-con/company/${companyID}/reports/${type}`)
	}
}