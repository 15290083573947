import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    background: var(--color-background);
    font-family: var(--font-family-primary);

    .content_container{
        height: 100vh;
        overflow: auto;
        flex: 1;
    }

    .inner_container {
        background: var(--color-light);
        padding: 20px;
        margin: 0 30px 30px 30px;
        border-radius: var(--border-radius-medium);
        box-shadow: var(--box-shadow-card);
        overflow: visible;
    }

    .section_title {
        font-size: 18px;
        padding: 10px 0px 10px 10px;
        margin: 40px 0 20px;
        font-weight: 600;
        border-bottom: 1px solid var(--color-primary);

        &:first-child {
            margin-top: 0px;
        }
    }
   
    .input_title {
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        color: var(--color-dark);
        margin: 10px;
    }

    .row {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0;
    }

    .column {
        flex: 1;
        justify-content: flex-start;
        max-width: 48%;
        &:last-child {
            margin-right: 0px;
        }
    }

    table {
        font-family: var(--font-family-secondary);
    }

    .table-bordered>tbody>tr>td {
        border: none;
    }

    .product_revenue_name_image {
        display: flex;
        align-items: center;

        img {
            max-height: 30px;
            width: 30px;
            margin-right: 12px;
        
        }

        p {
            color: var(--color-primary);
        }
    }
`