/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Container } from './styles'
import { RedeLogin, RedeMarca } from '../../../assets/images'
import { TextInput, Button } from '../../../shared_components'
import loginApi from '../../../services/login'
import { handleGetTokens, handleUserPermissions } from '../../../config/store/actions/auth'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import t from '../../../config/translate'

function Login({ dispatch, history, location, whitelabel_name }) {

	const [loginError, setLoginError] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const submitLogin = (e) => {
		e.persist()
		e.preventDefault()
		const form = e.target
		let user = {
			email: form['login_user'].value,
			password: form['login_password'].value,
			url: window.location.hostname
		}
		loginApi.login(user)
			.then(res => {
				dispatch(handleGetTokens(res.data, redirectBack))
				//dispatch(handleUserPermissions({ role: 'MST' }))
			})
			.catch(() => { setLoginError(true); setIsLoading(false) })
	}

	const redirectBack = () => {
		if (location.state && location.state.referrer) {
			history.push(location.state.referrer)
		} else {
			history.push('/')
		}

	}


	return (
		<Container>
			<Helmet>
				<title>Login | {whitelabel_name}</title>
			</Helmet>
			<div className='top_nav'>
				<img src={RedeMarca} alt='Rede Gestão Fácil - Logo' className='logo' />
			</div>
			<div className='content_container'>

				<div className='form_container'>

					<h1><b>Rede</b> Gestão Fácil</h1>
					<h2>Conte com um serviço da rede<br />para simplificar sua rotina</h2>
					<div className='colored_div' />
					<form onSubmit={submitLogin} className='input_container'>
						<TextInput id='login_user' placeholder={t('login.user')} />
						<TextInput type={'password'} id='login_password' placeholder={t('login.password')} />
						<Button type={'submit'} text={t('login.login')} />
						{loginError && <p className='error_message'>{t('login.error')}</p>}
						<p onClick={() => history.push('/recoverPassword')} className='hiperlink_button'>{t('login.forgot_password')}</p>
					</form>

				</div>
				<div className='image_container'>
					<img src={RedeLogin} alt='POS Machine' />
				</div>
			</div>


		</Container>
	)
}

function mapStateToProps({ auth, theme }) {
	return {
		tokens: auth.tokens || {},
		logo: theme.logo,
		whitelabel_name: theme.whitelabel_name || 'Rede',
	}
}


export default withRouter(connect(mapStateToProps)(Login))