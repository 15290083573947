import React, { useEffect, useState } from "react"
import t from "../../../../config/translate"
import { formatPendingData } from "../../../../utils/formatters"
import monitorAPI from "../../../../services/monitor"
import PendingData from "./../PendingData"

export function SaleItemPosGroup({ companyID }) {
	const [pendingData, setPendingData] = useState([])

	useEffect(() => {
		let componentMounted = true

		monitorAPI
			.getMonitorData(companyID, "saleitemspospgroupposuser")
			.then((res) => {
				return res.data
			})
			.then(formatPendingData)
			.then((data) => componentMounted && setPendingData(data))
			.catch((err) => console.error("[SaleItemPosGroup]", err.message))

		return () => {
			componentMounted = false
		}
	}, [companyID])

	return <PendingData title={t("monitor.graph_sent")} data={pendingData} />
}
