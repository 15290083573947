import React from "react";
import { DateRange } from "../../../shared_components";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import t from "../../../config/translate";
import { connect } from "react-redux";
import { formatCurrency } from "../../../utils/formatters";

const { SearchBar, ClearSearchButton } = Search;

function ReportTable({
//   companyID,
  data,
//   setData,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  updateRange,
}) {
  const groupHeaders = () => {
    return (
      <table
        style={{ marginBottom: 0, borderBottom: 0 }}
        className="table  table-bordered"
      >
        <tbody>
          <tr>
            <th>{t("date")}</th>
            <th>{t("day")}</th>
            <th style={{ borderBottom: "1px solid lightgray" }} colSpan="4">
              {t("report_sales_per_day.revenue")}
            </th>
            <th style={{ borderBottom: "1px solid lightgray" }} colSpan="3">
              {t("report_sales_per_day.amount")}
            </th>
            <th>{t("report_sales_per_day.avg_ticket")}</th>
            <th>{t("report_sales_per_day.canceled_sales")}</th>
          </tr>
        </tbody>
      </table>
    );
  };

  const columns = [
    {
      dataField: "Data",
      text: "",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b></b>
          </p>
        ) : (
          <p>{value.Data}</p>
        ),
    },
    {
      dataField: "WeekDay",
      text: "",
    },
    {
      dataField: "Bruto",
      text: t("report_sales_per_day.gross") + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b>{formatCurrency(value.Bruto).substring(2)}</b>
          </p>
        ) : (
          <p>{formatCurrency(value.Bruto).substring(2)}</p>
        ),
    },
    {
      dataField: "Desconto",
      text: t("report_sales_per_day.discount") + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b>{formatCurrency(value.Desconto).substring(2)}</b>
          </p>
        ) : (
          <p>{formatCurrency(value.Desconto).substring(2)}</p>
        ),
    },
    {
      dataField: "Liquido",
      text: t("report_sales_per_day.liquid") + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b>{formatCurrency(value.Liquido).substring(2)}</b>
          </p>
        ) : (
          <p>{formatCurrency(value.Liquido).substring(2)}</p>
        ),
    },
    {
      dataField: "Acumulado",
      text: t("report_sales_per_day.accumulated") + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b>{formatCurrency(value.Acumulado).substring(2)}</b>
          </p>
        ) : (
          <p>{formatCurrency(value.Acumulado).substring(2)}</p>
        ),
    },
    {
      dataField: "Vendas" + " (R$)",
      text: t("report_sales_per_day.sales"),
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b>{value.Vendas}</b>
          </p>
        ) : (
          <p>{value.Vendas}</p>
        ),
    },
    {
      dataField: "ItensVendidos",
      text: t("report_sales_per_day.items"),
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b>{value.ItensVendidos}</b>
          </p>
        ) : (
          <p>{value.ItensVendidos}</p>
        ),
    },
    {
      dataField: "Quantidade",
      text: t("report_sales_per_day.units"),
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b>{value.Quantidade}</b>
          </p>
        ) : (
          <p>{value.Quantidade}</p>
        ),
    },
    {
      dataField: "TicketMédio",
      text: "",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b>{formatCurrency(value.TicketMédio).substring(2)}</b>
          </p>
        ) : (
          <p>{formatCurrency(value.TicketMédio).substring(2)}</p>
        ),
    },
    {
      dataField: "VendasCanceladas",
      text: "",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) =>
        value.bold ? (
          <p>
            <b>{value.VendasCanceladas}</b>
          </p>
        ) : (
          <p>{value.VendasCanceladas}</p>
        ),
    },
  ];

  return (
    <div className="table_container">
      <ToolkitProvider keyField="id" data={data} columns={columns} search>
        {(props) => {
          return (
            <div className="user_table">
              <div className="table_title">
                <h2>{t("report_sales_per_day.title")}</h2>

                <div className="table_search_container">
                  <DateRange
                    initialDate={initialDate}
                    finalDate={finalDate}
                    setFinalDate={setFinalDate}
                    setInitialDate={setInitialDate}
                    submit={updateRange}
                  />

                  <div className="search_div">
                    <SearchBar
                      placeholder={t("search")}
                      {...props.searchProps}
                    />
                    <ClearSearchButton
                      text={t("clear")}
                      {...props.searchProps}
                    />
                  </div>
                </div>
              </div>

              <hr />
              <BootstrapTable
                caption={groupHeaders()}
                {...props.baseProps}
                striped
              />
            </div>
          );
        }}
      </ToolkitProvider>
    </div>
  );
}

export default connect()(ReportTable);
