/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'
import { store } from '../config/store'

let state = store.getState();
// console.log(state.auth.tokens.token);
let tokens = state.auth.tokens ? state.auth.tokens : { token: '' };

store.subscribe(() => {
	state = store.getState();
	tokens = state.auth.tokens ? state.auth.tokens : { token: '' };
})

// const baseURL = 'https://app-web-01-apiweb.azurewebsites.net/api/'
const baseURL = `${process.env.REACT_APP_SERVER}/api-con/`

const instance = axios.create({
	baseURL: baseURL,
	headers: {
		'Authorization': `Bearer ${tokens.token}`,
		'Content-Disposition': "attachment; filename=template.xlsx",
		'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

	},
	responseType: 'arraybuffer',
});

let chaveRequest = 0;
instance.interceptors.request.use((config) => {
	if (tokens) {
		config.headers = Object.assign({}, config.headers, {
			Authorization: `Bearer ${tokens.token}`,
		});
	} else {
		config.headers = {
			'Access-Control-Allow-Origin': '*'
		};
	}
	chaveRequest++;
	let _chaveRequest = "_" + chaveRequest;
	config.chaveRequest = _chaveRequest;

	return config;
});


export default {
	getSheets(companyID) {
		const url = `company/${companyID}/products/xlsx?take=10&page=1&orderType=ASC&orderBy=Name&status=ABCDEABC-ABCD-ABCD-ABCD-ABCED1457822`

		return instance.get(url)
		// return instance.get(`productCompany/baixarLayoutExcel/${companyID}`)
	},
	saveSheets(companyID, data) {
		const url = `company/${companyID}/products/xlsx`	

		return axios.post(baseURL + url, data, {
			headers: {
				'Authorization': `Bearer ${tokens.token}`,
				'Content-Type':'multipart/form-data'
			}
		})
	},
	// saveSheets(companyID, file64, filename) {
	// 	return axios.create({
	// 		baseURL: baseURL,
	// 		headers: {
	// 			'Authorization': `Bearer ${tokens.token}`,
	// 		}
	// 	}).post(`productCompany/importarExcel`, {
	// 		companyID: companyID,
	// 		file: {
	// 			base64: file64,
	// 			erros: [],
	// 			filename: filename,
	// 			filetype: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	// 			valid: true
	// 		}
	// 	})
	// },
	saveImages(companyID, file64, fileName) {
		return axios.create({
			baseURL: baseURL,
			headers: {
				'Authorization': `Bearer ${tokens.token}`,
			}
		}).post(`productCompany/importarZipImgs`, {
			companyID: companyID,
			file: {
				base64: file64,
				filename: fileName,
				filetype: "application/x-zip-compressed"
			}
		})
	}
}