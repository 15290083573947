import axios from "axios"
import { store } from "../config/store"
import { handleGetTokens, handleLogout } from "../config/store/actions/auth"
import loginApi from './login'

const baseURL = process.env.REACT_APP_SERVER

let state = store.getState()
let tokens = state.auth.tokens ? state.auth.tokens : { token: "" }

store.subscribe(() => {
	state = store.getState()
	tokens = state.auth.tokens ? state.auth.tokens : { token: "" }
})

const instance = axios.create({
	baseURL: baseURL,
	headers: {
		Authorization: `Bearer ${tokens.token}`,
		"Content-type": "application/json"
	},
})

const base = {
	async get(url) {
		try {
			return instance.get(url)
		} catch (error) {
			// console.log('base get error', error)
			if (error?.response?.status === 401) return instance.get(url)
		}
	},
	post(url, data) {
		try {
			return instance.post(url, data)
		} catch (error) {
			// console.log('base post error', error)
			if (error?.response?.status === 401) return instance.post(url, data)
		}
	},
	put(url, data) {
		try {
			return instance.put(url, data)
		} catch (error) {
			// console.log('base put error', error)
			if (error?.response?.status === 401) return instance.put(url, data)
		}
	}
}


let chaveRequest = 0
instance.interceptors.request.use((config) => {
	if (tokens) config.headers = Object.assign({}, config.headers, { Authorization: `Bearer ${tokens.token}` })
	else config.headers = { "Access-Control-Allow-Origin": "*" }

	chaveRequest++
	let _chaveRequest = "_" + chaveRequest
	config.chaveRequest = _chaveRequest

	return config
})

instance.interceptors.response.use((response) => response, async (error) => {
	const { auth } = store.getState()
	let refreshDate = tokens.data
	let diff = Math.abs((Date.now() - refreshDate) / 1000)
	if (diff < 1) return

	if (auth.tokens && error.response.status === 401) {
		const { tokenRefresh } = auth.tokens

		loginApi.refresh(tokenRefresh)
			.then(rst => {
				const token = rst.data.token
				return store.dispatch(handleGetTokens({ token, tokenRefresh }))
			})
			.catch(err => { return store.dispatch(handleLogout()) })
	}
	return Promise.reject(error)
})

export default base