import React from 'react'
import { Container } from './styles'
import { connect } from 'react-redux'
import { handleAuthedStore, handleLogout } from '../../../config/store/actions/auth'
import { withRouter } from 'react-router-dom'
import { DropdownMenu } from '../../../shared_components'
import { canConvertToJson } from '../../../utils/jsonValidator'

function TopNav({ pageTitle, dispatch, history, authedStore, companies }) {
	const successLogout = () => { history.push('/login') }
	if (canConvertToJson(companies)) companies = JSON.parse(companies)

	const toggleLateralNav = () => {
		const lateralNav = document.getElementById('lateral_nav')
		const lateralNavContainer = document.getElementById('lateral_nav_background')
		if (!lateralNav.classList.contains('active')) {
			lateralNav.classList.add('active')
			lateralNavContainer.classList.add('active_lnav_background')
		} else {
			lateralNav.classList.remove('active')
			lateralNavContainer.classList.remove('active_lnav_background')
		}
	}

	const changeStore = (store) => {
		const specificStore = {
			'Name': store.Trademark,
			'CompanyID': store.CompanyID,
			'CompanyGroupID': authedStore.CompanyGroupID
		}
		dispatch(handleAuthedStore(specificStore))
	}

	return (
		<Container>
			<div onClick={() => toggleLateralNav()} className='top_nav_title'>
				<ion-icon name='menu-outline' />
				<h1>{pageTitle}</h1>
			</div>

			<div className='top_nav_items'>

				{authedStore && companies.length > 1 &&
					<DropdownMenu user={authedStore.Name}
						items={companies}
						itemInputKey={'Trademark'}
						itemOutputKey={'CompanyID'}
						onChange={(e) => changeStore(e)} />}

				<div onClick={() => dispatch(handleLogout(successLogout))} className='icon_button'>
					<ion-icon name='exit-outline' />
				</div>

			</div>
		</Container>

	)
}

function mapStateToProps({ auth }) {
	return {
		authedStore: auth.authedStore,
		companies: auth.company ? auth.company.Companys : []
		//companies: auth.company ? JSON.parse(auth.company.Companys) : []
	}
}

export default withRouter(connect(mapStateToProps)(TopNav))