import React, { useEffect, useState } from 'react'
import { Container } from './styles'
import { Nav, TopNav } from '../../components'
import Helmet from 'react-helmet'
import t from '../../../config/translate'
import { Table } from '../../../shared_components'
import { useSelector } from 'react-redux'
import userAPI from './../../../services/user'

function UserConfig() {
	const whitelabel_name = useSelector(state => state.theme.whitelabel_name)
	const CompanyID = useSelector(state => state.auth.authedStore.CompanyID)
	// const CompanyGroupID = useSelector(state => state.auth.authedStore.CompanyGroupID)
	// const userPermission = useSelector(state => state.auth.permissions)
	const pageTitle = t('user.title')

	const [data, setData] = useState([])

	useEffect(() => {
		let componentMounted = true
		userAPI.getByCompany(CompanyID)
			.then(res => res.data)
			.then(data => componentMounted && setData(data))
			.catch((err) => console.error("[systemUserConfig]", err))
		return () => componentMounted = false
	}, [CompanyID])

	const createRow = (row, field) => (<div className="table_row">{row[field]}</div>)
	const colums = [
		/*
		{
			name: <div className="table_header" style={{ fontSize: '15px' }}><b>ID</b></div>,
			selector: (row) => row["UsersID"],
			cell: (row) => createRow(row, "UsersID"),
			sortable: true,
		},
		*/
		{
			name: <div className="table_header" style={{ fontSize: '15px' }}><b>Tipo</b></div>,
			selector: (row) => row["AccessLevelName"],
			cell: (row) => createRow(row, "AccessLevelName"),
			sortable: true,
		},
		{
			name: <div className="table_header" style={{ fontSize: '15px' }}><b>Nome</b></div>,
			selector: (row) => row["Name"],
			cell: (row) => createRow(row, "Name"),
			sortable: true,
		},
		{
			name: <div className="table_header" style={{ fontSize: '15px' }}><b>Email</b></div>,
			selector: (row) => row["Email"],
			cell: (row) => createRow(row, "Email"),
			sortable: true,
		}
	]

	return (
		<Container>
			<Helmet>
				<title>{pageTitle} | {whitelabel_name}</title>
			</Helmet>

			<Nav />
			<div className='content_container'>
				<TopNav pageTitle={pageTitle} />
				<div className='form_container'>
					<Table
						title={t('users.list')}
						data={data}
						colums={colums}
						search={{ show: true, searchPlaceHolder: t("search"), searchButtonClearText: t("clear") }}
						dense={false}
					/>
				</div>
			</div>
		</Container>
	)
}

export default UserConfig