import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    background: var(--color-background);
    font-family: var(--font-family-primary);

    .content_container{
        height: 100vh;
        overflow: auto;
        flex: 1;
    }

    .form_container {
        background: var(--color-light);
        padding: 20px;
        margin: 0 30px 30px 30px;
        border-radius: var(--border-radius-medium);
        box-shadow: var(--box-shadow-card);
        overflow: visible;
        display: flex;
        flex-wrap: wrap;
    }

    .section_title {
        font-size: 18px;
        padding: 10px 0px 10px 10px;
        margin: 40px 0 20px;
        font-weight: 600;
        border-bottom: 1px solid var(--color-primary);

        &:first-child {
            margin-top: 0px;
        }
    }
    iframe {    
        min-height: 200px;
        min-width: 250px;
        max-width: 100%;
    }

    .input_title {
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        color: var(--color-dark);
        margin: 10px;
    }

    .button_row {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 40px 20px;
        button {
            margin-right: 20px;
        }

        &:last-child {
            margin-bottom: 20px;
        }
    }

    .column {
        flex: 1;
        margin-right: 20px;

        &:last-child {
            margin-right: 0px;
        }
    }
`