import styled from 'styled-components'

export const Container = styled.div`
    background: linear-gradient(45deg, #FFFFFF, #DCE1E7);
    
    .login_line{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1;
    }

    .content_container{
        height: 100vh;
        display: flex;
        flex-wrap: wrap-reverse;
        overflow: auto;
        flex: 1;
    }

    .logo {
        max-width: 180px;
        margin: 50px 0 100px;
    }

    .form_container {
        height: 100vh;
        flex: 1.3;
        z-index: 4;
        display: flex;
        position: relative;
        padding: 0px 5% 40px 5%;
        flex-direction: column;
        justify-content: flex-start;
        
        h1 {
            font-family: Gotham, sans-serif;
            font-size: 48px;
            color: var(--color-dark);
        }
        
        span {
            color: var(--color-primary);
        }

        .input_container {
            margin-top: 40px;
            max-width: 300px;
        }
    }

    .image_container {
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 300px;
        z-index: 2;
        img {
            max-width: 90%;
        }
    }

    .error_message {
        color: var(--color-accent);
        font-weight: 600;
        margin-top: 20px;
    }



`