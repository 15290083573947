/* eslint-disable import/no-anonymous-default-export */
export default {

	//general
	'user': 'Usuários',
	'name': 'Nome',
	'image': 'Imagem',
	'status': 'Status',
	'date': 'Data',
	'hour': 'Hora',
	'day': 'Dia',
	'POS': 'POS',
	'price': 'Preço',
	'description': 'Descrição para cardápio',
	'barcode': 'Código de Barras',
	'group': 'Categoria',
	'stores': 'Lojas',
	'unit': 'Unidade',
	'type': 'Tipo',
	'email': 'E-mail',
	'reports': 'Relatórios',
	'password': 'Senha',
	'access_level': 'Nível de acesso',
	'active': 'Ativo',
	'inactive': 'Inativo',
	'all': 'Todos',
	'digital_menu': 'Cardápio digital',
	'register.error.img_too_large': 'Oh-oh, a imagem carregada é muito grande.',
	'register.error.img_unsupported': 'Opa, apenas imagens do tipo PNG são aceitas.',
	//cta
	'add': 'adicionar',
	'edit': 'editar',
	'save': 'salvar',
	'clear': 'limpar',
	'search': 'Pesquisar',
	'send': 'enviar',
	'quantity': 'Quantidade',
	'product': 'Produto',
	'insert': 'Inserir',
	'select': 'Selecionar',
	'cancel': 'Cancelar',

	'noEntry': 'Sem entradas',
	'noData': 'Sem dados a serem mostrados',

	'current_currency': 'R$',

	'nav.help': 'Ajuda',
	'nav.config': 'Configurações',
	'nav.print': 'Impressão',
	'nav.users': 'Usuários',
	'nav.of_cashier': 'Dos caixas',
	'nav.products_by_pos_billed': 'Produtos por POS - Faturado',
	'nav.products_by_pos_courtesy': 'Produtos por POS - Cortesia',
	'nav.dowloader_products_per_user': 'Produtos baixados por Usuário',
	'nav.pos_ranking': 'Rankings POS',

	'nav.cashless_extrato_online': 'Extrato on-line',
	'nav.cashless_listagem_credito': 'Listagem de Crédito',


	'nav.closing': 'Fechamento',
	'nav.per_day': 'Por Dia',
	'nav.canceled': 'Canceladas',
	'nav.barcode': 'Código de Barra',
	'nav.identificador': 'Identificador',
	'nav.paymentType': 'Tipo de pagamento',
	'nav.byId': 'Consulta por ID',
	'nav.detailed': 'Detalhadas',
	'nav.per_category': 'Por Categoria',
	'nav.sales': 'Vendas',
	'nav.billing': 'Faturamento',
	'nav.cashless': 'Cashless',
	'nav.register': 'Cadastro',
	'nav.products': 'Produtos',
	'nav.monitor': 'Monitor',
	'nav.estoque': 'Estoque',
	'nav.estoque.position': 'Posição',
	'nav.estoque.ajuste': 'Ajuste',
	'nav.system': 'Sistema',
	'nav.nfceTef': 'NFCE-TEF',
	'nav.terminals': 'Terminais',
	'nav.fiscal': 'Dados Fiscais',
	'report.stock': 'Posição de Estoque',
	'report.adjustment': 'Ajuste de Estoque',
	'adjustment': 'Ajuste',
	'out': 'Saída',
	'DoStockAdjustment': 'Ajustar estoque',

	//general notifications
	'success.update_data': 'Dados salvos com sucesso!',
	'error.update_data': 'Erro ao atualizar os dados',

	//Login screen
	'login.login': 'entrar',
	'login.user': 'usuario ou e-mail',
	'login.password': 'senha',
	'login.title_1': 'O sistema',
	'login.title_2': 'de PDV',
	'login.title_3': 'ideal',
	'login.title_4': 'para sua maquininha',
	'login.error': 'Opa, ocorreu um erro. Por favor, verifique seus dados.',
	'login.forgot_password': 'Esqueci a senha',

	//Recover Password
	'recover_password.title': 'Atualizar Senha',
	'recover_password.new_password': 'Nova senha',
	'recover_password.repeat_password': 'Repita sua senha',
	'recover_password.error': 'Opa, verfica essa senha nova pra a gente? A senha deve ter entre 8 e 15 carateres.',
	'recover_password.not_found': 'Hm, ocorreu algum erro. Pode solicitar a recuperação de senha novamente?',
	'recover_password.success': 'Senha alterada com sucesso. Estamos te redirecionando ao login :)',


	//Recover Password Email
	'recover_password_email.title': 'Recuperar senha',
	'recover_password_email.helmet_title': 'Esqueci a senha',
	'recover_password_email.error': 'Hm, ocorreu algum erro. Verifica seu email pra a gente?',
	'recover_password_email.success': 'Enviamos um e-mail com informações sobre como recuperar sua senha',
	'recover_password_email.not_found': 'Hm, não encontramos este e-mail',
	'recover_password_email.back_to_login': 'Voltar ao login',
	//monitor
	'monitor.title': 'Monitor',
	'monitor.range_today': 'Hoje',
	'monitor.range_days': 'dias',
	'monitor.range_year': 'Ano',
	'monitor.graph_revenue': 'Faturamento',
	'monitor.graph_avg_ticket': 'Ticket Médio',
	'monitor.graph_payment': 'Pagamento',
	'monitor.graph_products': 'Produtos',
	'monitor.graph_sent': 'Envios',
	'monitor.graph_maps': 'Mapas',
	'monitor_graph.no_data': 'Nenhum dado foi encontrado para este período.',
	'monitor_graph.no_sales': 'Nenhum venda foi registrada neste período.',
	'product_revenue.revenue': 'Faturamento',
	'product_revenue.sales': 'Vendas',
	'product_revenue.product': 'Produto',
	'product_revenue.amount': 'Qtd',
	'product_revenue.value': 'Valor',
	'maps_graph.revenue': 'Faturamento',
	'pending_data.subtitle': 'Pendências',
	'pending_data.pos': 'POS',
	'pending_data.amount': 'Qtd',
	'pending_data.value': 'Valor',
	'pending_data.battery': 'Bateria',
	'pending_data.sales': 'Vendas',
	'pending_data.total': 'Total',
	'pending_data.no_sent': 'Nenhum envio foi registrado neste período.',

	//group_register
	'group_register.title': 'Cadastro de Categorias',
	'group_register.add': 'Adicionar Categoria',
	'group_register.table_title': 'Categorias de Produtos',
	'group_register.error_updating_1': 'Erro ao atualizar o campo',
	'group_register.error_updating_2': 'da categoria',
	'group_register.error_status': 'Erro ao atualizar o status da categoria',
	'group_register.no_name': 'Hum, acho que esqueceu de preencher o nome da categoria',
	'group_register.no_image': 'Oh-Oh, a categoria precisa de uma imagem',
	'group_register.error': 'Ocorreu um erro ao tentar criar a categoria agora, pedimos desculpas. Pode tentar de novo?',
	'success.toggle_group': 'Status da categoria atualizado com sucesso!',
	'error.get_groups': 'Oh-oh, ocorreu um erro ao carregar categorias',
	'error.group_name_type': "Apenas letras e números são aceitos para nomear categorias.",
	'error.group_name_length': 'O nome do Categoria deve ter entre 1 e 20 caracteres',

	//add_group
	'add_group.new_group': 'Novo Categoria',
	'add_group.group_name': 'Nome da Categoria',

	//table edit image cell
	'table_edit_image.edit_image': 'Editar Imagem',
	'table_edit_image.image_message': 'Clique na imagem atual para escolher uma nova',

	//product_register
	'product_register.title': 'Cadastro de Produtos',
	'product_register.add': 'Adicionar Produto',
	'product_register.export': 'Exportar Planilha',
	'product_register.import': 'Importar Planilha',
	'product_register.import_images': 'Importar Imagens',
	'product_register.table_title': 'Produtos',
	'product_register.error_updating_1': 'Erro ao atualizar o campo',
	'product_register.error_updating_2': 'do produto',
	'product_register.error_status': 'Erro ao atualizar o status do produto',
	'product_register.alert_message': 'Deseja criar outro produto?',
	'product_edit.table_title': 'Editar',
	'product_register.enabled': 'Habilitados',
	'product_register.disabled': 'Desabilitados',
	'success.create_product': 'Produto criado com sucesso!',
	'error.create_product': 'Oh-oh, ocorreu um erro ao criar o produto! Tente novamente :)',
	'success.edit_product': 'Produto atualizado com sucesso!',
	'error.edit_product': 'Oh-oh, ocorreu um erro ao atualizar o produto! Tente novamente :)',
	'success.toggle_product': 'Status do Produto atualizado com sucesso!',
	'error.toggle_product': 'Oh-oh, ocorreu um erro ao atualizar o status do produto! Tente novamente :)',
	'error.product_name_regex': 'Apenas letras e números são aceitos para nomear produtos.',
	'error.codigo_regex': 'Apenas letras e números são aceitos para codigo de produtos.',
	'error.product_name_length': 'O nome do produto deve ter entre 1 e 40 caracteres',

	'error_import_dialog.title': 'Erros encontrados na planilha',
	'error_import_image_dialog.title': 'Erros encontrados nas imagens',
	'error_import_dialog.error_message': 'Mensagem de Erro',
	'error_import_dialog.product_row': 'Linha do Produto',
	'error_import_dialog.product_column': 'Coluna',

	//product register dialog
	'product_register_dialog.title': 'Novo Produto',
	'product_register.product_name': 'Nome do Produto',
	'product_register.codigo': 'Código do Produto',
	'product_register_dialog.price_per_store': 'Preço por loja',

	//report

	'report_title': 'Relatório',
	'report_closing_register.title': 'Fechamento de Caixa',
	'report_closing_register.liquid_revenue': 'Faturamento Líquido',
	'report_closing_register.supply': 'Suprimento',
	'report_closing_register.bleed': 'Sangria',

	// ReportBillingProductsByPOSBilled
	'report_products_billing.title': 'Produtos por POS Faturado',
	'report_products_billing.description': 'Descrição...',
	'report_products_billing.property_number': 'POS',
	'report_products_billing.internal_code': 'Cód. Produto',
	'report_products_billing.product_name': 'Produto',
	'report_products_billing.sales': 'QTD Vendas',
	'report_products_billing.amount': 'Quantidade',
	'report_products_billing.chargeback_amount': 'QTD Estorno',
	'report_products_billing.unit': 'Unitário',
	'report_products_billing.total': 'Total',

	// ReportBillingProductsByPOSCourtesy
	'report_products_courtesy.title': 'Produtos por POS Cortesia',
	'report_products_courtesy.description': 'Descrição...',
	'report_products_courtesy.property_number': 'POS',
	'report_products_courtesy.product_name': 'Produto',
	'report_products_courtesy.sales': 'QTD Vendas',
	'report_products_courtesy.amount': 'Quantidade',
	'report_products_courtesy.chargeback_amount': 'QTD Estorno',
	'report_products_courtesy.unit': 'Unitário',
	'report_products_courtesy.total': 'Total',


	// ReportCashlessExtratoOnline
	'report_cashless_extrato_online.title': 'Extrato Online',
	'report_cashless_extrato_online.description': 'Descrição...',
	'report_cashless_extrato_online.comany_name': 'Loja',
	'report_cashless_extrato_online.mifare_card_code': 'MifareCardCode',
	'report_cashless_extrato_online.cpfcnpj': 'CPFCNPJ',
	'report_cashless_extrato_online.saldo': 'Saldo',
	'report_cashless_extrato_online.previous_balance': 'Balanço Prévio',
	'report_cashless_extrato_online.card_balance': 'Card Balance',
	'report_cashless_extrato_online.saldo_reset': 'Saldo Reset',
	'report_cashless_extrato_online.st': 'ST',

	// ReportCashlessListagemCredito
	'report_cashless_listagem_credito.title': 'Listagem de Crédito',
	'report_cashless_listagem_credito.description': 'Descrição...',
	'report_cashless_listagem_credito.comany_name': 'Loja',
	'report_cashless_listagem_credito.property_number': 'Propriedade do número',
	'report_cashless_listagem_credito.data_hora': 'Data e hora',
	'report_cashless_listagem_credito.nfc_transactions_pos': 'NFCTransactionsPosCodeID',
	'report_cashless_listagem_credito.payment_type': 'Tipo de Pagamento',
	'report_cashless_listagem_credito.amt_total': 'Quantidade total',
	'report_cashless_listagem_credito.tef_type': 'TEFType',
	'report_cashless_listagem_credito.tef_autorizacao': 'TEFAutorizacao',
	'report_cashless_listagem_credito.tef_rede': 'TEFRede',
	'report_cashless_listagem_credito.tef_cartao': 'TEFCartao',


	// ReportBillingPOSRanking
	'report_pos_ranking.title': 'Rankings POS',
	'report_pos_ranking.description': 'Descrição....',
	'report_pos_ranking.property_number': 'POS',
	'report_pos_ranking.sales': 'Vendas',
	'report_pos_ranking.total': 'Total',
	'report_pos_ranking.desc_value': 'Valor Desconto',
	'report_pos_ranking.est_value': 'Valor Estorno',
	'report_pos_ranking.liquid_total': 'Total Líquido',

	// ReportBillingDowloaderProductsPerUser
	'report_dowloader_products_per_user.title': 'Produtos baixados por usuário',
	'report_dowloader_products_per_user.description': 'Descrição....',
	'report_dowloader_products_per_user.user_name': 'Usuário',
	'report_dowloader_products_per_user.product_name': 'Descrição do produto',
	'report_dowloader_products_per_user.unit': 'Unitario',
	'report_dowloader_products_per_user.amount': 'Quantidade',
	'report_dowloader_products_per_user.total': 'Total',


	'report_detailed_sales.title': 'Vendas Detalhadas',
	'report_detailed_sales.number': 'Número',
	'report_detailed_sales.product': 'Produto',
	'report_detailed_sales.unit': 'Un',
	'report_detailed_sales.amount': 'Qtd',
	'report_detailed_sales.single': 'Unitário',
	'report_detailed_sales.total': 'Total',

	'report_sales_per_day.title': 'Vendas por dia',
	'report_sales_per_day.revenue': 'Faturamento',
	'report_sales_per_day.amount': 'Quantidade',
	'report_sales_per_day.avg_ticket': 'Ticket Médio',
	'report_sales_per_day.canceled_sales': 'Vendas Canceladas',
	'report_sales_per_day.gross': 'Bruto',
	'report_sales_per_day.discount': 'Desconto',
	'report_sales_per_day.liquid': 'Liquido',
	'report_sales_per_day.accumulated': 'Acumulado',
	'report_sales_per_day.sales': 'Vendas',
	'report_sales_per_day.items': 'Itens',
	'report_sales_per_day.units': 'Unidades',

	'report_sales_per_group.title': 'Vendas por categoria',
	'report_sales_per_group.amount': 'Quantidade',
	'report_sales_per_group.group': 'Categoria',
	'report_sales_per_group.produto': 'Produto',
	'report_sales_per_group.unit': 'Un',
	'report_sales_per_group.single': 'Unitário',
	'report_sales_per_group.total': 'Total',

	'print_config.title': 'Configuração de Impressão',
	'print_config.print': 'Impressão',
	'print_config.print_mode': 'Forma de Impressão',
	'print_config.logo': 'Logo',
	'print_config.print_info': 'Conteúdo para impressão',
	'print_config.header1': 'Cabeçalho 1',
	'print_config.header2': 'Cabeçalho 2',
	'print_config.message1': 'Mensagem 1',
	'print_config.message2': 'Mensagem 2',
	'print_config.message3': 'Mensagem 3',
	'success.save_print_config': 'Configurações de Impressão atualizadas com sucesso!',
	'error.save_print_config': 'Ops! Ocorreu um erro ao atualizar configurações de impressão. Tente novamente :)',
	'error.get_print_config': 'Erro ao carregar configurações de impressão',
	'print_config.removeImage': 'Remover Imagem',


	'qr_config.title': 'QR Code - Cardápio',
	'qr_config.menu_config': 'Configurações do Cardápio',
	'qr_conifg.menu_logo': 'Logo - Cardápio',
	'qr_config.get_error': 'Erro ao carregar informações de QR Code',

	'user_config.title': 'Usuários',
	'user_config.get_user_error': 'Erro ao carregar usuários',
	'user_register.table_title': 'Cadastro de Usuários',

	'add_user.new_user': 'Novo Usuário',
	'photo_picker.helper_message': 'Clique na imagem para alterá-la.',

	//nelp page
	'help.title': 'Ajuda',
	'help.manual': 'Manuais de Ajuda',
	'help.how_to_groups_products': 'Como cadastrar categorias e produtos',
	'help.how_to_print': 'Como configurar o tipo de impressão',
	'help.frequent_doubts': 'Dúvidas mais frequentes',
	'help.video_tutorial': 'Tutoriais em Vídeo',

	//system config page
	'systemConfig.title': 'Configuração Sistema',
	'enableConectionBase': 'Habilita conectar a base?',
	'enableAmbulant': 'Habilita ambulante?',
	'enableOnlineButton': 'Hablita botão online?',
	'enableRefound': 'Habilita Estorno?',
	'acessLevelToRefound': 'Permissão para utilizar estorno',
	'MoneySymbol': 'Símbolo da moeda',
	'enableMoneyPayamentType': 'Habilita venda em dinheiro?',
	'showScreenChange': 'Mostrar tela de troco?',
	'requiredIdentification': 'Solicita identificação?',
	'identification': 'Identificação',
	'requiredCpfCnpj': 'Solicita CPF/CPNJ?',
	'validateCpfCnpj': 'Valida CPF/CNPJ?',
	'enableDiscount': 'Habilita desconto',
	'acessLevelToDiscount': 'Permissão para utilizar desconto',
	'maxDiscount': 'Desconto Máximo',
	'optionsScreen': 'Tela de opções',
	'anotherScrens': 'Outras telas',

	//system config page
	'nfceTef.title': 'Configuração NFCe-Tef',
	'tefparam': 'Parâmetros TEF',
	'logicNumber': 'Número Lógico',
	'companyCodFromAdquirente': 'Cód da Empresa na Adquirente',
	'taxParam': 'Parâmetros Ficais',
	'activeTaxParam': 'Ativa campos Fiscais?',
	'companyIsSimplesNacional': 'Empresa optante pelo Simples Nacional?',
	'emmitNfce': 'Ativa emissão de NFCe?',
	'ambNfcetype': 'Tipo de Ambiente NFCe',
	'emmitType': 'Tipo de Emissao',
	'valueRequiredCPF': 'Valor para CPF Obrigatório',
	'hmlParams': 'Parâmetros Homologação',
	'prdParams': 'Parâmetros Produção',
	'IDCSC': 'ID CSC',
	'CSC': 'CSC',

	//terminal config page
	'terminal.title': 'Configuração Terminais',
	'baseConf': 'Configuração base',
	'apiCredentials': 'Credenciais API',
	'ingress': 'Ingressos',
	'cashless': 'Cashless (MOVE - IWL)',
	'savePos': 'Lista de Terminais',
	'passwordRatChetsintegration': 'Senha Integração Catracas (Ingresso - Android)',
	'passwordCashless': 'Senha Cashless',
	'generateNewPassword': 'Gerar nova senha',
	'generateNewPasswordCashless': 'Gerar nova senha CASHLESS',

	//user config page
	'user.title': 'Configuração Usuários',
	'users.list': 'Usuários',


	//dados fiscais
	'dados.fiscal.title': 'Dados fiscais',
	'dados.fiscal.NCM': 'NCM',
	'dados.fiscal.ICMS': 'ICMS',
	'dados.fiscal.CST_CSOSN': 'CST/CSOSN',
	'dados.fiscal.CST': 'CST',
	'dados.fiscal.Aliq': 'Alíquota',
	'dados.fiscal.baseRedICMS': 'Base de Redução ICMS',
	'dados.fiscal.Pis': 'PIS',
	'dados.fiscal.Cofins': 'COFINS',
	'dados.fiscal.General': 'Geral',
	'dados.fiscal.others': '_',
	'dados.fiscal.Cfop': 'CFOP',
	'dados.fiscal.Cest': 'CEST',
	'dados.fiscal.Description': 'Descrição',
}