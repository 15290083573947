import React, { Component } from 'react'
import { CurrencyInput } from '../../../shared_components'

class ProductPriceInput extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: '',
        }
    }

    componentDidMount() {
        this.setValue(this.props.value)
        document.getElementById(this.props.id + 'product_price').focus()
    }

    setValue = (val) => {
        this.setState(({ value: val }))
    }

    render() {
        const { onUpdate,  id } = this.props;
        const { value } = this.state;
        return (
            <CurrencyInput id={id + 'product_price'}
                placeholder={'00,00'}
                locale='pt-br'
                value={value}
                onChange={this.setValue}
                onBlur={() => onUpdate(parseFloat(String(value).replace(/,/g, '.')))}
                countryCode={'BRL'} />
        )
    }
}

export default ProductPriceInput