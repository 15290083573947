import React, { useEffect } from 'react'
import { Container, Background, PopUp } from './styles'
import Button from '../button'

function AlertAction({setOpen, buttonText, message, onClick}){

    useEffect(() => {
        document.getElementById('alert_action_button').focus()

        const timeoutOpen = setTimeout(() => {
            setOpen(false)
        }, 10000)

        return () => clearTimeout(timeoutOpen)
    }, [setOpen])

    return (
        <Container>
            <Background onClick={() => setOpen(false)}/>
            <PopUp>
                
                <div className='bar'>
                    <div className='in'></div>
                </div>
                <div className='content'>
                    <p>{message}</p>
                    <Button id='alert_action_button' tabIndex={"0"} onClick={() => {onClick(); setOpen(false)}} text={buttonText}/>
                </div>

            </PopUp>
        </Container>
    )
}

export default AlertAction
