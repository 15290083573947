import {GET_THEME} from '../actions/theme/ac'

const initialState = {
    "logo": "",
    "page_templates": {
        login: 'main',
        recoverPassword: 'main',
        recoverPasswordEmail: 'main',
        groupRegister: 'main',
        productRegister: 'main',
        help: 'main',
        monitor: 'main',
        qrConfig: 'main',
        reportClosingRegister: 'main',
        reportDetailedSales: 'main',
        reportSalesPerDay: 'main',
        reportSalesPerGroup: 'main',
        userConfig: 'main',
    }
}

export default function theme(state=initialState, action) {
    switch(action.type) {
        case GET_THEME:
            let res = {
                ...state,
                ...action.theme,
                logo: action.theme.logo, 
                page_templates: {...state.page_templates, ...action.theme.page_templates}
            }
            return res
        default:
            return state
    }
}