export function isFloat(val) {
	var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
	if (!floatRegex.test(val))
		return false;

	val = parseFloat(val);
	if (isNaN(val))
		return false;
	return true;
}

export function isInt(val) {
	var intRegex = /^-?\d+$/;
	if (!intRegex.test(val))
		return false;

	var intVal = parseInt(val, 10);
	return parseFloat(val) === intVal && !isNaN(intVal);
}

export function hasDecimalPlaces(val) {
	let valor = val.toString().slice()
	if (valor.includes('.') && valor.split('.').length > 1) return true
	if (valor.includes(',') && valor.split(',').length > 1) return true
	return false
}