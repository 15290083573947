import React, { useState, useEffect } from 'react'
import { Container } from './styles'
import { RedeLogin, RedeMarca } from '../../../assets/images'
import { TextInput, Button, Toast } from '../../../shared_components'
import loginApi from '../../../services/login'
import { handleGetTokens, handleAuthedStore } from '../../../config/store/actions/auth'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import t from '../../../config/translate'

function RecoverPassword({ dispatch, history, location, whitelabel_name, match }) {

    const [loginError, setLoginError] = useState({
        active: false,
        message: t('recover_password.error'),
    })

    const [isLoading, setIsLoading] = useState(false)

    const submitLogin = (e) => {
        e.persist();
        e.preventDefault();
        const form = e.target
        let login_password_1 = form['login_password_1'].value
        let login_password_2 = form['login_password_2'].value
        if ((login_password_1 === login_password_2) && (login_password_1.length >= 8)) {
            let requestedUser = match.params.token
        
            loginApi.updatePass(login_password_1, requestedUser, window.location.hostname)
                .then(res => {
                    if (res.status === 200) {
                        setLoginError({
                            active: true,
                            message: t('recover_password.success')
                        })

                        setTimeout(() => {
                            history.push('/login')
                        }, 3000)

                    }
                })
                .catch((e) => { 
                    if (e.response.status === 404) {
                        setLoginError({
                            active: true,
                            message: t('recover_password.not_found')
                        })
                    } else if (e.response.status === 400) {
                        setLoginError({
                            active: true,
                            message: t('recover_password.error')
                        })
                    } 
                    setIsLoading(false) 
                })
        } else {
            setLoginError({
                active: true,
                message: t('recover_password.error')
            });
            setIsLoading(false)
        }

    }

    const redirectBack = () => {
        if (location.state && location.state.referrer) {
            history.push(location.state.referrer)
        } else {
            history.push('/')
        }

    }


    return (
        <Container>
            <Helmet>
                <title>{t('recover_password.title')} | {whitelabel_name}</title>
            </Helmet>
            <div className='top_nav'>
                <img src={RedeMarca} alt='Rede Gestão Fácil - Logo' className='logo' />
            </div>
            <div className='content_container'>

                <div className='form_container'>

                    <h1>{t('recover_password.title')}</h1>
                    <div className='colored_div' />
                    <form onSubmit={submitLogin} className='input_container'>
                        <TextInput maxLength={'15'} type={'password'} id='login_password_1' placeholder={t('recover_password.new_password')} />
                        <TextInput maxLength={'15'} type={'password'} id='login_password_2' placeholder={t('recover_password.repeat_password')} />
                        <Button type={'submit'} text={t('save')} />
                        {loginError.active && <p className='error_message'>{loginError.message}</p>}
                    </form>

                </div>
                <div className='image_container'>
                    <img src={RedeLogin} alt='POS Machine' />
                </div>
            </div>


        </Container>
    )
}

function mapStateToProps({ auth, theme }) {
    return {
        tokens: auth.tokens || {},
        logo: theme.logo,
        whitelabel_name: theme.whitelabel_name || 'Rede',
    }
}


export default withRouter(connect(mapStateToProps)(RecoverPassword))