/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'

// const instance = axios.create()
// instance.interceptors.response.use((response) => response, (error) => Promise.reject(error.response))

export default {
	async register(data) {
		const res = axios.post('https://app.poscontrole.com.br/app/prc.php', data, {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			}
		})
		return res
	}
}