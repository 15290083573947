import React, { useState, useEffect } from 'react'
import { Container } from './styles'
import { RedeLogin, RedeMarca } from '../../../assets/images'
import { TextInput, Button, Toast } from '../../../shared_components'
import loginApi from '../../../services/login'
import { handleGetTokens, handleAuthedStore } from '../../../config/store/actions/auth'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import t from '../../../config/translate'

function RecoverPasswordEmail({ dispatch, history, location, whitelabel_name }) {

    const [loginError, setLoginError] = useState({
        active: false,
        message: t('recover_password_email.error'),
    })
    const [isLoading, setIsLoading] = useState(false)

    const submitLogin = (e) => {
        e.persist();
        e.preventDefault();
        const form = e.target
        let email = form['login_user'].value
        loginApi.recoverPass(email, window.location.hostname)
            .then(res => {
                if (res.status === 200) {
                    setLoginError({
                        active: true,
                        message: t('recover_password_email.success')
                    })
                    
                } 
            })
            .catch((e) => { 
                if (e.response.status === 404) {
                    setLoginError({
                        active: true,
                        message: t('recover_password_email.not_found')
                    })
                } else if (e.response.status === 400) {
                    setLoginError({
                        active: true,
                        message: t('recover_password_email.error')
                    })
                } 
                setIsLoading(false)
            
            })
    }

    const redirectBack = () => {
        if (location.state && location.state.referrer) {
            history.push(location.state.referrer)
        } else {
            history.push('/')
        }

    }


    return (
        <Container>
            <Helmet>
                <title>{t('recover_password_email.helmet_title')} | {whitelabel_name}</title>
            </Helmet>
            <div className='top_nav'>
                <img src={RedeMarca} alt='Rede Gestão Fácil - Logo' className='logo' />
            </div>
            <div className='content_container'>

                <div className='form_container'>

                    <h1>{t('recover_password_email.title')}</h1>
                    <div className='colored_div' />
                    <form onSubmit={submitLogin} className='input_container'>
                        <TextInput id='login_user' placeholder={t('email')} />
                        <Button type={'submit'} text={t('send')} />
                        {loginError.active && <p className='error_message'>{loginError.message}</p>}

                    </form>
                    <p onClick={() => history.push('/login')} className='hiperlink_button'>{t('recover_password_email.back_to_login')}</p>
                </div>
                <div className='image_container'>
                    <img src={RedeLogin} alt='POS Machine' />
                </div>
            </div>


        </Container>
    )
}

function mapStateToProps({ auth, theme }) {
    return {
        tokens: auth.tokens || {},
        logo: theme.logo,
        whitelabel_name: theme.whitelabel_name || 'Rede',
    }
}


export default withRouter(connect(mapStateToProps)(RecoverPasswordEmail))