import React from 'react'
import ReactLoading from 'react-loading';

import {LoadingBox} from './styles'

const Loading = ({ type = "spin", color = "#000000" }) => (
	<LoadingBox>
		<ReactLoading type={type} color={color} height={'10%'} width={'10%'} />
	</LoadingBox>
);

export default Loading;
