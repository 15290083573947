import React, { useState } from 'react'
import { Container } from './styles'
import { Nav, TopNav } from '../../components'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import t from '../../../config/translate'
import { withRouter } from 'react-router-dom'
import { WhiteButton } from '../../../shared_components'
import Vimeo from '@u-wave/react-vimeo';


function Help({ whitelabel_name }) {

    return (
        <Container>
            <Helmet>
                <title>{t('help.title')} | {whitelabel_name}</title>
            </Helmet>

            <Nav />

            <div className='content_container'>
                <TopNav pageTitle={t('help.title')} />
                <div className='form_container'>
                    <div className='column'>
                        <p className='section_title'>{t('help.manual')}</p>
                        <div className='button_row'>
                            <p>{t('help.how_to_groups_products')}</p>
                            <div>
                                {whitelabel_name.toLowerCase() === 'rede' && <a href='/REDE_ComoCadastrarProdCat_GPOS_Atualizado2.pdf' download="REDE_ComoCadastrarProdCat_GPOS.pdf"><WhiteButton icon={'print-outline'} /></a>}
                            </div>
                        </div>
                        <div className='button_row'>
                            <p>{t('help.how_to_print')}</p>
                            <div>
                                {whitelabel_name.toLowerCase() === 'rede' && <a href='/REDE_ComoConfigurarTipodeImpressao_GPOS_Atualizado2.pdf' download="REDE_ComoConfigurarTipodeImpressao_GPOS.pdf"><WhiteButton icon={'print-outline'} /></a>}
                            </div>
                        </div>
                        <div className='button_row'>
                            <p>{t('help.frequent_doubts')}</p>
                            <div>
                                {whitelabel_name.toLowerCase() === 'rede' && <a href='/REDE_DuvidasMaisFrequentes_GPOS_Atualizado2.pdf' download="REDE_DuvidasMaisFrequentes_GPOS.pdf"><WhiteButton icon={'print-outline'} /></a>}
                            </div>
                        </div>
                    </div>
                    {whitelabel_name.toLowerCase() === 'rede' && <div className='column'>
                        <p className='section_title'>{t('help.video_tutorial')}</p>
                        <Vimeo video={"https://vimeo.com/551512973/b978ae3c98"}/>
                        <Vimeo video={"https://vimeo.com/559727013/79b591d5e3"}/>
                        <Vimeo video={"https://vimeo.com/565654314/d809d591ef"}/>
                    </div> }

                </div>
            </div>

        </Container>
    )
}

function mapStateToProps({ theme }) {
    return {
        whitelabel_name: theme.whitelabel_name || 'POS CONTROLE',
    }
}

export default connect(mapStateToProps)(Help)