/* eslint-disable import/no-anonymous-default-export */
import GroupRegister from './GroupRegister'
import Login from './Login'
import ProductRegister from './ProductRegister'
import PrintConfig from './PrintConfig'
import QrConfig from './QrConfig'
import Help from './Help'
import UserConfig from './UserConfig'
import ReportSalesPerGroup from './ReportSalesPerGroup'
import ReportSalesPerDay from './ReportSalesPerDay'
import ReportClosingRegister from './ReportClosingRegister'
import ReportBillingProductsByPOSBilled from './ReportBillingProductsByPOSBilled'
import ReportCashlessExtratoOnline from './ReportCashlessExtratoOnline'
import ReportBillingProductsByPOSCourtesy from './ReportBillingProductsByPOSCourtesy'
import ReportCashlessListagemCredito from './ReportCashlessListagemCredito'
import ReportBillingDowloaderProductsPerUser from './ReportBillingDowloaderProductsPerUser'
import ReportBillingPOSRanking from './ReportBillingPOSRanking'
import ReportDetailedSales from './ReportDetailedSales'
import Monitor from './Monitor'
import TerminalConfig from './TerminalConfig/index'
import TerminalConfigJustTable from './TerminalConfig/indexJustTable'
import RecoverPasswordEmail from './RecoverPasswordEmail'
import RecoverPassword from './RecoverPassword'
import { Report, Insert } from './Estoque'
import SystemConfig from './SystemConfig'
import NfceTefconfig from './NfceTefConfig'
import RedirectLogin from './RedirectLogin'
import FiscalConfig from './FiscalConfig'
import reportCanceledSales from './reportCanceledSales'
import ReportBarcodeSales from './reportBarCodeSales'
import ReportListSalesIdentificador from './ReportListSalesIdentificador'
import ReportPayamentTypeSale from './ReportPayamentTypeSale'
import ReportSearchByIdSales from './reportSearchByIdSales'
import WhiteLabelForm from './WhiteLabelForm'

export default {
	GroupRegister,
	Login,
	ProductRegister,
	PrintConfig,
	QrConfig,
	Help,
	UserConfig,
	ReportSalesPerGroup,
	ReportSalesPerDay,
	ReportClosingRegister,
	ReportBillingProductsByPOSBilled,
	ReportCashlessExtratoOnline,
	ReportBillingProductsByPOSCourtesy,
	ReportCashlessListagemCredito,
	ReportBillingDowloaderProductsPerUser,
	ReportBillingPOSRanking,
	ReportDetailedSales,
	Monitor,
	TerminalConfig,
	RecoverPasswordEmail,
	RecoverPassword,
	Report,
	Insert,
	SystemConfig,
	NfceTefconfig,
	RedirectLogin,
	TerminalConfigJustTable,
	FiscalConfig,
	reportCanceledSales,
	ReportBarcodeSales,
	ReportListSalesIdentificador,
	ReportPayamentTypeSale,
	ReportSearchByIdSales,
	WhiteLabelForm
}