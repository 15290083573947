import React from 'react'
import { Helmet } from 'react-helmet'

function HelmetTemplate() {

    return (
        <Helmet titleTemplate="%s" >
            <meta name="description" content="O sistema
                                                de PDV ideal
                                                para sua maquininha" />
            <html lang="pt-BR" />
        </Helmet>
    )
}

export default HelmetTemplate