import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./styles";
import { Nav, TopNav } from "../../components";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import t from "../../../config/translate";
import { withRouter } from "react-router-dom";
import ReportTable from "./report_table";
import reportAPI from "../../../services/reports";
import moment from "moment";
import { formatClosingRegister } from "../../../utils/formatters";

function ReportBillingProductsByPOSBilled({ companyID, whitelabel_name }) {
  const [data, setData] = useState([]);
  const [initialDate, setInitialDate] = useState(moment());
  const [finalDate, setFinalDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  
  const fetchDataBillingProductByPOSBIlled = useCallback(async () => {
    setIsLoading(true)
    const initialDateFormated = moment(initialDate).format("YYYY-MM-DD");
    const finalDateFormated = moment(finalDate).format("YYYY-MM-DD");

    const res = await reportAPI.getFaturamentoProdutosPorPosFaturado(
      companyID,
      initialDateFormated,
      finalDateFormated
    );

    setIsLoading(false)

    return res.data;
  }, [companyID, finalDate, initialDate]);

  useEffect(() => {
    let isMounted = true;

    async function fn() {
      const data = await fetchDataBillingProductByPOSBIlled();
      
      if (isMounted) {
        const dataFormated = formatClosingRegister(data);
        setData(dataFormated);
      }
    }
    fn();

    return () => (isMounted = false);
  }, [companyID, fetchDataBillingProductByPOSBIlled, finalDate, initialDate]);

  const updateRange = async () => {
    const data = await fetchDataBillingProductByPOSBIlled();
    const dataFormated = formatClosingRegister(data);
    setData(dataFormated);
  };

  return (
    <Container>
      <Helmet>
        <title>
          {t("report_title")} | {whitelabel_name}
        </title>
      </Helmet>

      <Nav />

      <div className="content_container">
        <TopNav pageTitle={t("report_title")} />
        <ReportTable
          data={data}
          // setData={setData}
          // companyID={companyID}
          isLoading={isLoading}
          updateRange={updateRange}
          initialDate={initialDate}
          setInitialDate={setInitialDate}
          finalDate={finalDate}
          setFinalDate={setFinalDate}
        />
      </div>
    </Container>
  );
}

function mapStateToProps({ auth, theme }) {
  return {
    companyID: auth.authedStore ? auth.authedStore.CompanyID : "",
    companyGroupID: auth.authedStore ? auth.authedStore.CompanyGroupID : "",
    whitelabel_name: theme.whitelabel_name || "POS CONTROLE",
  };
}

export default withRouter(connect(mapStateToProps)(ReportBillingProductsByPOSBilled));
