import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    background: var(--color-background);
    font-family: var(--font-family-primary);
  
    
    .content_container{
        height: 100vh;
        overflow: auto;
        flex: 1;
    }    
    .form_container {
        background: var(--color-light);
        padding: 20px;
        margin: 0 30px 30px 30px;
        border-radius: var(--border-radius-medium);
        box-shadow: var(--box-shadow-card);
        overflow: visible;
        
    }

    .print_ficha {
        max-height: 300px;
        margin: 20px 0;
    }

    .section_title {
        font-size: 18px;
        padding: 10px 0px 10px 10px;
        margin: 40px 0 20px;
        font-weight: 600;
        border-bottom: 1px solid var(--color-primary);

        &:first-child {
            margin-top: 0px;
        }
    }
   
    .input_title {
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        color: var(--color-dark);
        margin: 10px;
    }

    .input_row {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        .row_item {
            @media screen and (min-width: 601px) {min-width: 280px; width: 45%;};
            @media screen and (max-width: 600px) {min-width: 250px};
        }
    }

    .column {
        flex: 1;
        margin-right: 20px;

        &:last-child {
            margin-right: 0px;
        }
    }
`