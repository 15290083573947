/* eslint-disable import/no-anonymous-default-export */
import base from './base'

export default {
	getProducts(companyID, page = 1, amount = 10, search = '', orderBy = '', orderType = '', stats) {
		if (stats) {
			return base.get(`/api-con/company/${companyID}/products?page=${page}&take=${amount}&orderType=${orderType}&orderBy=${orderBy}&search=${search}&status=${stats}`)
		}
		return base.get(`/api-con/company/${companyID}/products?page=${page}&take=${amount}&orderType=${orderType}&orderBy=${orderBy}&search=${search}`)
	},
	editProduct(companyID, ProductID, data) {
		// console.log(data)
		return base.put(`/api-con/company/${companyID}/products`, {
			ProductID: ProductID,
			...data,
		})
	},
	addProduct(companyID, data) {
		return base.post(`/api-con/company/${companyID}/products`, data)
	},
	searchProduct(companyID, data) {
		return base.get(`/api-con/company/${companyID}/searchProducts?Search=${data}`)
	}
}
