import React, { useEffect } from 'react'
import { GraphContainer } from '../../components'
import t from '../../../config/translate'
import { Other } from '../../../assets/pos_icons'

function MapsData(props) {

	const { title, setRange, ranges, selectedRange, data } = props

	useEffect(() => {
		const timeoutGoogleMap = setTimeout(() => {
			let google = window.google
			if (google) {
				let map = new google.maps.Map(document.getElementById("monitor_maps_data"), {
					center: new google.maps.LatLng(-14.2401, -53.1805),
					zoom: 3.5,
				})
				// const iconBase = ''
				const icons = {

					info: {
						icon: Other,
					},
				}

				let features = []

				data.map((d) => {
					features = features.concat({
						position: new google.maps.LatLng(d.latitude, d.longitude),
						type: 'info'
					})
					return features
				})

				// Create markers.
				for (let i = 0;i < features.length;i++) {
					new google.maps.Marker({
						position: features[i].position,
						icon: icons[features[i].type].icon,
						map: map,
					})
				}
			}
		}, 500)



		return () => clearTimeout(timeoutGoogleMap)
	}, [data])

	return (
		<GraphContainer
			title={title}
			setRange={setRange}
			ranges={ranges}
			selectedRange={selectedRange}
			//subTitle={t('maps_graph.revenue')}
			Children={
				(
					<div className='bar_graph_container'>

						{data ?
							<div style={{ minHeight: 400, maxHeight: 800 }} id='monitor_maps_data'></div>
							: <p className='bar_graph_container'>{t('monitor_graph.no_sales')}</p>
						}


					</div>
				)
			}
		/>
	)
}

export default MapsData