import React from 'react'
import { CONTAINER } from './styles'
import InputMask from 'react-input-mask'

function MASK_INPUT({ icon, title, styles, placeholder, type, id, onChange, value, disabled, mask, maskChar, className }) {

    return (
        <CONTAINER>
            {title && <h5>{title}</h5>}
            <div className='mask_input_container' style={{ ...styles }}>
                {icon && <ion-icon name={icon} />}
                <InputMask
                    id={id}
                    disabled={disabled}
                    mask={mask}
                    value={value}
                    className={className}
                    maskChar={maskChar ? maskChar : ' '}
                    onChange={(e) => onChange ? onChange(e.target.value) : null}
                    placeholder={placeholder}
                    type={type} />
            </div>
        </CONTAINER>
    )
}

export default MASK_INPUT