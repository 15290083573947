import { GET_AUTHED_STORE, GET_COMPANY, GET_TOKENS, LOGOUT, GET_USER_PERMISSIONS, ATTEMPS_REFRESH_TOKEN } from '../actions/auth/ac'

export default function authedStore(state = {}, action) {
	switch (action.type) {
		case GET_AUTHED_STORE:
			return { ...state, authedStore: { ...action.authedStore } }
		case GET_COMPANY:
			return { ...state, company: { ...action.company } }
		case GET_TOKENS:
			return { ...state, tokens: { ...action.tokens } }
		case GET_USER_PERMISSIONS:
			return { ...state, permissions: { ...action.permissions } }
		case LOGOUT:
			return {}
		default:
			return state
	}
}