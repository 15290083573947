import React, { useEffect, useState } from 'react'
import Theme from './assets/theme'
import Routes from './config/routes'
import { HelmetTemplate } from './config/helmet'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { googleMaps } from './services/maps'
import configAPI from './services/config'
import { handleGetTheme } from './config/store/actions/theme'
import { connect } from 'react-redux'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { Fragment } from 'react'
import WhiteLabelForm from './templates/main/WhiteLabelForm'


function App({ dispatch, theme, auth, history, page_templates }) {

	const [notFound, setNotFound] = useState(false)

	useEffect(() => {
		googleMaps()
		configAPI.getTheme(window.location.hostname).then((res) => {
			if (res) {
				if (res.data.colorPalette) {
					dispatch(handleGetTheme(res.data))
				} else {
					setNotFound(true)
				}
			}
		}).catch(() => setNotFound(true))
	}, [dispatch])

	return (
		<div className="App">
			<ReactNotification />
			<HelmetTemplate />
			<Switch>
			<Route path="/4cad9e1c-a7a1-48d3-9efa-5dba69733aeb/register" component={WhiteLabelForm} />
			</Switch>
			{theme &&
				<Fragment>
					<Theme theme={theme.colorPalette} />
					<Routes page_templates={page_templates} />
				</Fragment>
			}
			{!theme && notFound &&
				<div>
					404
				</div>
			}

			{!theme && !notFound &&
				<div className='main_loader' />
			}
		</div>
	)
}

function mapStateToProps({ theme, auth }) {
	return {
		theme: theme.colorPalette ? theme : undefined,
		auth,
		page_templates: theme.page_templates,
	}
}


export default withRouter(connect(mapStateToProps)(App))
