import styled from 'styled-components'

export const DATE_CONTAINER = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    cursor: pointer;
    font-family: var(--font-family-secondary);

    h5 {
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        color: var(--color-dark);
        margin: 10px;
    }


    .date-range-custom-input {
        cursor: pointer;
        user-select: none;
        border: 1.5px solid lightgray;
        border-radius: var(--border-radius-small);
        padding: 8px 12px;
        background: white;
        display: flex;
        align-items: center;


        &:active {
            opacity: 0.5;
            transition: 0.3s;
        }

        img {
            max-height: 20px;
            margin-right: 6px;
        }

        input {
            background: none;
            outline: none;
            cursor: pointer;
            width: 0px;
            height: 0px;
            vibility: hidden;
            line-height: 20px;

            border: none;
            font-size: 16px;
        }
        input::placeholder {
            color: var(--color-darkgray);
        }
    }

    .date-picker-value {
        color: var(--color-text);
        font-family: var(--font-family-main);
        font-weight: var(--font-weight-small);
        font-size: 16px;
    }
    .date-picker-placeholder {
        color: var(--color-darkgray);
        font-family: var(--font-family-main);
        font-weight: var(--font-weight-small);
        font-size: 16px;
    }

    .MuiPickersStaticWrapper-staticWrapperRoot {
        z-index: 4000;
    }
    .MuiPickersToolbar-toolbar {
        background: var(--color-primary);
    }

    .MuiPickersDay-daySelected {
        background: var(--color-secondary);
    }

    .MuiPickersDay-daySelected:hover {
        background: var(--color-secondary);
    }
    .MuiPickersDay-current  {
        color:  var(--color-light);
    }
    .MuiTypography-colorPrimary {
        color:  var(--color-dark);
    }

`

export const Background = styled.div`
    max-width: 49.6vw;
    min-height: 100vh;
    background: lightgray;
    opacity: 0.5;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    min-width: 100vw;
    height: 100%;
    z-index: 998;
`

export const DIALOG_CONTAINER = styled.div`
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 4000;
    transform: translate(-50%, -50%);

    .picker_btn_container {
        z-index: 2000;
        position: relative;
    }

    .close_button {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 36px;
        height: 36px;
        padding: 0;
        z-index: 2000;
        color: var(--color-dark);
        border-radius: 16px;

        ion-icon {
          font-size: 24px;
        }
    }

    .react-calendar {
        border-radius: 8px;
        overflow: hidden;
        border: none;
        z-index: 999;
    }

    .react-calendar__tile:disabled {
        background-color: var(--color-lightgray);
    }
    .react-calendar__tile--now{
        background-color: var(--color-secondary);
    }

    .react-calendar__month-view__days__day--weekend {
        color: black;
    }
    .react-calendar__tile--hover{
        color: white;
        background-color: var(--color-gray);
    }
    .react-calendar__tile--active .react-calendar__tile--hasActive {
        color: white;
        background-color: var(--color-primary);
    }
`