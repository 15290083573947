import React, { useState, useEffect, useCallback } from 'react'
import { INPUT_CONTAINER, CONTAINER } from './styles'

export default function CURRENCY_INPUT(props) {
	const { id, font, style, inputStyle, title,
		readOnly = false, onChange, value, placeholder,
		className, locale, countryCode, onBlur = () => { } } = props
	const [maskedInput, setMaskedInput] = useState('')

	let idAux = id.length > 0 ? id : 'currencyInput'


	const handleMaskedInput = useCallback((query) => {
		let aux, letters
		if (query.toString().length > 0) {
			let newQuery = query.toString().replace(/,/g, '.')
			aux = Intl.NumberFormat(locale, { style: 'currency', currency: countryCode }).format(parseFloat(newQuery).toFixed(2))
			letters = []
			for (let i = 0;i < aux.length;i++) {
				if (!is_numeric(aux[i])) letters[i] = aux[i]
				else break
			}
			letters = letters.join("")
			if (letters.substring(letters.length - 3) === 'NaN') setMaskedInput(letters.substring(0, letters.length - 3))
			else setMaskedInput(letters)
		}

		let auxQuery = query.toString()
		if (!is_numeric(auxQuery[auxQuery.length - 1])) {
			auxQuery = auxQuery.substring(0, auxQuery.length - 1)
			onChange(auxQuery)
		} else onChange(query)

	}, [countryCode, locale, onChange])

	const is_numeric = (str) => {
		return /^[0-9.,\b]+$/.test(str)
	}

	// useEffect(() => {
	// 	if (value) { handleMaskedInput(value) }
	// 	const currency = document.getElementById(idAux)
	// 	if (countryCode === 'BRL' && value) {
	// 		let auxQuery = value.toString().replace(".", ",")
	// 		onChange(auxQuery)
	// 		currency.value = auxQuery
	// 		currency.onkeyup = test(currency)
	// 	} else {
	// 		currency.value = value
	// 	}

	// }, [countryCode, idAux, onChange, value])


	const test = (input) => {
		let value = input.value
		let values = value.split("")
		var update = ""
		var transition = ""
		var expression = /(^\d+$)|(^\d+\.\d+$)|[,]/
		var finalExpression = /^([0-9][0-9]*[,]?\d{0,3})$/

		for (let id in values) {
			if (expression.test(values[id]) === true && values[id] !== '') {
				transition += '' + values[id].replace('.', ',')
				if (finalExpression.test(transition) === true) {
					update += '' + values[id].replace('.', ',')
				}
			}
		}

		input.value = update
	}

	return (
		<CONTAINER>
			{title && <h5>{title}</h5>}
			<INPUT_CONTAINER style={{ ...style }}>
				<h5>{maskedInput}</h5>
				<input readOnly={readOnly} onChange={(e) => handleMaskedInput(e.target.value)}
					disabled={readOnly}
					value={value || ''}
					id={idAux}
					onBlurCapture={() => onBlur()}
					className={className}
					type={'text'}
					placeholder={placeholder}
					style={{ ...inputStyle, font: font }} />

			</INPUT_CONTAINER>
		</CONTAINER>
	)

}
