export default function generatePagOptions(max) {
	let i = 5
	let options = []
	if (max > 5) options.push(5)
	if (max > 10) options.push(10)

	while (i <= (max - 10)) {
		i += 10
		options.push(i)
	}
	const hasQtdInOptions = options.find(q => q === max)
	if (!hasQtdInOptions) options.push(max)
	return options
}