import React, { useEffect, useState } from "react";
import t from "../../../../config/translate";
import monitorAPI from "../../../../services/monitor";
import MapsData from "./../MapsData";

export function PosImLocal({ companyID }) {
  const [mapsData, setMapsData] = useState([]);

  useEffect(() => {
    let componentMounted = true;

    monitorAPI
      .getMonitorData(companyID, "possimlocal")
      .then((res) => res.data)
      .then((data) => componentMounted && setMapsData(data))
      .catch((err) => console.error("[PosImLocal]", err.message));

    return () => {
      componentMounted = false;
    };
  }, [companyID]);

  return <MapsData data={mapsData} title={t("monitor.graph_maps")} />;
}
