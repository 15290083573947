import styled from 'styled-components'

export const Container = styled.div`
    .search_content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        
        .input_search {
            display: flex;
            font-weight: var(--font-weight-medium);
            font-size: 16px;
            color: var(--color-dark);
            margin: 0px 5px -10px 0px;
            width: 20%;
            min-width: 60px;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            width: 60px;
            min-width: 60px;
            outline: none;
            border: 0px;
            background: var(--color-primary);
            color: var(--color-light);
            text-transform: uppercase;
            font-weight: 600;
            border-radius: var(--border-radius-small);
            
            &:hover {
                cursor: pointer;
                opacity: 0.8;
                transition: 0.3s;
            }
        
            &:active {
                transition: 0.3s;
                opacity: 0.6;
            }
        }

        @media screen and (max-width: 900px) {
            flex-direction: column;
            align-items: flex-end;
            flex-wrap: wrap;
            button {
                max-width: 140px;
            }
            label {
                margin: 20px 0 10px;
            }
        }
    }
`