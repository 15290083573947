export const GET_COMPANY = 'posControle/auth/GET_COMPANY'
export const GET_TOKENS = 'posControle/auth/GET_TOKENS'
export const GET_AUTHED_STORE = 'posControle/auth/GET_AUTHED_STORE'
export const LOGOUT = 'posControle/auth/LOGOUT'
export const GET_USER_PERMISSIONS = 'posControle/auth/GET_USER_PERMISSIONS'

export function getCompany(company) {
	return {
		type: GET_COMPANY,
		company
	}
}

export function getTokens(tokens) {
	return {
		type: GET_TOKENS,
		tokens,
	}
};

export function getAuthedStore(authedStore) {
	return {
		type: GET_AUTHED_STORE,
		authedStore
	}
}

export function getUserPermissions(permissions) {
	return {
		type: GET_USER_PERMISSIONS,
		permissions
	}
}

export function logout() {
	return {
		type: LOGOUT
	}
}