import styled from 'styled-components'

export const CONTAINER = styled.div`
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-main);
    margin: 0 0 10px;

    h5 {
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        color: var(--color-dark);
        margin: 10px;
    }

    img {
        max-height: 13px;
        margin-left: 10px;
    }

    .dropdown_container{
        position: relative;
        max-height: 50px;
    }
   

    .dropdown_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        max-width: 100%;
        margin: 4px 0;
        border: 1.5px solid lightgray;
        border-radius: var(--border-radius-small);
        padding: 8px 12px;
    }
    

    .dropdown_item_container {
        position: absolute;
        margin-top: 16px;
        border: 1px solid lightgray;
        border-radius: var(--border-radius-medium);
        background: white;
        max-height: 150px;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        transition: 0.5s;
        overflow: hidden;
        overflow-y: auto;
    }

    .dropdown_item_container_closed{
        position: absolute;
        margin-top: 16px;
        visibility: hidden;
        border: 1px solid lightgray;
        border-radius: var(--border-radius-medium);
        background: white;
        max-height: 0px;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        transition: 0.3s;
        overflow: hidden;
    }

    input  {
        font-weight: var(--font-weight-small);
        font-size: 14px;
        color: var(--color-dark);
        width: calc(100% - 54px);
        outline: none;
        background: none;
        cursor: pointer;
        border: none;
    }

    input::placeholder {
        color: var(--color-darkgray);
    }

    .dropdown_item {
        min-height: 50px;
        max-width: 100%;
        display: flex;
        align-items: center;
        line-break: anywhere;
        padding: 0 18px;
        font-size: 18px;
        color: gray;
        cursor: pointer;

        &:hover {
            filter: brightness(10%);
            transition: 0.3s;
        }
    }
    


`