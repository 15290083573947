import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, TextInput, Button, WhiteButton, PhotoInput, CurrencyInput, DropdownInput } from '../../../shared_components'
import t from '../../../config/translate'
import dadosFiscais from '../../../services/dadosFiscais'


function EditProductDialog({ groups, product, editProduct, units, nfcType, value, onUpdate }) {
	const [preview, setPreview] = useState('')
	const [unit, setUnit] = useState([])
	const [SalePrice, setSalePrice] = useState(0)
	const [group, setGroup] = useState([])
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [barCode, setBarCode] = useState('')
	const [dados, setDados] = useState([])
	const [selecionado, setSelecionado] = useState({})

	const companyID = useSelector(state => state.auth.authedStore.CompanyID)
	const companyGroupID = useSelector(state => state.auth.authedStore.CompanyGroupID)

	const getDadosFiscais = () => {
		dadosFiscais.get(companyGroupID)
			.then(rst => {
				rst.data.map(ncm => ncm.data = `${ncm.NCM}-${ncm.description}`)
				setDados(rst.data)
				const s = rst.data.find(d => d.NCM === product.NFCeNCM)
				setSelecionado(s)
			})
	}

	useEffect(() => {
		getDadosFiscais()
		if (product) {
			setName(product.Name)
			setDescription(product.Description)
			setBarCode(product.BarCode)
			setPreview(product.ImageBase64)
			setGroup(groups.filter((g) => g.ProductGroupID === product.ProductGroupID)[0])
			setUnit(units.filter((u) => u.UnitTypeID === product.UnitTypeID)[0])
			setSalePrice(parseFloat(product.SalePrice).toFixed(2))
		}
	}, [groups, product, units])

	const formatAPINumber = (val) => {
		return parseFloat(String(val).replace(",", ".")).toFixed(2)
	}

	const submitProduct = (e) => {
		e.preventDefault()
		e.persist()
		const form = e.target

		let data = {
			"CompanyGroupID": companyGroupID,
			"ProductGroupID": group["ProductGroupID"],
			"UnitTypeID": unit["UnitTypeID"],
			"Name": form['product_name'].value,
			"Description": form['product_description'].value,
			"ImageBase64": preview,
			"BarCode": form['product_cod_bar'].value,
			"SalePrice": formatAPINumber(SalePrice),
			"dadosFiscais": selecionado
		}
		editProduct(product.ProductID, data, onUpdate)
	}

	const onChange = (e) => { setSelecionado(e) }
	return (
		<Dialog
			setOpen={() => onUpdate(value)}
			Children={(
				<div>
					<div className='dialog_title_container'>
						<WhiteButton className='close_button'
							type='button'
							onClick={() => onUpdate(value)}
							icon={'close-outline'} />
						<h3 className='dialog_title'>{t('product_edit.table_title')}</h3>
					</div>
					<form id={'edit_product_form'} onSubmit={(e) => submitProduct(e)}>
						<div className='dialog_content_container product_dialog_content'>

							<div className='dialog_input_container'>

								<div style={{ minWidth: 250 }} className='column'>
									<TextInput id={'product_name'}
										onChange={setName}
										value={name}
										regex={/^[0-9a-zA-Z+,-_% ]+$/}
										regexMessage={t('error.product_name_regex')}
										maxLength={"50"}
										title={t('product_register.product_name')} />

									<div>
										<p className='input_title'>{t('price')}</p>
										<CurrencyInput placeholder={'0,00'}
											locale='pt-br'
											style={{ boxShadow: 'none', border: '1.5px solid lightgray', marginBottom: 14 }}
											id={companyID + 'salePriceNewProduct'}
											value={SalePrice}
											onChange={setSalePrice}
											countryCode={'BRL'} />
									</div>

									<TextInput maxLength={"20"} onChange={setBarCode}
										value={barCode} id={'product_cod_bar'} title={t('barcode')} />

								</div>

								<div style={{ minWidth: 250 }} className='column'>
									<DropdownInput auxKey={'Description'} value={unit} onChange={setUnit} items={units} itemKey={'Name'} title={t('unit')} />
									<DropdownInput value={group} onChange={setGroup} items={groups} itemKey={'Name'} title={t('group')} />
									<TextInput maxLength={"50"} onChange={setDescription}
										value={description} id={'product_description'} title={t('description')} />
								</div>

								{nfcType === 'S' &&
									<div style={{ minWidth: 250 }} className='column'>
										<DropdownInput
											placeholder={t('search')}
											items={dados}
											value={selecionado}
											itemKey='data'
											onChange={onChange}
											title={t('dados.fiscal.NCM')}
										/>
									</div>
								}

								<div className='column'>
									<PhotoInput
										preview={preview}
										setPreview={setPreview}
										styles={{ margin: '40px 0', justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}
									/>
								</div>
							</div>

							<Button type={'submit'} styles={{ margin: '20px 0 0' }} text={t('save')} />
						</div>
					</form>
				</div>
			)} />
	)
}

export default EditProductDialog