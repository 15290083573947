/* eslint-disable import/no-anonymous-default-export */
import base from './base'

export default {
	updateCSC(companyID, data) {
		return base.put(`/api-con/company/${companyID}/company/updateCSC`, data)
	},
	updateHMCSC(companyID, data) {
		return base.put(`/api-con/company/${companyID}/company/updateHMCSC`, data)
	},
	get(companyID) {
		return base.get(`/api-con/company/${companyID}/company`)
	},
	updateClessUID(companyID, data) {
		return base.put(`/api-con/company/${companyID}/company/updateClessUID`, data)
	}
}