import React, { useState } from "react";
import { Toggle, Button, AlertAction } from "../../../shared_components";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import AddProductDialog from "./add_product_dialog";
import { TableEditImage, TableEditDropdown, Filter } from "../../components";
import ProductPriceInput from "./product_price_input";
import productsAPI from "../../../services/products";
import t from "../../../config/translate";
import { units, status } from "../../../utils/constants";
import EditProductDialog from "./edit_product_dialog";
import { Toast } from "../../../shared_components";
import filterFactory from "react-bootstrap-table2-filter";
import {
  formatCurrency,
  formatCurrencyWithoutSymbol,
  formatErrorProductImages,
  formatErrorProductTable,
} from "../../../utils/formatters";
import sheetsAPI from "../../../services/sheets";
import ErrorImportDialog from "./error_import_dialog";
import ErrorImportImagesDialog from "./error_import_images_dialog";
import dadosFiscais from "../../../services/dadosFiscais";
import { useSelector } from "react-redux";

const { SearchBar, ClearSearchButton } = Search;

function ProductTable(props) {
  const CompanyGroupID = useSelector(
    (state) => state.auth.authedStore.CompanyGroupID
  );

  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showAlertAction, setShowAlertAction] = useState(false);
  const [importError, setImportError] = useState({});
  const [importImagesError, setImportImagesError] = useState({});

  const {
    page,
    sizePerPage,
    totalSize,
    groups,
    companyGroupID,
    handleTableChange,
    data,
    setData,
    allData,
    companyID,
    companies,
    nfcType,
    atualizaTable,
  } = props;

  const [selectedStatus, setSelectedStatus] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);

  const getSheet = () => {
    sheetsAPI.getSheets(companyID).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  const statusHeader = (column, colIndex) => {
    return (
      <div className="header_filter">
        <p>{column.text}</p>
        <Filter
          open={openFilter}
          setOpen={setOpenFilter}
          Children={
            <div style={{ marginBottom: 0 }}>
              <div
                onClick={() => changeFilterStatus(0)}
                className="filter_radio_item dropdown_item"
              >
                <ion-icon
                  name={`radio-button-${
                    selectedStatus === 0 ? "on" : "off"
                  }-outline`}
                ></ion-icon>
                <p>{t("all")}</p>
              </div>
              <div
                onClick={() => changeFilterStatus(status.on)}
                className="filter_radio_item dropdown_item"
              >
                <ion-icon
                  name={`radio-button-${
                    selectedStatus === status.on ? "on" : "off"
                  }-outline`}
                ></ion-icon>
                <p>{t("active")}</p>
              </div>
              <div
                onClick={() => changeFilterStatus(status.off)}
                className="filter_radio_item dropdown_item"
              >
                <ion-icon
                  name={`radio-button-${
                    selectedStatus === status.off ? "on" : "off"
                  }-outline`}
                ></ion-icon>
                <p>{t("inactive")}</p>
              </div>
            </div>
          }
        />
      </div>
    );
  };

  const changeFilterStatus = (v) => {
    setSelectedStatus(v);
    setOpenFilter(false);

    handleTableChange("filter", {
      filters: {
        StatusID: {
          filterVal: v !== 0 ? v : null,
        },
      },
    });
  };

  const submitToggle = (id, currentStatus) => {
    let newStatus = currentStatus === status.off ? status.on : status.off;

    let auxItems = [...data];
    productsAPI
      .editProduct(companyID, id, { StatusID: newStatus })
      .then((res) => {
        auxItems = auxItems.map((i) => {
          if (i.ProductID === id) {
            i.StatusID = res.status === 200 ? newStatus : currentStatus;
          }
          return i;
        });
        res.status === 200
          ? Toast("", t("success.toggle_product"), "success")
          : Toast("", t("error.toggle_product"), "danger");
        setData(auxItems);
        atualizaTable();
      })
      .catch(() => Toast("", t("error.toggle_product"), "danger"));
  };

  const editProduct = (id, editedData, onUpdate) => {
    let auxItems = [...data];

    productsAPI
      .editProduct(companyID, id, editedData)
      .then((res) => {
        if (res.status === 200) {
          auxItems = auxItems.map((i) => {
            if (i.ProductID === id) {
              let keys = Object.keys(editedData);
              for (let position in keys) {
                i[keys[position]] = editedData[keys[position]];
              }
              if (editedData.UnitTypeID) {
                i.UnitType = String(
                  units().filter(
                    (g) => g.UnitTypeID === editedData.UnitTypeID
                  )[0].Name
                ).toUpperCase();
              }
            }
            return i;
          });

          const dados = { productID: id, ...editedData.dadosFiscais };
          if (nfcType === "S") {
            dadosFiscais
              .addDadosFiscais(dados, CompanyGroupID)
              .then(() => {
                Toast("", t("success.edit_product"), "success");
              })
              .catch(() => {
                Toast("", t("error.edit_product"), "danger");
              });
          }
          setData(auxItems);
          atualizaTable();
        }
      })
      .catch((e) => {
        Toast("", t("error.edit_product"), "danger");
      });
  };

  const columns = () => {
    let auxColumns = [
      {
        dataField: "edit",
        text: "Editar",
        headerStyle: (colum, colIndex) => {
          return { width: "50px", textAlign: "left", verticalAlign: "initial" };
        },
        formatter: () => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <ion-icon
              style={{
                fontSize: 32,
                color: "var(--color-primary)",
                pointerEvents: "none",
              }}
              content="Editar"
              name={"create-outline"}
            />
          </div>
        ),
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <EditProductDialog
            groups={groups}
            companyGroupID={companyGroupID}
            companyID={companyID}
            companies={companies}
            product={row}
            editProduct={editProduct}
            units={units()}
            nfcType={nfcType}
            value={value}
            {...editorProps}
          />
        ),
      },
      {
        dataField: "StatusID",
        text: t("active"),
        headerStyle: (colum, colIndex) => {
          return {
            width: "70px",
            textAlign: "left",
            verticalAlign: "initial",
            overflow: "visible",
          };
        },
        editable: false,
        formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
          <Toggle
            state={value.StatusID !== status.off}
            submitToggle={() => submitToggle(value.ProductID, value.StatusID)}
          />
        ),
        headerFormatter: statusHeader,
      },
      {
        dataField: "ImageBase64",
        text: t("image"),
        headerStyle: (colum, colIndex) => {
          return { width: "70px", textAlign: "left", verticalAlign: "initial" };
        },
        formatter: (editorProps, value, row, column, rowIndex, columnIndex) => {
          if (value.ImageBase64) {
            return (
              <img
                style={{ maxHeight: 50, maxWidth: 50 }}
                src={"data:image/png;base64," + value.ImageBase64}
              />
            );
          }
          return <div />;
        },
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => <TableEditImage {...editorProps} value={value} />,
      },
      {
        dataField: "ProductGroupID",
        text: t("group"),
        formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
          <p style={{ lineBreak: `anywhere`, maxWidth: 120 }}>
            {value.GrpName}
          </p>
        ),
        headerStyle: (colum, colIndex) => {
          return {
            width: "140px",
            textAlign: "left",
            verticalAlign: "initial",
          };
        },
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <TableEditDropdown
            isLastElement={data.length <= rowIndex + 2}
            {...editorProps}
            id={row.ProductID}
            items={groups}
            itemKey={"Name"}
            outputKey={"ProductGroupID"}
            value={value}
          />
        ),
      },
      {
        sort: true,
        dataField: "Name",
        text: t("name"),
        headerStyle: (colum, colIndex) => {
          return {
            width: "200px",
            textAlign: "left",
            verticalAlign: "initial",
          };
        },
        validator: (newValue, row, column) => {
          const nameRegex = /^[0-9a-zA-Z+,-_% ]+$/;
          if (!nameRegex.test(newValue)) {
            return {
              valid: false,
              message: t("error.product_name_regex"),
            };
          }
          if (newValue.length > 40 || newValue.length < 1) {
            return {
              valid: false,
              message: t("error.product_name_length"),
            };
          }
          return true;
        },
      },

      {
        dataField: "UnitTypeID",
        text: t("unit"),
        formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
          <p style={{ lineBreak: `anywhere`, maxWidth: 120 }}>
            {value.UnitType}
          </p>
        ),
        headerStyle: (colum, colIndex) => {
          return {
            width: "140px",
            textAlign: "left",
            verticalAlign: "initial",
          };
        },
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <TableEditDropdown
            isLastElement={data.length <= rowIndex + 2}
            {...editorProps}
            id={row.ProductID}
            items={units()}
            auxKey={"Description"}
            itemKey={"Name"}
            outputKey={"UnitTypeID"}
            value={value}
          />
        ),
      },

      {
        dataField: "SalePrice",
        text: `${t("price")} (R$)`,
        headerStyle: (colum, colIndex) => {
          return {
            width: "110px",
            textAlign: "left",
            verticalAlign: "initial",
            minWidth: "110px",
          };
        },
        formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
          <p style={{ lineBreak: `anywhere`, maxWidth: 120 }}>
            {formatCurrency(value.SalePrice).substring(2)}
          </p>
        ),
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <ProductPriceInput
            {...editorProps}
            id={row.ProductID}
            value={parseFloat(value).toFixed(2)}
          />
        ),
      },

      {
        dataField: "BarCode",
        text: t("barcode"),
        headerStyle: (colum, colIndex) => {
          return {
            width: "120px",
            textAlign: "left",
            verticalAlign: "initial",
          };
        },
        validator: (newValue, row, column) => {
          if (newValue.length > 20 || newValue.length < 1) {
            return {
              valid: false,
              message: "O código de barras deve ter entre 1 e 20 caracteres",
            };
          }
          return true;
        },
      },
      {
        dataField: "Description",
        text: t("description"),
        headerStyle: (colum, colIndex) => {
          return {
            width: "100px",
            textAlign: "left",
            verticalAlign: "initial",
          };
        },
      },
    ];

    if (nfcType === "S") {
      auxColumns = auxColumns.concat([
        {
          sort: true,
          dataField: "NFCeNCM",
          text: "NCM",
          headerStyle: (colum, colIndex) => {
            return {
              width: "100px",
              textAlign: "left",
              verticalAlign: "initial",
            };
          },
        },
        {
          sort: true,
          dataField: "NFCeCFOP",
          text: "CFOP",
        },
        {
          sort: true,
          dataField: "NFCeCST",
          text: "CST/CSOSN",
        },
        {
          sort: true,
          dataField: "NFCeCEST",
          text: "CEST",
          headerStyle: (colum, colIndex) => {
            return {
              width: "70px",
              textAlign: "left",
              verticalAlign: "initial",
            };
          },
        },
        {
          sort: true,
          dataField: "NFCeAliqICMS",
          text: "Alíq. ICSM",
          headerStyle: (colum, colIndex) => {
            return {
              width: "50px",
              textAlign: "left",
              verticalAlign: "initial",
            };
          },
        },
        {
          sort: true,
          dataField: "NFCeCSTPIS",
          text: "CST PIS",
          headerStyle: (colum, colIndex) => {
            return {
              width: "50px",
              textAlign: "left",
              verticalAlign: "initial",
            };
          },
        },
        {
          sort: true,
          dataField: "NFCeAliqPIS",
          text: "Alíq. PIS",
          headerStyle: (colum, colIndex) => {
            return {
              width: "50px",
              textAlign: "left",
              verticalAlign: "initial",
            };
          },
        },
        {
          sort: true,
          dataField: "NFCeCSTCOFINS",
          text: "CST COFINS",
          headerStyle: (colum, colIndex) => {
            return {
              width: "60px",
              textAlign: "left",
              verticalAlign: "initial",
            };
          },
        },
        {
          sort: true,
          dataField: "NFCeAliqCOFINS",
          text: "Alíq. COFINS",
          headerStyle: (colum, colIndex) => {
            return {
              width: "60px",
              textAlign: "left",
              verticalAlign: "initial",
            };
          },
        },
      ]);
    }
    return auxColumns;
  };

  const beforeSaveCell = (oldValue, newValue, row, column, done) => {
    const itemId = row.ProductID;
    const whatToChange = column.dataField;
    let auxItems = [...data];

    if (oldValue !== newValue) {
      setTimeout(() => {
        productsAPI
          .editProduct(companyID, itemId, { [whatToChange]: newValue })
          .then((res) => {
            auxItems = auxItems.map((i) => {
              if (i.ProductID === itemId) {
                if (whatToChange === "ProductGroupID" && res.status === 200) {
                  i.GrpName = groups.filter(
                    (g) => g.ProductGroupID === newValue
                  )[0].Name;
                }
                if (whatToChange === "UnitTypeID" && res.status === 200) {
                  i.UnitType = String(
                    units().filter((g) => g.UnitTypeID === newValue)[0].Name
                  ).toUpperCase();
                }
                i[whatToChange] = res.status === 200 ? newValue : oldValue;
              }
              return i;
            });
            if (res.status !== 200) {
              Toast(
                "",
                `${t("product_register.error_updating_1")} ${whatToChange} ${t(
                  "product_register.error_updating_2"
                )}`,
                "danger"
              );
            }
            setData(auxItems);
          })
          .catch(() => {
            auxItems = auxItems.map((i) => {
              if (i.ProductID === itemId) {
                i[whatToChange] = oldValue;
              }
              return i;
            });
            setData(auxItems);
            Toast(
              "",
              `${t("product_register.error_updating_1")} ${whatToChange} ${t(
                "product_register.error_updating_2"
              )}`,
              "danger"
            );
          });

        done(true);
      }, 0);
    } else if (column.dataField === "ImageBase64") {
      auxItems = auxItems.map((i) => {
        if (i.ProductID === itemId) {
          i[whatToChange] = oldValue;
        }
        return i;
      });
      setData(auxItems);
      done(true);
    } else {
      done(false);
    }

    return { async: true };
  };

  const onSelectExcel = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    let file = e.target.files[0];
    let fileName = e.target.files[0].name;
    let idxDot = fileName.lastIndexOf(".") + 1;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let file64 = reader.result.substring(78);
      sheetsAPI
        .saveSheets(companyID, file64, fileName)
        .then((res) => {
          if (res.status === 200) {
            let auxInfo = {
              info: {
                importedTotal: res.data.importadosCount,
                notImportedTotal: res.data.naoImportadosCount,
              },
              errors: formatErrorProductTable(res.data.naoImportados),
            };
            setImportError(auxInfo);
            document.getElementById("file-upload").value = "";
          }
        })
        .catch(() => (document.getElementById("file-upload").value = ""));
    };

    reader.onerror = function (error) {
      // console.log('Error on getBase64: ', error)
      document.getElementById("file-upload").value = "";
    };
  };

  const onSelectImageZip = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    let file = e.target.files[0];
    let fileName = e.target.files[0].name;
    let idxDot = fileName.lastIndexOf(".") + 1;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let file64 = reader.result.substring(41);
      sheetsAPI
        .saveImages(companyID, file64, fileName)
        .then((res) => {
          if (res.status === 200) {
            let auxInfo = {
              info: {
                importedTotal: res.data.importadosCount,
                notImportedTotal: res.data.naoImportadosCount,
              },
              errors: formatErrorProductImages(res.data.naoImportados),
            };
            setImportImagesError(auxInfo);
            document.getElementById("file-upload_2").value = "";
          }
        })
        .catch(() => (document.getElementById("file-upload_2").value = ""));
    };

    reader.onerror = function (error) {
      // console.log('Error on getBase64: ', error)
      document.getElementById("file-upload_2").value = "";
    };
  };

  const addProduct = (product) => {
    setData((p) => p.concat([product]));
    setShowAlertAction(true);
    setShowAddDialog(false);
  };

  return (
    <div onKeyDown={(e) => testFun(e, columns())} className="table_container">
      <ToolkitProvider
        keyField="ProductID"
        data={data}
        columns={columns()}
        search
      >
        {(props) => {
          return (
            <div className="product_table">
              <div className="table_title">
                <div className="table_add_container">
                  <Button
                    onClick={() => setShowAddDialog(true)}
                    text={t("product_register.add")}
                  />
                  {/* <Button onClick={() => getSheet()} text={t('product_register.export')} />
										<label htmlFor="file-upload" className="excel-file-upload">
											{t('product_register.import')}
										</label>
										<input id="file-upload" type="file" accept=".xlsx*" onChange={onSelectExcel} />
										<label htmlFor="file-upload_2" className="excel-file-upload">
											{t('product_register.import_images')}
										</label>
										<input id="file-upload_2" type="file" accept=".zip*" onChange={onSelectImageZip} /> */}
                </div>

                <div className="table_search_container">
                  <SearchBar placeholder={t("search")} {...props.searchProps} />
                  <ClearSearchButton text={t("clear")} {...props.searchProps} />
                </div>
              </div>

              <hr />
              <BootstrapTable
                onTableChange={handleTableChange}
                {...props.baseProps}
                remote
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  beforeSaveCell,
                })}
                striped
                hover
                filter={filterFactory()}
                pagination={paginationFactory({ page, sizePerPage, totalSize })}
              />
            </div>
          );
        }}
      </ToolkitProvider>

      {showAddDialog && (
        <AddProductDialog
          groups={groups}
          setOpen={setShowAddDialog}
          companyID={companyID}
          nfcType={nfcType}
          addProduct={addProduct}
          companyGroupID={companyGroupID}
          companies={companies}
        />
      )}

      {showAlertAction && (
        <AlertAction
          onClick={() => setShowAddDialog(true)}
          buttonText={t("add")}
          message={t("product_register.alert_message")}
          setOpen={setShowAlertAction}
        />
      )}

      {importError.errors && (
        <ErrorImportDialog
          setOpen={() => setImportError({})}
          data={importError.errors}
          info={importError.info}
        />
      )}
      {importImagesError.errors && (
        <ErrorImportImagesDialog
          setOpen={() => setImportImagesError({})}
          data={importImagesError.errors}
          info={importImagesError.info}
        />
      )}
    </div>
  );
}

export default ProductTable;

const testFun = (event, columns) => {
  const allTds = Array.from(document.getElementsByTagName("td"));
  allTds.map((i, key) => (i.tabIndex = key));
  const currentTab = document.getElementsByClassName(
    "react-bootstrap-table-editing-cell"
  )[0];

  if (currentTab) {
    const currentTabIndex = currentTab.tabIndex;

    switch (event.code) {
      case "ArrowUp":
        document.activeElement.blur();
        // console.log("ArrowUp")
        setTimeout(() => {
          const currentElement = allTds[currentTabIndex - columns.length];
          if (currentElement) {
            currentElement.click();
          }
        }, 80);
        break;
      case "ArrowDown":
        document.activeElement.blur();
        // console.log("ArrowDown")
        setTimeout(() => {
          const currentElement = allTds[currentTabIndex + columns.length];
          if (currentElement) {
            currentElement.click();
          }
        }, 80);
        break;
      case "ArrowRight":
        document.activeElement.blur();
        // console.log("ArrowRight")
        setTimeout(() => {
          const currentElement = allTds[currentTabIndex + 1];
          if (currentElement) {
            currentElement.click();
          }
        }, 80);
        break;
      case "ArrowLeft":
        document.activeElement.blur();
        // console.log("ArrowLeft")
        setTimeout(() => {
          const currentElement = allTds[currentTabIndex - 1];
          if (currentElement) {
            currentElement.click();
          }
        }, 80);
        break;
    }
  }
};
