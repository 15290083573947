import styled from 'styled-components'

export const CONTAINER = styled.div`
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-main);
  
    
    h5 {
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        color: var(--color-dark);
        margin: 10px;
    }

    .text_input_container {
        border: 1.5px solid lightgray;
        border-radius: var(--border-radius-small);
        padding: 8px 12px;
        background: white;
        margin: 4px 0 14px;
        display: flex;
        align-items: center;
        overflow: hidden;
        
        img {
            max-height: 20px;
            margin-right: 10px;
        }
    
        input  {
            font-family: var(--font-family-main);
            font-weight: var(--font-weight-small);
            font-size: 14px;
            color: var(--color-dark);
            width: 100%;
            outline: none;
            background: none;
            border: none;
        }
    
        input::placeholder {
            color: rgba(0,0,0,0.54);
        }
    }
 
    .text_input_error {
        font-size: 10px;
        color: red;
        margin: 0 0 10px;
    }

    .text_input_alert {
        font-size: 10px;
        margin: 0 0 10px;
        color: red;
    }

    ion-icon {
        cursor: pointer;
    }


`