import React from "react";
import { DateRange } from "../../../shared_components";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import t from "../../../config/translate";
import { connect } from "react-redux";
import moment from "moment";
import { formatCurrency } from "../../../utils/formatters";

const { SearchBar, ClearSearchButton } = Search;

function ReportTable({
//   companyID,
  data,
//   setData,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  updateRange,
}) {
  const columns = [
    {
      dataField: "Data",
      text: t("date"),
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <p>{moment(new Date(value.DataHoraJS)).format("DD/MM/YYYY")}</p>
      ),
    },
    {
      dataField: "DataHora",
      text: t("hour"),
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <p>{moment(new Date(value.DataHoraJS)).format("HH:mm")}</p>
      ),
    },
    {
      dataField: "PropertyNumber",
      text: t("POS"),
    },
    {
      dataField: "Val",
      text: t("report_closing_register.liquid_revenue") + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <p>{formatCurrency(value.Val).substring(2)}</p>
      ),
    },
    {
      dataField: "ValInput",
      text: t("report_closing_register.supply") + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <p>{formatCurrency(value.ValInput).substring(2)}</p>
      ),
    },
    {
      dataField: "ValOutput",
      text: t("report_closing_register.bleed") + " (R$)",
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <p>{formatCurrency(value.ValOutput).substring(2)}</p>
      ),
    },
  ];

  return (
    <div className="table_container">
      <ToolkitProvider
        keyField="uniqueTableKey"
        data={data}
        columns={columns}
        search
      >
        {(props) => {
          return (
            <div className="user_table">
              <div className="table_title">
                <h2>{t("report_closing_register.title")}</h2>

                <div className="table_search_container">
                  <DateRange
                    initialDate={initialDate}
                    finalDate={finalDate}
                    setFinalDate={setFinalDate}
                    setInitialDate={setInitialDate}
                    submit={updateRange}
                  />

                  <div className="search_div">
                    <SearchBar
                      placeholder={t("search")}
                      {...props.searchProps}
                    />
                    <ClearSearchButton
                      text={t("clear")}
                      {...props.searchProps}
                    />
                  </div>
                </div>
              </div>

              <hr />
              <BootstrapTable {...props.baseProps} striped />
            </div>
          );
        }}
      </ToolkitProvider>
    </div>
  );
}

export default connect()(ReportTable);
