import styled from 'styled-components'

export const Container = styled.div`
    font-size: 14px;
    font-weight: 600;
    
    color: var(--color-light);

    .accordion {
        max-height: 45.5px;
        width: 227px;
        background: rgba(255, 255, 255, 0.3);
        overflow: hidden;
        transition: 0.3s;
        cursor: pointer;

    }

    .accordion_title {
        min-height: 45.5px;
        min-width: 227px;
        background: var(--color-primary);
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
            display: flex;
            align-items: center;
           
    
            p {
                margin: 0;
            }
    
            ion-icon {
                margin-right: 20px;
                font-size: 18px;
            } 
        }


        &:hover {
            background: rgba(255, 255, 255, 0.2);
        }

        &:active {
            background: rgba(255, 255, 255, 0.1);
        }
    }



    .accordion_active {
        max-height: 5000px;
        width: 227px;
        overflow: hidden;
        transition: 2s;
        cursor: pointer;
    }

    .accordion_title.active {
        &:hover {
            background: rgba(255, 255, 255, 0.2);
        }
          &:active {
            background: rgba(255, 255, 255, 0.1);
        }
    }

    .accordion_inner_option {
        padding: 12px 16px;
        background: var(--color-primary);
        color: var(--color-light);

        &:hover {
            background: rgba(255, 255, 255, 0.2);
        }

        &:active {
            background: rgba(255, 255, 255, 0.1);
        }

        span {
            color: var(--color-light);
            padding-left: 50px;
        }
    }

    .inner_option_title {
        padding: 6px 16px;
        border-top: 2px solid var(--color-primary);
        background: var(--color-secondary);
        margin: 0;
        padding-left: 65px;
    }

    .with_title {
        padding-left: 35px;
    }

    .accordion_inner_option.active {
        background: rgba(255, 255, 255, 0.1);
    }
`