import React, { useState } from 'react'
import { Container } from './styles'
import { withRouter } from 'react-router-dom'
import { Fragment } from 'react'

function Accordion({ onChange, options, location }) {
    const [selectedOption, setSelectedOption] = useState('')
    const [innerOption, setInnerOption] = useState('')

    const handleInnerOption = (o, i) => {
        setInnerOption(i.name)
        onChange && onChange(o.pathname, i.pathname)
    }

    const pathName = location.pathname ? location.pathname.split('/').slice(1) : ['', '']

    const handleSelectedOption = (val) => {

        if (selectedOption !== val) {
            setSelectedOption(val)
            setInnerOption('')
            if(!val.innerOptions && onChange) {
                onChange(val.pathname)
            }
        } else {
            setSelectedOption('')
        }

    }

    const getInnerOptionClassName = (innerOption, item, title) => {
        let className = 'accordion_inner_option'
        if(innerOption === item.name || item.pathname === pathName[1]){
            className = className + ' active'
        }  
        if(title) {
            className = className + ' with_title'
        }
        return className
    }

    return (
        <Container>
            {options.map((o, key) =>
                <div key={key + 'option'} className={(o.option === selectedOption.option) ? 'accordion_active' : 'accordion'}>
                    <div onClick={() => handleSelectedOption(o)}
                        className={(o.option === selectedOption.option || o.pathname === pathName[0]) ? 'accordion_title active' : 'accordion_title'}>
                        <div>
                            <ion-icon name={o.icon} />
                            <p>{o.option}</p>
                        </div>
             
                        {o.innerOptions && o.innerOptions.length > 0 && <ion-icon className='accordion_title_arrow' name='chevron-down-outline'/>}
                    </div>
                    <div className='accordion_content'>
                        {o.innerOptions && o.innerOptions.map((i, key) =>
                            <Fragment key={key}>
                                {i.title && <p className='inner_option_title'>{i.title}</p>}
                                {
                                    i.items.map((item, key) =>
                                        <div key={item.pathname +  key + 'inner_option'} style={{cursor: item.pathname === 'invalid' && 'not-allowed'}} onClick={() => item.pathname !== 'invalid' && handleInnerOption(o, item)}
                                            className={getInnerOptionClassName(innerOption, item, i.title)}>
                                            <span>{item.name}</span>
                                        </div>
                                    )
                                }
                            </Fragment>

                        )}
                    </div>
                </div>
            )}
        </Container>

    )
}

export default withRouter(Accordion)