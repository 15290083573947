import React, { useState } from 'react'
import DataTable from 'react-data-table-component'
import { TextInput, Button } from '../'
import { Container } from './styles'
import t from '../../config/translate'

function Table({
	data,
	colums,
	title = '',
	noDataComponent = '',
	options = { rowsPerPageText: 'Colunas por pagina:', rangeSeparatorText: 'de', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' },
	rowPerPage = 10,
	perPageOptions = [5, 10, 15, 20],
	handlerRowClick = () => { },
	search = { show: false, searchPlaceHolder: '', searchButtonClearText: '' },
	dense = false,
	paginationServer = false,
	onChangeRowsPerPage = () => { },
	onChangePage = () => { },
	totalRows = 0,
	loading = false
}) {
	const [filter, setFilter] = useState('')
	// const [totalRows] = useState(0)

	if (noDataComponent === '') noDataComponent = t('noData')

	const filterData = () => {
		if (filter === '') return data
		let result = []
		data.forEach(d => {
			let keys = Object.keys(d)
			for (let i = 0;i < keys.length;i++) {
				if (d[keys[i]] && d[keys[i]].toString().toUpperCase().includes(filter.toUpperCase())) {
					result.push(d)
					break
				}
			}
		})
		return result
	}

	const handlerSearchChanged = e => setFilter(e)
	const handleButtonClick = () => filterData()

	return (
		<Container>
			{search.show &&
				<div className="search_content">
					<TextInput placeholder={search.searchPlaceHolder} value={filter} className="input_search" onChange={handlerSearchChanged} />
					<Button icon="search" onClick={handleButtonClick} />
				</div>
			}
			<DataTable
				title={title}
				columns={colums}
				data={filterData()}
				pagination
				paginationServer={paginationServer}
				paginationPerPage={rowPerPage}
				paginationRowsPerPageOptions={perPageOptions}
				paginationComponentOptions={options}
				paginationTotalRows={totalRows}
				onRowClicked={handlerRowClick}
				noDataComponent={(<div><br></br><p>{noDataComponent}</p></div>)}
				dense={dense}
				onChangeRowsPerPage={onChangeRowsPerPage}
				onChangePage={onChangePage}
				progressPending={loading}
			/>
		</Container>
	)
}

export default Table