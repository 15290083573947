import styled from 'styled-components'

export const Container = styled.div`
    background: rgb(254, 255, 128);
    font-family: Courier Prime, monospace;
    width: 216.73px;
    height: fit-content;
    padding: 5px 20px;
    text-align: center;
    overflow: hidden;
    margin-bottom: 20px;

    p {
        font-size: 10px;
        margin: 0;
    }

    img {
        max-width: 150px;
        max-height: 60px;
        margin: 5px 0 10px;
        filter: grayscale(1);
    }

    .header2 {
        margin-bottom: 5px;
    }

    .message3 {
        margin-bottom: 5px;
    }

    .header1 {
        margin: 0;
    }

    .productName {
        width: 100%;
        background: #303030;
        color:rgb(254, 255, 128);
        padding: 8px 0;
        font-size: 16px;
    }

    .price {
        padding: 8px 0 5px;
        font-size: 20px;
        font-weight: 600;
        margin: 0;
    }
`