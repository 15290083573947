import React, {useEffect, Fragment} from 'react'
import main from './main'
import rede from './rede'

const templates = {
    main,
    rede,
}

function TemplateRenderer({componentName, template}) {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    const chosenTemplate = template ? template : 'main'
	let RequestedComponent = templates[chosenTemplate] ? templates[chosenTemplate][componentName] : templates['main'][componentName]
    if(RequestedComponent){
        return <RequestedComponent/>
    } else {
        return <Fragment>404</Fragment>
    }
}

export default TemplateRenderer