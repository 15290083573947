import React from 'react'
import { CONTAINER } from './styles'

function TEXT_INPUT({ icon, rows = "4", title, styles, placeholder, type, id, onChange, value }) {

	if (onChange) {
		return (
			<CONTAINER >
				{title && <h5>{title}</h5>}
				<div className='text_input_container' style={{ ...styles }}>
					{icon && <ion-icon name={icon}></ion-icon>}
					<textarea value={value}
						onChange={onChange}
						rows={rows}
						id={id}
						placeholder={placeholder}
						type={type} />
				</div>
			</CONTAINER>
		)
	}

	return (
		<CONTAINER>
			{title && <h5>{title}</h5>}
			<div className='text_input_container' style={{ ...styles }}>
				{icon && <ion-icon name={icon}></ion-icon>}
				<textarea
					rows={rows}
					id={id}
					placeholder={placeholder}
					type={type}
				/>
			</div>
		</CONTAINER>
	)
}

export default TEXT_INPUT