/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSetState } from 'react-use'
import { Container } from './styles'
import { Nav, TopNav } from '../../components'
import { useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import t from '../../../config/translate'
import { Dropdown, TextInput, Button, Toast } from '../../../shared_components'
import configApi from '../../../services/config'
import systemConfigApi from '../../../services/systemConfig'
import { css } from "@emotion/react"
import { ClipLoader } from "react-spinners"

const getAcessLevel = () => configApi.getAcessLevel()

const direct = (data) => data.Value
const sNOption = (data) => {
	if (data.Value === 'S') return { value: 'S', description: 'Sim' }
	if (data.Value === 'N') return { value: 'N', description: 'Não' }
}
const sNOptionKey = (data) => {
	if (data.keyStatus === 'S') return { value: 'S', description: 'Sim' }
	if (data.keyStatus === 'N') return { value: 'N', description: 'Não' }
}
const findOption = async (data, defaultObj) => {
	const acessLevel = await getAcessLevel()
	let find = acessLevel.data.find(level => level.ShortName === data.Value)
	if (find) return find
	return defaultObj
}
const SendSN = (conf, data, key) => {
	return { Id: conf, Value: data.value }
}
const SendAcessLevel = (conf, data, key) => {
	return { Id: conf, Value: data.ShortName, KeyStatus: key }
}
const SendDirect = (conf, data, key) => {
	return { Id: conf, Value: data }
}

//enableRefound: '',
//requiredIdentification: '',
//enableDiscount: '',
const mapConf = {
	enableAmbulant: { conf: 'VENDA.AMBULANTE', operation: sNOption, update: SendSN, keyStatus: '' },
	enableOnlineButton: { conf: 'SISTEMA.ONLINE', operation: sNOption, update: SendSN, keyStatus: '' },
	acessLevelRefound: { conf: 'VENDA.ESTORNO', operation: findOption, update: SendAcessLevel, keyStatus: 'enableRefound' },
	moneySimbol: { conf: 'VENDA.MOEDA', operation: direct, update: SendDirect, keyStatus: '' },
	enableMoneyPayamentType: { conf: 'VENDA.DINHEIRO', operation: sNOption, update: SendSN, keyStatus: '' },
	showScreenChange: { conf: 'VENDA.TELATROCO', operation: sNOption, update: SendSN, keyStatus: '' },
	identification: { conf: 'VENDA.IDENTIFICACAO', operation: direct, update: SendDirect, keyStatus: '' },
	requiredCpfCnpj: { conf: 'VENDA.CPF', operation: sNOption, update: SendSN, keyStatus: '' },
	validateCpfCnpj: { conf: 'VENDA.VALIDACPF', operation: sNOption, update: SendSN, keyStatus: '' },
	acessLevelDiscount: { conf: 'VENDA.DESCONTO', operation: findOption, update: SendAcessLevel, keyStatus: 'enableDiscount' },
	maxDiscount: { conf: 'VENDA.DESCONTOMAX', operation: direct, update: SendDirect, keyStatus: '' },
	enableDiscount: { conf: 'VENDA.DESCONTO', operation: sNOptionKey },
	enableRefound: { conf: 'VENDA.ESTORNO', operation: sNOptionKey },
}

function SystemConfig() {
	const whitelabel_name = useSelector(state => state.theme.whitelabel_name)
	const CompanyID = useSelector(state => state.auth.authedStore.CompanyID)
	const userPermission = useSelector(state => state.auth.permissions)
	const pageTitle = t('systemConfig.title')

	const [disabled, setDisabled] = useState(true)
	const SNOptions = [{ value: 'S', description: 'Sim' }, { value: 'N', description: 'Não' }]
	const SNOptionsDefault = SNOptions[1]
	const SNItemKey = 'description'

	const [acessLevel, setAcessLevel] = useState([])
	const acessLevelDefault = { AccessLevelID: '55550E77-10D1-40DA-A067-075CB2EDDCFA', Name: 'Caixa – Acesso no PDV para venda de produtos', ShortName: 'CAX' }
	const acessLevelItemKey = 'Name'

	const [dicountOptions, setDiscountOptions] = useState([])

	const [processing, setProcessing] = useState(false)

	const [state, setState] = useSetState({
		enableAmbulant: SNOptionsDefault,
		enableOnlineButton: SNOptionsDefault,
		enableRefound: SNOptionsDefault,
		acessLevelRefound: acessLevelDefault,
		moneySimbol: 'R$',
		enableMoneyPayamentType: SNOptionsDefault,
		showScreenChange: SNOptionsDefault,
		requiredIdentification: SNOptionsDefault,
		identification: '',
		requiredCpfCnpj: SNOptionsDefault,
		validateCpfCnpj: SNOptionsDefault,
		enableDiscount: SNOptionsDefault,
		acessLevelDiscount: acessLevelDefault,
		maxDiscount: 5
	})

	useEffect(() => {
		let discounts = []
		let value = 0
		while (value < 70) discounts.push(value += 5)
		setDiscountOptions(discounts)
	}, [])

	useEffect(() => {
		let componentMounted = true
		//if (userPermission && userPermission.role === 'MST') setDisabled(false)
		if (userPermission && (userPermission.role === 'MST' || userPermission.role === 'ADM')) setDisabled(false)

		getAcessLevel()
			.then((res) => res.data)
			.then(data => componentMounted && setAcessLevel(data))
			.catch((err) => console.error("[systemConfigAcessLevel]", err.message))
		return () => componentMounted = false
	}, [])

	useEffect(() => {
		let componentMounted = true
		const req = (id, add) => {
			systemConfigApi.getPosPdvConfig(CompanyID, `id='${id}'`)
				.then((res) => res.data)
				.then(data => componentMounted && add(data[0]))
				.catch((err) => console.error("[systemAllConfig]", err))
		}

		Object.entries(mapConf).forEach(conf => {
			req(conf[1].conf, async (data) => {
				let change = {}
				change[conf[0]] = await conf[1].operation(data, acessLevelDefault)
				if (conf[1].operation(data)) setState(change)
			})
		})

		return () => componentMounted = false
	}, [CompanyID])

	const handleChange = (value, e) => {
		let change = {}
		change[e.target.id] = value
		setState(change)
	}

	const handleSaveClick = () => {
		if (processing) {
			Toast('Atenção', 'Já existe um processo em andamento aguarde!', 'info')
			return
		}
		setProcessing(true)

		let update = []
		Object.entries(state).forEach(conf => {
			if (mapConf[conf[0]] && mapConf[conf[0]].update) {
				const key = state[mapConf[conf[0]].keyStatus] ? state[mapConf[conf[0]].keyStatus].value : ''
				update.push(mapConf[conf[0]].update(mapConf[conf[0]].conf, conf[1], key))
			}
		})
		systemConfigApi.updateAllPosPdvConfig(CompanyID, update)
			.then(() => {
				Toast('Atenção', 'Dados configurados com sucesso!', 'success')
				setProcessing(false)
			})
			.catch((err) => {
				Toast('Atenção', 'Ocorreu um erro inesperado, por favor tente novamente!', 'danger')
				setProcessing(false)
			})
	}

	const override = css
		`
		display: block;
		border-color: red;
	`

	return (
		<Container>
			<Helmet>
				<title>{pageTitle} | {whitelabel_name}</title>
			</Helmet>

			<Nav />
			<div className='content_container'>
				<TopNav pageTitle={pageTitle} />
				<div className='form_container'>
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>

						<div className='column'>
							<p className='section_title'>{t('optionsScreen')}</p>
							<Dropdown title={t('enableAmbulant')} id='enableAmbulant' value={state.enableAmbulant} onChange={handleChange} items={SNOptions} itemKey={SNItemKey} disabled={disabled} />
							<Dropdown title={t('enableOnlineButton')} id='enableOnlineButton' value={state.enableOnlineButton} onChange={handleChange} items={SNOptions} itemKey={SNItemKey} disabled={disabled} />
							<Dropdown title={t('enableRefound')} id='enableRefound' value={state.enableRefound} onChange={handleChange} items={SNOptions} itemKey={SNItemKey} disabled={disabled} />
							<Dropdown title={t('acessLevelToRefound')} id='acessLevelRefound' value={state.acessLevelRefound} onChange={handleChange} items={acessLevel} itemKey={acessLevelItemKey} disabled={disabled} />
						</div>

						<div className='column'>
							<p className='section_title'>{t('anotherScrens')}</p>
							<TextInput title={t('MoneySymbol')} id='moneySimbol' value={state.moneySimbol} onChange={handleChange} disabled={disabled} />
							<Dropdown title={t('enableMoneyPayamentType')} id='enableMoneyPayamentType' value={state.enableMoneyPayamentType} onChange={handleChange} items={SNOptions} itemKey={SNItemKey} disabled={disabled} />
							<Dropdown title={t('showScreenChange')} id='showScreenChange' value={state.showScreenChange} onChange={handleChange} items={SNOptions} itemKey={SNItemKey} disabled={disabled} />
							<Dropdown title={t('requiredIdentification')} id='requiredIdentification' value={state.requiredIdentification} onChange={handleChange} items={SNOptions} itemKey={SNItemKey} disabled={disabled} />
							<TextInput title={t('identification')} id='identification' value={state.identification} onChange={handleChange} disabled={disabled} />
							<Dropdown title={t('requiredCpfCnpj')} id='requiredCpfCnpj' value={state.requiredCpfCnpj} onChange={handleChange} items={SNOptions} itemKey={SNItemKey} disabled={disabled} />
							<Dropdown title={t('validateCpfCnpj')} id='validateCpfCnpj' value={state.validateCpfCnpj} onChange={handleChange} items={SNOptions} itemKey={SNItemKey} disabled={disabled} />
							<Dropdown title={t('enableDiscount')} id='enableDiscount' value={state.enableDiscount} onChange={handleChange} items={SNOptions} itemKey={SNItemKey} disabled={disabled} />
							<Dropdown title={t('acessLevelToDiscount')} id='acessLevelDiscount' value={state.acessLevelDiscount} onChange={handleChange} items={acessLevel} itemKey={acessLevelItemKey} disabled={disabled} />
							<Dropdown title={t('maxDiscount')} id='maxDiscount' value={state.maxDiscount} onChange={handleChange} items={dicountOptions} disabled={disabled} />
						</div>
					</div>
					<p className='section_title'></p>
					<div className='group_buttons'>
						<Button text={t('save')} styles={{ width: 150, height: 50 }} onClick={handleSaveClick} />
						<Button text={t('cancel')} styles={{ margin: 5, width: 150, height: 50 }} />
						<ClipLoader color="#ffffff" loading={processing} css={override} size={20} />
					</div>
				</div>
			</div>
		</Container>
	)
}

export default SystemConfig