import React, { useState } from 'react'
import { CONTAINER } from './styles'


function DROPDOWN({ itemKey, items, title, styles, placeholder, id, onChange, value, disabled = false }) {
	const [open, setOpen] = useState(false)

	const canOpen = () => {
		if (!disabled) setOpen(!open)
	}
	return (
		<CONTAINER style={{ ...styles }}>
			{title && <h5>{title}</h5>}
			<div className='dropdown_container'>
				<div className='dropdown_title' onClick={canOpen}>
					{itemKey
						? <input readOnly="readonly" placeholder={placeholder} id={id} value={value[itemKey] ? value[itemKey] : ''} />
						: <input readOnly="readonly" placeholder={placeholder} id={id} value={value || ''} />}
					<ion-icon name="chevron-down-outline"></ion-icon>
				</div>
				{!itemKey && items && items.length > 0 &&
					<div className={open ? 'dropdown_item_container' : 'dropdown_item_container_closed'}>
						{items.map((i, key) =>
							<div key={key} onClick={(e) => { onChange(i, e); canOpen() }} className='dropdown_item' id={id}>
								{i}
							</div>
						)}
					</div>
				}
				{itemKey && items && items.length > 0 &&
					<div className={open ? 'dropdown_item_container' : 'dropdown_item_container_closed'}>
						{items.map((i, key) =>
							<div key={key} onClick={(e) => { onChange(i, e); canOpen() }} className='dropdown_item' id={id}>
								{i[itemKey]}
							</div>
						)}
					</div>
				}
			</div>
		</CONTAINER>
	)
}

export default DROPDOWN