import React, { useState, useEffect } from 'react'
import { useSetState } from 'react-use'
import { Container } from './styles'
import { Nav, TopNav } from '../../components'
import { useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import t from '../../../config/translate'
import { TextInput, Button, Toast, Table, Dialog } from '../../../shared_components'
import companyAPI from '../../../services/company'
import productGroupAPI from '../../../services/productGroup'
import posPdvApi from '../../../services/posPdv'
import { css } from "@emotion/react"
import { ClipLoader } from "react-spinners"

function Terminalconfig() {
	const whitelabel_name = useSelector(state => state.theme.whitelabel_name)
	const CompanyID = useSelector(state => state.auth.authedStore.CompanyID)
	const CompanyGroupID = useSelector(state => state.auth.authedStore.CompanyGroupID)
	const userPermission = useSelector(state => state.auth.permissions)
	const pageTitle = t('terminal.title')

	const [disabled, setDisabled] = useState(true)
	// const [processing, setProcessing] = useState(false)
	const [processingIngressPassword, setProcessingIngressPassword] = useState(false)
	const [processingCashlessPassword, setProcessCashlessPassword] = useState(false)
	const [data, setData] = useState([])

	const [state, setState] = useSetState({
		cnpj: '',
		basePassword: '',
		user: '',
		apiPassword: '',
		ingressPassword: '',
		passwordCashless: '',
		produtoGroupID: '',
		comapanyName: ''
	})

	useEffect(() => {
		let componentMounted = true
		if (userPermission && userPermission.role === 'MST') setDisabled(false)

		companyAPI.get(CompanyID)
			.then((res) => res.data)
			.then(data => {
				if (componentMounted) {
					setState({ cnpj: data.Cnpj || '' })
					setState({ basePassword: data.InitHash || '' })
					setState({ user: data.apiUser || '' })
					setState({ apiPassword: data.apiPassword || '' })
					setState({ passwordCashless: data.ClessUID || '' })
					setState({ comapanyName: data.Name || '' })
				}
			})
			.catch((err) => console.error("[systemCompanyConfig]", err))

		productGroupAPI.getIngressPassword(CompanyGroupID)
			.then((res) => res.data)
			.then(data => {
				if (componentMounted) {
					setState({ ingressPassword: data.SeedHash })
					setState({ produtoGroupID: data.productGroupID })
				}
			})
			.catch((err) => console.error("[systemIngressPasswordConfig]", err))

		posPdvApi.gets(CompanyID)
			.then(res => res.data)
			.then(data => {
				if (componentMounted) {
					setData(data)
				}
			})
			.catch((err) => console.error("[systemPosPdvGets]", err))

		return () => componentMounted = false
	}, [CompanyID, CompanyGroupID, userPermission, setState])

	const handleChange = (value, e) => {
		let change = {}
		change[e.target.id] = value
		setState(change)
	}

	// const handleSaveClick = () => {
	// 	if (processing) {
	// 		Toast('Atenção', 'Já existe um processo em andamento aguarde!', 'info')
	// 		return
	// 	}
	// 	setProcessing(true)
	// 	setProcessing(false)
	// }

	const [openDialogIngress, setOpenDialogIngress] = useState(false)
	const [openDialogCashless, setOpenDialogCashless] = useState(false)

	const handleClickIngressPassword = () => {
		if (disabled) return
		if (processingIngressPassword) {
			Toast('Atenção', 'Já existe um processo em andamento aguarde!', 'info')
			return
		}
		setProcessingIngressPassword(true)
		productGroupAPI.updateSeedHash(CompanyGroupID, { productGroupID: state.produtoGroupID })
			.then((data) => {
				setState({ ingressPassword: data.data.SeedHash })
				Toast('Atenção', 'Nova senha gerada com sucesso!', 'success')
				setProcessingIngressPassword(false)
				setOpenDialogIngress(false)
			})
			.catch(() => {
				Toast('Atenção', 'Ocorreu um erro, por favor tente novamente!', 'info')
				setProcessingIngressPassword(false)
				setOpenDialogIngress(false)
			})
	}

	const handleClickPasswordCashless = () => {
		if (disabled) return
		if (processingCashlessPassword) {
			Toast('Atenção', 'Já existe um processo em andamento aguarde!', 'info')
			return
		}
		setProcessCashlessPassword(true)
		companyAPI.updateClessUID(CompanyID)
			.then((data) => {
				setState({ passwordCashless: data.data.ClessUID })
				Toast('Atenção', 'Nova senha gerada com sucesso!', 'success')
				setProcessCashlessPassword(false)
				setOpenDialogCashless(false)
			})
			.catch(() => {
				Toast('Atenção', 'Ocorreu um erro, por favor tente novamente!', 'info')
				setProcessCashlessPassword(false)
				setOpenDialogCashless(false)
			})

	}

	const createRow = (row, field) => (<div className="table_row">{row[field]}</div>)
	const colums = [
		{
			name: <div className="table_header">POS</div>,
			selector: (row) => row["PropertyNumber"],
			cell: (row) => createRow(row, "PropertyNumber"),
			sortable: true,
		},
		{
			name: <div className="table_header">Serial</div>,
			selector: (row) => row["SerialNumber"],
			cell: (row) => createRow(row, "SerialNumber"),
			sortable: true,
		},
		{
			name: <div className="table_header">PDV ID (NDD)</div>,
			selector: (row) => row["PDVID"],
			cell: (row) => createRow(row, "PDVID"),
			sortable: true,
		},
		{
			name: <div className="table_header">Serie</div>,
			selector: (row) => row["NFSerie"],
			cell: (row) => createRow(row, "NFSerie"),
			sortable: true,
		},
		{
			name: <div className="table_header">PDV ID (SE)</div>,
			selector: (row) => row["PDVID"],
			cell: (row) => createRow(row, "PDVID"),
			sortable: true,
		},
		{
			name: <div className="table_header">OTP</div>,
			selector: (row) => row["GSurfOPT"],
			cell: (row) => createRow(row, "GSurfOPT"),
			sortable: true,
		}
	]

	const override = css
		`
		display: block;
		border-color: red;
	`
	return (
		<Container>
			<Helmet>
				<title>{pageTitle} | {whitelabel_name}</title>
			</Helmet>

			<Nav />

			{openDialogCashless &&
				<Dialog
					setOpen={setOpenDialogCashless}
					Children={
						<div>
							<p className='section_dialog_title'>Tem certeza que deseja trocar a senha?</p>
							<div className='group_buttons'>
								<Button text='Sim' onClick={handleClickPasswordCashless} styles={{ width: 180, height: 40, margin: 10 }} />
								<Button text='Não' onClick={() => setOpenDialogCashless(false)} styles={{ width: 180, height: 40, margin: 10 }} />
							</div>
						</div>
					}
				/>}

			{openDialogIngress &&
				<Dialog
					setOpen={setOpenDialogIngress}
					Children={
						<div>
							<p className='section_dialog_title'>Tem certeza que deseja trocar a senha?</p>
							<div className='group_buttons'>
								<Button text='Sim' onClick={handleClickIngressPassword} styles={{ width: 180, height: 40, margin: 10 }} />
								<Button text='Não' onClick={() => setOpenDialogIngress(false)} styles={{ width: 180, height: 40, margin: 10 }} />
							</div>
						</div>
					}
				/>}

			<div className='content_container'>
				<TopNav pageTitle={pageTitle} />
				<div className='form_container'>
					<p className='section_title'>{t('savePos')}</p>
					<Table
						data={data}
						colums={colums}
						search={{ show: true, searchPlaceHolder: t("search"), searchButtonClearText: t("clear") }}
						dense={false}
					/>

					{/* <p className='section_title'></p>
					<div className='group_buttons'>
						<Button text={t('save')} styles={{ width: 150, height: 50 }} onClick={handleSaveClick} />
						<Button text={t('cancel')} styles={{ margin: 5, width: 150, height: 50 }} />
						<ClipLoader color="#ffffff" loading={processing} css={override} size={20} />
					</div> */}
				</div>
			</div>
		</Container>
	)
}

export default Terminalconfig