import React, { useEffect, useState } from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Background, DIALOG_CONTAINER, DATE_CONTAINER } from './styles'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import WhiteButton from '../white_button'
import { ptBR } from "date-fns/locale"

function DATE_PICKER(props) {
	const { id, placeholder, minDate, language, title, styles, setDate, maxDate, initialValue } = props
	const [value, setValue] = useState(initialValue)
	const [currentMinDate, setCurrentMinDate] = useState(minDate)
	const [showDialog, setShowDialog] = useState(false)

	useEffect(() => {
		if (!(moment(minDate).isSame(currentMinDate, 'day'))) {
			const outsideMinDate = moment(minDate)
			const outsideMaxDate = moment(maxDate)
			setCurrentMinDate(outsideMinDate)

			if (value && moment(value).isBefore(outsideMinDate)) {
				setValue(outsideMinDate)
				setDate(moment(outsideMinDate).toDate())
			} else if (moment(value).isAfter(outsideMaxDate)) {
				setValue(outsideMaxDate)
				setDate(moment(outsideMaxDate).toDate())
			}
		}
	}, [minDate, maxDate, currentMinDate, value, setDate])

	const handleChange = (val) => {
		setValue(moment(val))
		setDate(moment(val).toDate())
	}

	return (
		<DATE_CONTAINER>
			<div style={{ minWidth: '100%' }}>
				{title && <h5>{title}</h5>}

				<div style={{ ...styles }}
					className="date-range-custom-input"
					onClick={(e) => {
						e.stopPropagation()
						setShowDialog(true)
					}} >

					<input disabled={true}
						placeholder={placeholder}
						defaultValue={formatDate(value, language)}
						id={id} />
					<div>
						{value ? <span className='date-picker-value'>{formatDate(value, language)}</span> : <span className='date-picker-placeholder'>{placeholder}</span>}
					</div>
				</div>

			</div>
			{showDialog &&
				<DIALOG_CONTAINER>
					<Background onClick={() => setShowDialog(false)} />
					<div className='picker_btn_container'>
						<WhiteButton className='close_button'
							type='button'
							onClick={() => setShowDialog(false)}
							icon={'close-outline'} />
						<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
							<DatePicker
								format="dd/MM/yyyy"
								value={value}
								variant="static"
								minDate={currentMinDate}
								maxDate={maxDate}
								autoOk
								onClose={() => setShowDialog(false)}
								onChange={handleChange}
							/>
						</MuiPickersUtilsProvider>
					</div>

				</DIALOG_CONTAINER>
			}
		</DATE_CONTAINER>
	)
}


function formatDate(value, locale = 'pt') {
	if (locale === 'us') {
		return moment(value).format('MM/DD/YYYY')
	}
	return moment(value).format('DD/MM/YYYY')

}

export default DATE_PICKER