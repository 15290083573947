import { getCompany, getAuthedStore, getTokens, logout, getUserPermissions } from './ac'
import jwt from 'jsonwebtoken'
import userApi from '../../../../services/user'
import { canConvertToJson } from '../../../../utils/jsonValidator'

export function handleGetTokens(tokens, redirectBack) {
	return (dispatch) => {
		const { token } = tokens
		const decoded = jwt.decode(token, { complete: true })

		if (decoded.payload) {
			// console.log('Your JWT was successfully validated!')
			const company = decoded.payload

			dispatch(getCompany(company))
			redirectBack && redirectBack()

			let companyJson = company.Companys
			if (canConvertToJson(company.Companys)) companyJson = JSON.parse(company.Companys)
			const specificStore = {
				'Name': companyJson[0].Trademark,
				'CompanyID': companyJson[0].CompanyID,
				'CompanyGroupID': company.CompanyGroupID
			}
			dispatch(handleAuthedStore(specificStore))

			userApi.getRole(token)
				.then(res => res && res.data)
				.then(res => dispatch(handleUserPermissions(res)))
				.catch((err) => {
					//console.log('Erro dispatch role', err)
				})
		}

		return dispatch(getTokens(tokens))
	}
}

export function handleAuthedStore(authedStore) {
	return (dispatch) => {
		return dispatch(getAuthedStore(authedStore))
	}
}

export function handleUserPermissions(permissions) {
	return (dispatch) => {
		return dispatch(getUserPermissions(permissions))
	}
}

export function handleLogout(successLogout) {
	return (dispatch) => {
		successLogout && successLogout()
		return dispatch(logout())
	}
}