import styled from 'styled-components'

export const Container = styled.div`
font-family: var(--font-family-primary);
background: var(--color-primary);
    .lateral_nav {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        min-height: 100vh;
        transition: 0.3s;
    

        @media screen and (min-width: 651px) {
            width: 0px;
        }
        @media screen and (max-width: 650px){
            position: fixed;
            left: -277px;
            top: 90px;
            width: 237px;
            z-index: 2021;
        }


    }

.lateral_nav.active {
    opacity: 1;
    transition: 0.3s;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    
    @media screen and (min-width: 651px) {
        width: 237px;
    }


    @media screen and (max-width: 650px){
        position: fixed;
        left: 0px;
        top: 90px;
        width: 237px;
        z-index: 2021;
        background: var(--color-primary);
    }
}

    .company_logo {
        max-width: 170px;
        margin: 35px 15px 35px;
    }

    .white_logo_container {
        background: white;
    }

    
    &::-webkit-scrollbar {
        display: none;
      }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

`