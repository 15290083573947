/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSetState } from 'react-use'
import { Container } from './styles'
import { Nav, TopNav } from '../../components'
import { useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import t from '../../../config/translate'
import { Dropdown, TextInput, Button, Toast } from '../../../shared_components'
import systemConfigApi from '../../../services/systemConfig'
import companyAPI from '../../../services/company'
import { css } from "@emotion/react"
import { ClipLoader } from "react-spinners"

const direct = (data) => data.Value
const sNOption = (data) => {
	if (data.Value === 'S') return { value: 'S', description: 'Sim' }
	if (data.Value === 'N') return { value: 'N', description: 'Não' }
}
const sNOAmbType = (data) => {
	if (data.Value === '2') return { value: '2', description: 'Homologação' }
	if (data.Value === '1') return { value: '1', description: 'Produção' }
}
const sNOEmmitType = (data) => {
	if (data.Value === '9') return { value: '9', description: 'Contigencia' }
	if (data.Value === '1') return { value: '1', description: 'Normal' }
}

const SendDefault = (conf, data, key) => {
	return { Id: conf, Value: data.value }
}
const SendDirect = (conf, data, key) => {
	return { Id: conf, Value: data }
}

//logicNumber
const mapConf = {
	companyCodFromAdquirente: { conf: 'TEF.CODEMPRESA', operation: direct, update: SendDirect, keyStatus: '' },
	activeTaxParam: { conf: 'AMB.FISCAL', operation: sNOption, update: SendDefault, keyStatus: '' },
	companyIsSimplesNacional: { conf: 'NFCE.SIMPLES', operation: sNOption, update: SendDefault, keyStatus: '' },
	emmitNfce: { conf: 'NFCE.STATUS', operation: sNOption, update: SendDefault, keyStatus: '' },
	ambNfcetype: { conf: 'NFCE.AMBIENTE', operation: sNOAmbType, update: SendDefault, keyStatus: '' },
	emmitType: { conf: 'NFCE.EMISSAO', operation: sNOEmmitType, update: SendDefault },
	valueRequiredCPF: { conf: 'NFCE.CPFVAL', operation: direct, update: SendDirect, keyStatus: '' },
}

function NfceTefconfig() {
	const pageTitle = t('nfceTef.title')

	const whitelabel_name = useSelector(state => state.theme.whitelabel_name)
	const CompanyID = useSelector(state => state.auth.authedStore.CompanyID)
	const userPermission = useSelector(state => state.auth.permissions)

	const SNOptions = [{ value: 'S', description: 'Sim' }, { value: 'N', description: 'Não' }]
	const SNOptionsDefault = SNOptions[1]

	const NFCeTypeOptions = [{ value: '2', description: 'Homologação' }, { value: '1', description: 'Produção' }]
	const NFCeTypeOptionsDefault = NFCeTypeOptions[0]

	const NFCeEmmitTypeOptions = [{ value: '1', description: 'Normal' }, { value: '9', description: 'Contigencia' }]
	const NFCeEmmitTypeOptionsDefault = NFCeEmmitTypeOptions[0]

	const DefaultItemKey = 'description'

	const [disabled, setDisabled] = useState(true)
	const [processing, setProcessing] = useState(false)

	const [state, setState] = useSetState({
		logicNumber: '0',
		companyCodFromAdquirente: '0',
		activeTaxParam: SNOptionsDefault,
		companyIsSimplesNacional: SNOptionsDefault,
		emmitNfce: SNOptionsDefault,
		ambNfcetype: NFCeTypeOptionsDefault,
		emmitType: NFCeEmmitTypeOptionsDefault,
		valueRequiredCPF: '0',
		IDCSC: '0',
		CSC: '0',
		HMIDCSC: '0',
		HMCSC: '0'
	})

	useEffect(() => {
		let componentMounted = true

		if (userPermission && userPermission.role === 'MST') setDisabled(false)

		const req = (id, add) => {
			systemConfigApi.getPosPdvConfig(CompanyID, `id='${id}'`)
				.then((res) => res.data)
				.then(data => componentMounted && add(data[0]))
				.catch((err) => console.error("[systemAllConfig]", err))
		}

		Object.entries(mapConf).forEach(conf => {
			req(conf[1].conf, async (data) => {
				let change = {}
				change[conf[0]] = await conf[1].operation(data)
				if (conf[1].operation(data)) setState(change)
			})
		})

		companyAPI.get(CompanyID)
			.then(res => res.data)
			.then(data => {
				setState({ IDCSC: data.IDCSC })
				setState({ CSC: data.CSC ? data.CSC : 0 })

				setState({ HMIDCSC: data.HMIDCSC })
				setState({ HMCSC: data.HMCSC ? data.HMCSC : 0 })
			})
			.catch((err) => console.error("[systemAllConfigCompany]", err))

		return () => componentMounted = false
	}, [CompanyID])

	const handleChange = (value, e) => {
		let change = {}
		change[e.target.id] = value
		setState(change)
	}

	const handleSaveClick = () => {
		if (processing) {
			Toast('Atenção', 'Já existe um processo em andamento aguarde!', 'info')
			return
		}
		setProcessing(true)
		let update = []
		Object.entries(state).forEach(conf => {
			if (mapConf[conf[0]] && mapConf[conf[0]].update) {
				const key = state[mapConf[conf[0]].keyStatus] ? state[mapConf[conf[0]].keyStatus].value : ''
				update.push(mapConf[conf[0]].update(mapConf[conf[0]].conf, conf[1], key))
			}
		})
		Promise.all([
			companyAPI.updateCSC(CompanyID, { IDCSC: state.IDCSC, CSC: state.CSC }),
			companyAPI.updateHMCSC(CompanyID, { IDCSC: state.HMIDCSC, CSC: state.HMCSC }),
			systemConfigApi.updateAllPosPdvConfig(CompanyID, update)
		])
			.then(() => {
				Toast('Atenção', 'Dados configurados com sucesso!', 'success')
				setProcessing(false)
			})
			.catch(() => {
				Toast('Atenção', 'Ocorreu um erro inesperado, por favor tente novamente!', 'danger')
				setProcessing(false)
			})
	}

	const override = css
		`
		display: block;
		border-color: red;
	`
	return (
		<Container>
			<Helmet>
				<title>{pageTitle} | {whitelabel_name}</title>
			</Helmet>

			<Nav />
			<div className='content_container'>
				<TopNav pageTitle={pageTitle} />
				<div className='form_container'>
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>

						<div className='column'>
							<p className='section_title'>{t('tefparam')}</p>
							<TextInput title={t('logicNumber')} id='logicNumber' value={state.logicNumber} onChange={handleChange} disabled={true} />
							<TextInput title={t('companyCodFromAdquirente')} id='companyCodFromAdquirente' value={state.companyCodFromAdquirente} onChange={handleChange} disabled={disabled} />

							<p className='section_title'>{t('taxParam')}</p>
							<Dropdown title={t('activeTaxParam')} id='activeTaxParam' value={state.activeTaxParam} onChange={handleChange} items={SNOptions} itemKey={DefaultItemKey} disabled={disabled} />
							<Dropdown title={t('companyIsSimplesNacional')} id='companyIsSimplesNacional' value={state.companyIsSimplesNacional} onChange={handleChange} items={SNOptions} itemKey={DefaultItemKey} disabled={disabled} />
							<Dropdown title={t('emmitNfce')} id='emmitNfce' value={state.emmitNfce} onChange={handleChange} items={SNOptions} itemKey={DefaultItemKey} disabled={disabled} />
							<Dropdown title={t('ambNfcetype')} id='ambNfcetype' value={state.ambNfcetype} onChange={handleChange} items={NFCeTypeOptions} itemKey={DefaultItemKey} disabled={disabled} />
							<Dropdown title={t('emmitType')} id='emmitType' value={state.emmitType} onChange={handleChange} items={NFCeEmmitTypeOptions} itemKey={DefaultItemKey} disabled={disabled} />
							<TextInput title={t('valueRequiredCPF')} id='valueRequiredCPF' value={state.valueRequiredCPF} onChange={handleChange} disabled={disabled} />
						</div>

						<div className='column'>
							<p className='section_title'>{t('hmlParams')}</p>
							<TextInput title={t('IDCSC')} id='HMIDCSC' value={state.HMIDCSC} onChange={handleChange} disabled={disabled} />
							<TextInput title={t('CSC')} id='HMCSC' value={state.HMCSC} onChange={handleChange} disabled={disabled} />

							<p className='section_title'>{t('prdParams')}</p>
							<TextInput title={t('IDCSC')} id='IDCSC' value={state.IDCSC} onChange={handleChange} disabled={disabled} />
							<TextInput title={t('CSC')} id='CSC' value={state.CSC} onChange={handleChange} disabled={disabled} />
						</div>
					</div>
					<p className='section_title'></p>
					<div className='group_buttons'>
						<Button text={t('save')} styles={{ width: 150, height: 50 }} onClick={handleSaveClick} />
						<Button text={t('cancel')} styles={{ margin: 5, width: 150, height: 50 }} />
						<ClipLoader color="#ffffff" loading={processing} css={override} size={20} />
					</div>
				</div>
			</div>
		</Container>
	)
}

export default NfceTefconfig