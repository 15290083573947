import styled from 'styled-components'

export const Container = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    overflow: hidden;

    .login_line{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1;
    }

    .content_container{
        display: flex;
        flex-wrap: wrap;
        flex: 1;
    }

    .logo {
        max-width: 180px;
        margin: 20px 50px;
    }

    .form_container {
        flex: 1.3;
        z-index: 4;
        display: flex;
        position: relative;
        margin: 5%;
        flex-direction: column;
        justify-content: center;
        font-family: ItauDisplay, sans-serif;
        min-width: 300px;
        h1 {
            font-size: 4vmax;
            color: #F47922;
            font-weight: 400;
        }
        
        h2 {
            color: #00BAB5;
            font-weight: 400;
            font-size: 2.5vmax;
        }
        span {
            color: var(--color-primary);
        }

        .input_container {
            margin-top: 20px;
            max-width: 300px;
        }
    }

    .image_container {
        margin: 5%;
        flex: 2;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 300px;
        z-index: 2;
        img {
            max-width: 90%;
            max-height: 400px;
        }
    }

    .error_message {
        color: var(--color-accent);
        font-weight: 600;
        margin-top: 20px;
    }

    .colored_div {
        width: 40px;
        height: 4px;
        background: #F47922;
        margin: 20px 0 40px;
    }

    .top_nav {
        height: 90px;
        width: 100vw;
        padding: 0 28px;
        font-family: var(--font-family-primary);
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F47922;
        
    }

    .hiperlink_button {
        color: var(--color-primary);
        margin: 20px 0 0;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        &:active {
            transition: 0.3s;
            opacity: 0.5;
        }
    }

`