import React from "react";
import { DateRange } from "../../../shared_components";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import t from "../../../config/translate";
import { connect } from "react-redux";
import moment from "moment";
import { formatCurrency } from "../../../utils/formatters";
import Loading from "../../../shared_components/loading";

const { SearchBar, ClearSearchButton } = Search;

function ReportTable({
//   companyID,
  data,
//   setData,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  updateRange,
  isLoading
}) {
  const columns = [
    {
      dataField: "CompanyName",
      text: t("report_cashless_listagem_credito.comany_name"),
    },
    {
      dataField: "PropertyNumber",
      text: t("report_cashless_listagem_credito.property_number"),
    },
    {
      dataField: "DataHora",
      text: t("report_cashless_listagem_credito.data_hora"),
    },
    {
      dataField: "NFCTransactionsPosCodeID",
      text: t("report_cashless_listagem_credito.nfc_transactions_pos"),
    },
    {
      dataField: "PaymentTypeName",
      text: t("report_cashless_listagem_credito.payment_type"),
    },
    {
      dataField: "AmntTotal",
      text: t("report_cashless_listagem_credito.amt_total"),
    },
    {
      dataField: "TEFType",
      text: t("report_cashless_listagem_credito.tef_type"),
    },
    {
      dataField: "TEFAutorizacao",
      text: t("report_cashless_listagem_credito.tef_autorizacao"),
    },
    {
      dataField: "TEFRede",
      text: t("report_cashless_listagem_credito.tef_rede"),
    },
    {
      dataField: "TEFCartao",
      text: t("report_cashless_listagem_credito.tef_cartao"),
    },
  ];

  return (
    <div className="table_container">
      <ToolkitProvider
        keyField="uniqueTableKey"
        data={data}
        columns={columns}
        search
      >
        {(props) => {
          return (
            <div className="user_table">
              <div className="table_title">
                <div>
                  <h2>{t("report_cashless_listagem_credito.title")}</h2>
                  <h3>{t("report_cashless_listagem_credito.description")}</h3>
                </div>

                <div className="table_search_container">
                  <DateRange
                    initialDate={initialDate}
                    finalDate={finalDate}
                    setFinalDate={setFinalDate}
                    setInitialDate={setInitialDate}
                    submit={updateRange}
                  />

                  <div className="search_div">
                    <SearchBar
                      placeholder={t("search")}
                      {...props.searchProps}
                    />
                    <ClearSearchButton
                      text={t("clear")}
                      {...props.searchProps}
                    />
                  </div>
                </div>
              </div>

              <hr />
              { isLoading ? <Loading /> : <BootstrapTable {...props.baseProps} striped /> }
            </div>
          );
        }}
      </ToolkitProvider>
    </div>
  );
}

export default connect()(ReportTable);
