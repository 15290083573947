import React, { useState } from 'react'
import { Container } from './styles'
import AmCharts from "@amcharts/amcharts3-react";
import { Pill } from '../../../shared_components'
import { useWindowSize } from '../../../hooks'
import t from '../../../config/translate'

function BarGraph({ data, valueY, categoryX, setRange, ranges, id, title, selectedRange, subTitle }) {

    const [expanded, setExpanded] = useState(true)
    const [fullScreen, setFullScreen] = useState(false)
    const windowSize = useWindowSize()

    const config = {
        "type": "serial",
        "theme": "light",
        "dataProvider": data,
        "gridAboveGraphs": false,
        "startDuration": 1,
        "graphs": [{
            "balloonText": "[[category]]: <b>[[value]]</b>",
            "fillAlphas": 0.8,
            "lineAlpha": 0.1,
            "type": "column",
            "valueField": valueY
        }],
        "chartCursor": {
            "categoryBalloonEnabled": true,
            "cursorAlpha": 0,
            "zoomable": false
        },
        "numberFormatter": {
            "precision": 2,
            "decimalSeparator": ",",
            "thousandsSeparator": ""
        },
        "valueAxes": [{
            "minimum": 0
        }],
        "categoryField": categoryX,
        "categoryAxis": {
            "gridPosition": "start",
            "gridAlpha": 0,
            "tickPosition": "start",
            "tickLength": 0
        },
        "export": {
            "enabled": true
        },
        'hideCredits': true,
    }
    
    const getGraphState = () => {
        let graphState = expanded ? 'bar_container_opened' : 'bar_container_closed'
        if (fullScreen) {
            graphState = 'full_screen_graph'
        }
        return graphState
    }


    return (
        <Container>
            <div className={getGraphState()}>
                <div className='bar_title_container'>
                    <div className='container_line'>
                        <div className='bar_title'>
                            <h5>{title}</h5>
                            {subTitle && <p>{subTitle}</p>}
                        </div>
                        <div className='bar_title_options'>
                            {windowSize.width > 1450 && <div className='bar_pill_container'>
                                {ranges.map((r, key) =>
                                    <Pill selected={selectedRange === r.value} key={key} text={r.name} onClick={() => setRange(r.value)} />
                                )}

                            </div>}


                            <div className='bar_graph_arrow_down' onClick={() => { setFullScreen(!fullScreen); setExpanded(true) }}>
                                <ion-icon name='resize-outline' />
                            </div>
                            {!fullScreen &&
                                <div className='bar_graph_arrow_down' onClick={() => setExpanded(!expanded)}>
                                    <ion-icon name='chevron-down-outline' />
                                </div>
                            }

                        </div>
                    </div>

                    {windowSize.width <= 1450 && <div className='bar_pill_container'>
                        {ranges.map((r, key) =>
                            <Pill selected={selectedRange === r.value} key={key} text={r.name} onClick={() => setRange(r.value)} />
                        )}

                    </div>}

                </div>


                {data.length === 0 ?
                    <div className='bar_graph_container'>
                        {t('monitor_graph.no_data')}
                    </div>
                    :
                    <div className='bar_graph_container'>
                        <AmCharts.React style={{ width: "100%", height: data.length > 0 ? "400px" : '0px' }} options={config} />
                    </div>
                }

            </div>


        </Container>
    )
}

export default BarGraph