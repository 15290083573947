/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Nav, TopNav } from '../../components'
import { Container } from './styles'
import reportsApi from '../../../services/reports'
import t from "../../../config/translate"
import { Table } from '../../../shared_components'
import TEXT_INPUT from '../../../shared_components/text_input'
import Button from '../../../shared_components/white_button'
import { formatCurrency, formatCurrencyWithoutSymbol, formatQuantity } from '../../../utils/formatters'

function ReportSearchByIdSales() {
	const CompanyID = useSelector(state => state.auth.authedStore.CompanyID)
	const whitelabel_name = useSelector(state => state.theme.whitelabel_name)
	const pageTitle = 'Consulta de Venda - Por ID'

	const [totalRowsSale, setTotalSaleRows] = useState(100)
	const [totalRowsPay, setTotalPayRows] = useState(100)
	const [perPageOptionsSale, setPerPageOptionsSale] = useState([100])
	const [perPageOptionsPay, setPerPageOptionsPay] = useState([100])
	const [loading, setLoading] = useState(false)

	const [identificador, setIdentificador] = useState('')
	const [sales, setSales] = useState([])
	const [pay, setPay] = useState([])

	const getSales = () => {
		setLoading(true)
		reportsApi.getSearchByIdSale(CompanyID, identificador)
			.then(rst => {
				setTotalSaleRows(rst.data.sales.length)
				setPerPageOptionsSale([rst.data.sales.length])
				let total = 0
				rst.data.sales.forEach(s => { total += s.AmntTotal })
				rst.data.sales.push({
					ProductName: "Total",
					Quantity: '',
					PriceAmount: '',
					AmntTotal: total,
					Unidade: ''
				})
				setSales(rst.data.sales)

				setTotalPayRows(rst.data.payment.length)
				setPerPageOptionsPay([rst.data.payment.length])
				let totalPago = 0
				rst.data.payment.forEach(p => { totalPago += p.PaidAmount })

				rst.data.payment.push({
					NameBase: 'Total Pago',
					PaidAmount: totalPago
				})

				rst.data.payment.push({
					NameBase: 'Em Aberto',
					PaidAmount: total - totalPago
				})

				setPay(rst.data.payment)


				setLoading(false)
			})
			.catch(() => { setLoading(false) })
	}

	const createRow = (row, field) => (
		<div className="table_row">{row[field]}</div>
	)

	const createRowCurrency = (row, field) => (
		<div className="table_row">{formatCurrencyWithoutSymbol(row[field])}</div>
	)

	const createRowQtd = (row, field) => (
		<div className="table_row">{formatQuantity(row[field], row['Unidade'])}</div>
	)

	const saleColums = React.useMemo(
		() => [
			{
				name: <div className="table_header">Produto</div>,
				selector: (row) => row["ProductName"],
				cell: (row) => createRow(row, "ProductName"),
				sortable: true,
			},
			{
				name: <div className="table_header">QTD</div>,
				selector: (row) => row["Quantity"],
				cell: (row) => createRowQtd(row, "Quantity"),
				sortable: true,
			},
			{
				name: <div className="table_header">Unitário</div>,
				selector: (row) => row["PriceAmount"],
				cell: (row) => createRowCurrency(row, "PriceAmount"),
				sortable: true,
			},
			{
				name: <div className="table_header">Total</div>,
				selector: (row) => row["AmntTotal"],
				cell: (row) => createRowCurrency(row, "AmntTotal"),
				sortable: true,
			}
		],
		[]
	)

	const payColums = React.useMemo(
		() => [
			{
				name: <div className="table_header">Forma de Pagamento	</div>,
				selector: (row) => row["NameBase"],
				cell: (row) => createRow(row, "NameBase"),
				sortable: true,
			},
			{
				name: <div className="table_header">Total</div>,
				selector: (row) => row["PaidAmount"],
				cell: (row) => createRowCurrency(row, "PaidAmount"),
				sortable: true,
			}
		],
		[]
	)

	const search = {
		show: true,
		searchPlaceHolder: t("search"),
		searchButtonClearText: t("clear"),
	}

	const handleTextInput = (e) => { setIdentificador(e) }

	return (
		<Container>
			<Helmet><title>{pageTitle} | {whitelabel_name}</title></Helmet>
			<Nav />
			<div className='content_container'>
				<TopNav pageTitle={pageTitle} />
				<div className="form_container">
					<div className="table_search_container">
						<TEXT_INPUT className='textBuscar' placeholder='Identificador' onChange={handleTextInput}></TEXT_INPUT>
						<Button className='buttonBuscar' text='Buscar' onClick={getSales} />
					</div>
					<hr></hr>

					<Table
						data={sales}
						colums={saleColums}
						title={'Items'}
						search={search}
						paginationServer={false}
						totalRows={totalRowsSale}
						rowPerPage={totalRowsSale}
						perPageOptions={perPageOptionsSale}
						loading={loading}
					/>
					<hr></hr>
					<div>
						<Table
							data={pay}
							colums={payColums}
							title={'Pagamento'}
							search={search}
							paginationServer={false}
							totalRows={totalRowsPay}
							rowPerPage={totalRowsPay}
							perPageOptions={perPageOptionsPay}
							loading={loading}
						/>
					</div>
				</div>
			</div>
		</Container>
	)
}

export default ReportSearchByIdSales