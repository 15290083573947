export const units = () => {
    
    let result  = [
         {
            "UnitTypeID":"55550E77-10D1-40DA-A067-075CB2124ACC",
            "Name":"Un",
            "Description":""
         },
        {
            "UnitTypeID":"55550E77-10D1-40DA-A067-075CB2EDDD85",
            "Name":"Cx",
            "Description":""
         },
         {
            "UnitTypeID":"70FC0030-56DC-4316-AAF5-5B4A106FBE93",
            "Name":"In",
            "Description":"Ingresso"
         },
         {
            "UnitTypeID":"55550E77-10D1-40DA-A067-075CB2ACCE25",
            "Name":"Kg",
            "Description":"Peso Variável"
         },
         {
            "UnitTypeID":"55550E77-10D1-40DA-A067-075CB2EEEA29",
            "Name":"Lt",
            "Description":""
         },
         {
            "UnitTypeID":"78866486-AC5D-472A-9C2D-D9A8454FE79C",
            "Name":"M2",
            "Description":""
         },
         {
            "UnitTypeID":"34088DE9-2DE6-42B5-AAB1-CADB9AA226FB",
            "Name":"M3",
            "Description":""
         },
         {
            "UnitTypeID":"FB3BFE14-7D94-4043-BC76-F198D00BE34A",
            "Name":"Pc",
            "Description":""
         },
         
         {
            "UnitTypeID":"D46DAEDE-CDC7-4C2D-889F-66E2BB7CE170",
            "Name":"Vq",
            "Description":"Valor e Qt Variáveis"
         },
         {
            "UnitTypeID":"71F5C365-95D4-4B8C-B648-5CE0A2DA297E",
            "Name":"VR",
            "Description":"Valor Variável"
         }
    ]

   //  return result.sort(function (a, b) {
   //      if (a['Name'] < b['Name']) {
   //          return -1;
   //      }
   //      if (b['Name'] < a['Name']) {
   //          return 1;
   //      }
   //      return 0;
   //  })
   return result;
}

export const status = {
   off: "ABCDEABC-ABCD-ABCD-ABCD-ABCED1457822",
   on: 'abcdeabc-abcd-abcd-abcd-abced1758966'
}