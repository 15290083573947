import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    background: var(--color-background);
    font-family: var(--font-family-primary);
    
    .content_container{
        height: 100vh;
        overflow: auto;
        flex: 1;
    }

    @media screen and (max-width: 800px) {
        .react-bootstrap-table table {
            display: block;
            overflow-x: auto;
        
        }
    }

    .table_container {
        background: var(--color-light);
        padding: 20px;
        margin: 0 30px 30px 30px;
        border-radius: var(--border-radius-medium);
        box-shadow: var(--box-shadow-card);
        overflow: visible;
    }
    .table_title {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        h2 {
            font-size: 18px;
            margin: 0;
        }
    }

    .header_filter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            margin-bottom: 0;
        }
    }
    .table_add_container {
        display: flex;
        align-items: center;
        width: 50%;
        min-width: 200px;
        button {
            max-width: 200px;
        }
    }
    .table_search_container {
        display: flex;
        align-items: center;
        width: 50%;
        label {
            min-width: 60%;
            margin: 0 20px;
        }


        button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            width: 40%;
            min-width: 100px;
            outline: none;
            border: 0px;
            background: var(--color-primary);
            color: var(--color-light);
            text-transform: uppercase;
            font-weight: 600;
            border-radius: var(--border-radius-small);
            
            &:hover {
                cursor: pointer;
                opacity: 0.8;
                transition: 0.3s;
            }
        
            &:active {
                transition: 0.3s;
                opacity: 0.6;
            }
        }

        @media screen and (max-width: 900px) {
            flex-direction: column;
            align-items: flex-end;
            flex-wrap: wrap;
            button {
                max-width: 140px;
            }
            label {
                margin: 20px 0 10px;
            }
        }
    }

    .group_table {
        display: flex;
        flex-direction: column;
        justify-content: center;

        input {
            height: 40px;
        }
    }



    .dialog_input_container {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        button {
            margin: 0 0 20px;
        }

        .column {
            flex: 1;
            min-width: 300px;
            max-width: 45%;
        }
    }

    .image_selector {
        display: flex;
        margin-bottom: 20px;
        p {
            margin-left: 20px;
        }
    }
`