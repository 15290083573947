import React from 'react'
import { Dialog, PhotoInput, WhiteButton, Button } from '../../../shared_components'
import t from '../../../config/translate'

class TableEditImage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            preview: '',
        }
    }

    componentDidMount() {
        this.setPreview(this.props.value)
    }

    setPreview = (val) => {
        this.setState(({ preview: val }))
    }


    render() {
        const { onUpdate, value } = this.props;
        const { preview } = this.state;
        return (
            <Dialog setOpen={() => onUpdate(value)}
                Children={(
                    <div>
                        <div className='dialog_title_container'>
                            <WhiteButton className='close_button'
                                type='button'
                                onClick={() => onUpdate(value)}
                                icon={'close-outline'} />
                            <h3 className='dialog_title'>{t('table_edit_image.edit_image')}</h3>
                        </div>

                        <div className='dialog_content_container'>
                            <PhotoInput textStyles={{marginLeft: 20}} preview={preview} setPreview={this.setPreview} />
                            <Button onClick={() => onUpdate(preview)} text={'Salvar imagem'}/>
                        </div>
           
                    </div>
                )} />
        )
    }

}


export default TableEditImage