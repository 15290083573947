export const googleMaps = (callback) => {
	const existingScript = document.getElementById('googleMaps')
	if (!existingScript) {
		const script = document.createElement('script')
		const mapsKEY = 'AIzaSyBqdItRNISZXJqGNEpy6JzM0XMYz8shFmY'
		script.src = `https://maps.googleapis.com/maps/api/js?key=${mapsKEY}`
		script.id = 'googleMaps'
		document.body.appendChild(script)
		script.onload = () => {
			if (callback) callback()
		}
	}
	if (existingScript && callback) callback()
}