import styled from 'styled-components'

export const Container = styled.div`
    min-height: 90px;
    width: 100%;
    padding: 0 28px;
    font-family: var(--font-family-primary);
    display: flex;
    align-items: center;
    justify-content: space-between;


    ion-icon {
        cursor: pointer;
        &:active {
            opacity: 0.7;
            transition: 0.3s;
        }
    }

    
    .top_nav_title {
        display: flex;
        align-items: center;
        ion-icon {
            color: var(--color-primary);
            font-size: 28px;
            margin-right: 20px;
        }
        h1 {
            margin: 0;
            font-weight: 500;
            font-size: 120%;
        }
    }

    .top_nav_items {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .icon_button {
        font-size: 28px;
        cursor: pointer;
        color: var(--color-primary);
        display: flex;
        margin-left: 20px;
        align-items: center;

    }
`