import React from 'react'
import { Container } from './style'

export default function PrintFicha({ printValues, style }) {
    return (
        <Container style={{...style}}>
            <p>==============================</p>
            <img src={"data:image/png;base64," + printValues.logo} />
            <div className='productName'>VIA PRODUÇÃO</div>
            <div className='productName'>PEDIDO 2109</div>
            <p>==============================</p>
            <p className={'leftAligned'}> QT   ITEM                    </p>
            <p>==============================</p>
            <p className={'leftAligned'}> 01   HAMBURGUER              </p>
            <p className={'leftAligned'}> 01   HOT DOG                 </p>
            <p className={'leftAligned'}> 01   REFRIGERANTE            </p>
            <p>------------  1/2  ------------</p>

            <p>21/09/2021   14:48:27   V1.8.8</p>
            <p>00.000.000/0001-00    DINHEIRO</p>
        </Container>
    )
}