import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Toast } from '../../shared_components'
import TemplateRenderer from '../../templates/index'
import { store } from '../store'
import userAPI from '../../services/user'
import { handleUserPermissions } from '../store/actions/auth'
import { useSelector } from 'react-redux'

function PrivateRoute({ componentName, referrer, paramsList, requiredPermissions = [], ...rest }) {

	const { auth } = store.getState()
	const userPermission = useSelector(state => state.auth.permissions)

	const hasPermission = () => requiredPermissions.find(requiredPermission => requiredPermission === userPermission.role) ? true : false
	const isLogged = () => auth.tokens ? true : false
	const getParameters = (parametersList) => {
		let auxParams = ''
		for (let item in paramsList) auxParams += parametersList[paramsList[item]] + '/'
		return auxParams
	}

	let ret = (<Route {...rest} render={(props) =>
		isLogged()
			? <TemplateRenderer componentName={componentName} {...props} />
			: <Redirect to={{ pathname: "/login", state: { referrer: paramsList ? `/${referrer}/${getParameters(props.match.params)}` : `/${referrer}` } }} />
	} />)

	if (requiredPermissions.length > 0) {
		if (!hasPermission()) {
			Toast('Atenção', 'Desculpe, Não possui permissão para acessar esta area', 'info')
			ret = (<Route {...rest} render={(props) =>
				isLogged()
					? <Redirect to={{ pathname: "/monitor", state: { referrer: paramsList ? `/${referrer}/${getParameters(props.match.params)}` : `/${referrer}` } }} />
					: <Redirect to={{ pathname: "/login", state: { referrer: paramsList ? `/${referrer}/${getParameters(props.match.params)}` : `/${referrer}` } }} />
			} />)
		}
	}
	return ret
}

function LoggedRoute({ componentName, referrer, template = 'main', ...rest }) {
	const { auth } = store.getState()
	return (
		<Route {...rest} render={(props) =>
			!auth.tokens
				? <TemplateRenderer template={template} componentName={componentName} {...props} />
				: <Redirect to={{ pathname: '/', }} />
		} />
	)
}

function Routes({ page_templates }) {
	useEffect(() => {
		let componentMounted = true
		const { auth } = store.getState()
		if (auth && auth.tokens && auth.tokens.token) {
			userAPI.getRole(auth.tokens.token)
				.then(res => res && res.data)
				.then(res => componentMounted && store.dispatch(handleUserPermissions(res)))
				.catch((err) => {
					// console.log('Erro dispatch role', err)
				})
		}
		return () => componentMounted = false
	}, [])

	return (
		<Switch>
			{/* public */}
			{/* White Label */}
			<Route path="/4cad9e1c-a7a1-48d3-9efa-5dba69733aeb/register" component={() => <LoggedRoute componentName='WhiteLabelForm' referrer={'/4cad9e1c-a7a1-48d3-9efa-5dba69733aeb/register'} />} />

			<Route exact path='/login' component={() => <LoggedRoute template={page_templates.login} componentName='Login' />} />
			<Route exact path='/recoverPassword' component={() => <LoggedRoute template={page_templates.recoverPasswordEmail} componentName='RecoverPasswordEmail' />} />
			<Route exact path='/changePassword/:token' component={() => <LoggedRoute template={page_templates.recoverPassword} componentName='RecoverPassword' />} />
			<Route exact path='/redirectLogin/:token' component={() => <LoggedRoute componentName='RedirectLogin' referrer={'redirectLogin'} />} />

			{/* inserts */}
			<Route exact path='/cadastro/produtos' component={() => <PrivateRoute componentName='ProductRegister' referrer={'cadastro/produtos'} />} />
			<Route exact path='/cadastro/categorias' component={() => <PrivateRoute componentName='GroupRegister' referrer={'cadastro/categorias'} />} />

			{/* help */}
			<Route exact path='/help' component={() => <PrivateRoute componentName='Help' referrer={'help'} />} />

			{/* cardapio digital */}
			<Route exact path='/qr_code' component={() => <PrivateRoute componentName='QrConfig' requiredPermissions={['ADM', 'GER', 'GERRD', 'MST']} referrer={'qr_code'} />} />

			{/* report */}
			<Route exact path='/report/sales_per_group' component={() => <PrivateRoute componentName='ReportSalesPerGroup' referrer={'report/sales_per_group'} />} />
			<Route exact path='/report/sales_per_day' component={() => <PrivateRoute componentName='ReportSalesPerDay' referrer={'report/sales_per_day'} />} />
			<Route exact path='/report/closing_register' component={() => <PrivateRoute componentName='ReportClosingRegister' referrer={'report/closing_register'} />} />
			<Route exact path='/report/products_by_pos_billed' component={() => <PrivateRoute componentName='ReportBillingProductsByPOSBilled' referrer={'report/products_by_pos_billed'} />} />
			<Route exact path='/report/products_by_pos_courtesy' component={() => <PrivateRoute componentName='ReportBillingProductsByPOSCourtesy' referrer={'report/products_by_pos_courtesy'} />} />
			<Route exact path='/report/dowloader_products_per_user' component={() => <PrivateRoute componentName='ReportBillingDowloaderProductsPerUser' referrer={'report/dowloader_products_per_user'} />} />
			<Route exact path='/report/pos_ranking' component={() => <PrivateRoute componentName='ReportBillingPOSRanking' referrer={'report/pos_ranking'} />} />
			<Route exact path='/report/sales_detailed' component={() => <PrivateRoute componentName='ReportDetailedSales' referrer={'report/sales_detailed'} />} />
			<Route exact path='/report/sales_canceled' component={() => <PrivateRoute componentName='reportCanceledSales' referrer={'report/sales_canceled'} />} />
			<Route exact path='/report/cashless_extrato_online' component={() => <PrivateRoute componentName='ReportCashlessExtratoOnline' referrer={'report/cashless_extrato_online'} />} />
			<Route exact path='/report/cashless_listagem_credito' component={() => <PrivateRoute componentName='ReportCashlessListagemCredito' referrer={'report/cashless_listagem_credito'} />} />

			<Route exact path='/report/sales_barcode' component={() => <PrivateRoute componentName='ReportBarcodeSales' referrer={'report/sales_barcode'} />} />
			<Route exact path='/report/sales_identificador' component={() => <PrivateRoute componentName='ReportListSalesIdentificador' referrer={'report/sales_identificador'} />} />
			<Route exact path='/report/sales_payamentType' component={() => <PrivateRoute componentName='ReportPayamentTypeSale' referrer={'report/sales_payamentType'} />} />
			<Route exact path='/report/sales_byId' component={() => <PrivateRoute componentName='ReportSearchByIdSales' referrer={'report/sales_byId'} />} />

			{/* estoque */}
			<Route path='/estoque/report' component={() => <PrivateRoute componentName='Report' referrer={'/estoque/report'} />} />
			<Route path='/estoque/insert' component={() => <PrivateRoute componentName='Insert' referrer={'/estoque/insert'} />} />

			{/* config */}
			<Route exact path='/config/print' component={() => <PrivateRoute componentName='PrintConfig' requiredPermissions={['ADM', 'GER', 'GERRD', 'MST']} referrer={'config/print'} />} />
			<Route exact path='/config/users' component={() => <PrivateRoute componentName='UserConfig' requiredPermissions={['ADM', 'GER', 'GERRD', 'MST']} referrer={'config/users'} />} />
			<Route path='/config/system' component={() => <PrivateRoute componentName='SystemConfig' requiredPermissions={['ADM', 'GER', 'GERRD', 'MST']} referrer={'config/system'} />} />
			<Route path='/config/nfceTef' component={() => <PrivateRoute componentName='NfceTefconfig' requiredPermissions={['ADM', 'GER', 'GERRD', 'MST']} referrer={'config/nfceTef'} />} />
			<Route exact path='/config/terminals' component={() => <PrivateRoute componentName='TerminalConfig' requiredPermissions={['ADM', 'GER', 'GERRD', 'MST']} referrer={'config/terminals'} />} />
			<Route exact path='/config/terminalsJustTable' component={() => <PrivateRoute componentName='TerminalConfigJustTable' requiredPermissions={['ADM', 'GER', 'GERRD', 'MST']} referrer={'config/terminalsJustTable'} />} />
			<Route exact path='/config/fiscalConfig' component={() => <PrivateRoute componentName='FiscalConfig' requiredPermissions={['ADM', 'GER', 'GERRD', 'MST']} referrer={'config/fiscalConfig'} />} />



			{/* monitor */}
			<Route path='/' component={() => <PrivateRoute componentName='Monitor' referrer={''} />} />
			<Route path='/monitor' component={() => <PrivateRoute componentName='Monitor' referrer={'/monitor'} />} />

		</Switch>
	)
}

export default Routes