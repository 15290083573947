import React from 'react'
import { GraphContainer } from '../../components'
import t from '../../../config/translate'
import BootstrapTable from 'react-bootstrap-table-next'
import { formatCurrencyWithoutSymbol } from '../../../utils/formatters'

function ProductRevenue(props) {

	const { title, setRange, ranges, selectedRange, data } = props
	const columns = [

		{
			headerStyle: () => {
				return { verticalAlign: 'initial', width: '60%' }
			},
			formatter: (editorProps, value, row, column, rowIndex, columnIndex) => {
				return (
					<div className='product_revenue_name_image'>
						{value.ImageBase64 ?
							<img src={"data:image/png;base64," + value.ImageBase64} alt={value.ProductID} />
							: <div style={{ width: 42 }} />}
						<p><b>{value.ProductName}</b></p>

					</div>

				)
			},
			dataField: 'ProductName',
			text: t('product_revenue.product')
		},
		{
			headerStyle: () => {
				return { verticalAlign: 'initial', textAlign: 'right' }
			},
			formatter: (editorProps, value, row, column, rowIndex, columnIndex) => {
				return (
					<p style={{ width: '100%', textAlign: 'right' }}>{(value.QtItem)}</p>
				)
			},
			dataField: 'QtItem',
			text: t('product_revenue.amount')
		},
		{
			headerStyle: () => {
				return { verticalAlign: 'initial', textAlign: 'right' }
			},
			formatter: (editorProps, value, row, column, rowIndex, columnIndex) => {
				return (
					<p style={{ width: '100%', textAlign: 'right' }}>{formatCurrencyWithoutSymbol(value.AmntTotal)}</p>
				)
			},
			dataField: 'AmntTotal',
			text: t('product_revenue.value') + ' (R$)'
		},
	]


	return (
		<GraphContainer
			title={title}
			setRange={setRange}
			ranges={ranges}
			selectedRange={selectedRange}
			subTitle={t('product_revenue.revenue')}
			Children={
				(
					<div className='bar_graph_container'>
						<div className='graph_row_items'>
							<div>
								<p>{t('product_revenue.sales')}</p>
								<h5>{data.totalSales || 0}</h5>
							</div>
							<div>
								<p>{t('product_revenue.revenue')} (R$)</p>
								<h5>{data.totalRevenue ? formatCurrencyWithoutSymbol(data.totalRevenue) : 0}</h5>
							</div>
						</div>

						{data ?
							<BootstrapTable
								hover
								keyField="ProductID"
								data={data.products || []}
								columns={columns}
							/>
							: <p className='bar_graph_container'>{t('monitor_graph.no_sales')}</p>
						}


					</div>
				)
			}
		/>
	)
}

export default ProductRevenue