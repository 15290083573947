import React, { useEffect, useState } from "react"
import { Container } from "./styles"
import { Nav, TopNav } from "../../components"
import { Table, DateRange, Toast } from "../../../shared_components"
import { connect } from "react-redux"
import Helmet from "react-helmet"
import t from "../../../config/translate"
import { withRouter } from "react-router-dom"
import reportAPI from "../../../services/reports"
import { formatReportDetailedSales } from "../../../utils/formatters"
import moment from "moment"
import { formatQuantity, formatCurrency } from "../../../utils/formatters"

function ReportDetailedSales({ companyID, whitelabel_name }) {
	const [data, setData] = useState([])
	const [initialDate, setInitialDate] = useState(moment())
	const [finalDate, setFinalDate] = useState(moment())
	const [totalRows, setTotalRows] = useState(10)
	const [perPageOptions, setPerPageOptions] = useState([5, 10, 15, 20])
	const [loading, setLoading] = useState(false)
	const [perPage, setPerPage] = useState(10)

	const fetchDetailedSales = (page, range) => {
		setLoading(true)
		const initialDateFormated = moment(initialDate).format("YYYY-MM-DD")
		const finalDateFormated = moment(finalDate).format("YYYY-MM-DD")

		reportAPI.getDetailedSales(companyID, initialDateFormated, finalDateFormated, page, range)
			.then(rst => {
				setLoading(false)
				if (rst.data.length > 0) setTotalRows(rst.data[0].totalRows)
				setPerPageOptions([5, 10, 15, 20, 40, 60, 100])
				setData(formatReportDetailedSales(rst.data))
			})
			.catch((err) => {
				setLoading(false)
				Toast('Atenção', 'Tente novamente mais tarde!', 'info')
				console.error("[EstoqueReport]", err.message)
			})
	}

	useEffect(() => {
		let componentMounted = true
		if (componentMounted) fetchDetailedSales(1, perPage)
	}, [])

	const updateRange = async () => {
		fetchDetailedSales(1, perPage)
	}

	const handlePageChange = (page) => {
		fetchDetailedSales(page, perPage)
	}
	const handlePerRowsChange = async (newPerPage, page) => {
		fetchDetailedSales(page, newPerPage)
		setPerPage(newPerPage)
	}

	const search = {
		show: true,
		searchPlaceHolder: t("search"),
		searchButtonClearText: t("clear"),
	}

	const createRow = (row, field) => {
		if (row['bold']) return (<div className="table_row"><b>{row[field]}</b></div>)
		return (<div className="table_row">{row[field]}</div>)
	}

	const createRowQtd = (row, field) => {
		if (row['bold']) return createRow(row, field)
		if (row[field] === '') return createRow(row, field)
		let qtd = parseFloat(row[field])
		return (<div className="table_row">{formatQuantity(qtd, row.ProductUnitType)}</div>)
	}

	const createRowCurrency = (row, field) => {
		if (row['bold']) return createRow(row, field)
		if (row[field] === '') return createRow(row, field)
		let qtd = parseFloat(row[field])
		return (<div className="table_row">{formatCurrency(qtd, row.ProductUnitType).replace('R$', '')}</div>)
	}

	const colums = React.useMemo(
		() => [
			{
				name: <div className="table_header">Número</div>,
				selector: (row) => row["PropertyNumber"],
				cell: (row) => createRow(row, "PropertyNumber"),
				sortable: false,
			},
			{
				name: <div className="table_header">Data</div>,
				selector: (row) => row["Data"],
				cell: (row) => createRow(row, "Data"),
				sortable: false,
			},
			{
				name: <div className="table_header">Hora</div>,
				selector: (row) => row["Hora"],
				cell: (row) => createRow(row, "Hora"),
				sortable: false,
			},
			{
				name: <div className="table_header">Produto</div>,
				selector: (row) => row["ProductName"],
				cell: (row) => createRow(row, "ProductName"),
				sortable: false,
			},
			{
				name: <div className="table_header">Un</div>,
				selector: (row) => row["ProductUnitType"],
				cell: (row) => createRow(row, "ProductUnitType"),
				sortable: false,
			},
			{
				name: <div className="table_header">Qtd</div>,
				selector: (row) => row["ProductAmount"],
				cell: (row) => createRowQtd(row, "ProductAmount"),
				sortable: false,
			},
			{
				name: <div className="table_header">Unitário (R$)</div>,
				selector: (row) => row["ProductPrice"],
				cell: (row) => createRowCurrency(row, "ProductPrice"),
				sortable: false,
			},
			{
				name: <div className="table_header">Total (R$)</div>,
				selector: (row) => row["Total"],
				cell: (row) => createRowCurrency(row, "Total"),
				sortable: false,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)


	return (
		<Container>
			<Helmet>
				<title>
					{t("report_title")} | {whitelabel_name}
				</title>
			</Helmet>

			<Nav />

			<div className="content_container">
				<TopNav pageTitle={t("report_title")} />
				<div className="table_container">
					<div className="table_search_container">
						<DateRange
							initialDate={initialDate}
							finalDate={finalDate}
							setFinalDate={setFinalDate}
							setInitialDate={setInitialDate}
							submit={updateRange}
							mindate={moment().subtract(60, 'days')}
						/>
					</div>

					<Table
						data={data}
						colums={colums}
						title={t("report_detailed_sales.title")}
						search={search}
						paginationServer={true}
						onChangePage={handlePageChange}
						onChangeRowsPerPage={handlePerRowsChange}
						totalRows={totalRows}
						perPageOptions={perPageOptions}
						loading={loading}
					/>
				</div>
			</div>
		</Container>
	)
}

function mapStateToProps({ auth, theme }) {
	return {
		companyID: auth.authedStore ? auth.authedStore.CompanyID : "",
		companyGroupID: auth.authedStore ? auth.authedStore.CompanyGroupID : "",
		whitelabel_name: theme.whitelabel_name || "POS CONTROLE",
	}
}

export default withRouter(connect(mapStateToProps)(ReportDetailedSales))
