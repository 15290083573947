import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { CONTAINER } from "./styles";

function DROPDOWN({
  isLastElement,
  itemKey,
  items,
  title,
  styles,
  placeholder,
  id,
  onChange,
  value,
  auxKey,
  inputChange = () => {},
  showIconArrowDown = true,
}) {
  const [open, setOpen] = useState(false);
  const [itemsToUse, setItemsToUse] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setItemsToUse(items);
  }, [items]);

  useEffect(() => {
    if (value === undefined) return;
    if (itemKey) setQuery(value[itemKey]);
    else setQuery(value);
  }, [itemKey, value]);

  const handleQuery = (val) => {
    const aux = items.filter((i) =>
      itemKey
        ? i[itemKey].toLowerCase().includes(val.toLowerCase())
        : i.toLowerCase().includes(val.toLowerCase())
    );
    setQuery(val);
    setItemsToUse(aux);
    if (inputChange) {
      setOpen(true);
      inputChange(val);
    }
  };

  const handleOpen = () => {
    document.getElementById(id) && document.getElementById(id).focus();
    setOpen(!open);
  };

  return (
    <CONTAINER style={{ ...styles }}>
      {title && <h5>{title}</h5>}
      <div className="dropdown_container">
        <div className="dropdown_title" onClick={() => handleOpen()}>
          <input
            onChange={(e) => handleQuery(e.target.value)}
            placeholder={placeholder}
            id={id}
            value={query}
          />
          {showIconArrowDown && (
            <ion-icon name="chevron-down-outline"></ion-icon>
          )}
        </div>
        {!itemKey && itemsToUse && itemsToUse.length > 0 && (
          <div
            className={
              open
                ? "dropdown_item_container"
                : "dropdown_item_container_closed"
            }
          >
            {itemsToUse.map((i, key) => (
              <div
                key={key}
                onClick={() => {
                  onChange(i);
                  setOpen(!open);
                }}
                className="dropdown_item"
              >
                {i}
              </div>
            ))}
          </div>
        )}
        {itemKey && itemsToUse && itemsToUse.length > 0 && (
          <div
            className={
              open
                ? "dropdown_item_container" +
                  (isLastElement ? "_last_item" : "")
                : "dropdown_item_container_closed"
            }
          >
            {itemsToUse.map((i, key) => (
              <div
                key={key}
                onClick={() => {
                  onChange(i);
                  setOpen(!open);
                }}
                className="dropdown_item"
              >
                <p>
                  {" "}
                  {i[itemKey]}{" "}
                  {auxKey && i[auxKey].length > 0 && (
                    <Fragment>
                      <br />
                      <span>{i[auxKey]}</span>
                    </Fragment>
                  )}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </CONTAINER>
  );
}

export default DROPDOWN;
