/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'
const API_KEY = "@u7Yt!9"

const instance = axios.create({ baseURL: 'https://app-web-01-apiweb.azurewebsites.net/api/' })
instance.interceptors.response.use((response) => response, (error) => Promise.reject(error.response))

export default {
	async login(user) {
		if (process.env.NODE_ENV === 'development') user.url = 'app.tellpdv.com.br' // PARA UTILIZAR USUARIO: TW2 ADM SENHA: 123456
		const res = axios.post(`${process.env.REACT_APP_AUTH_SERVER}/api/v1/authentication/auth`, user)
		return res
	},
	async refresh(tokenRefresh) {
		const res = axios.get(`${process.env.REACT_APP_AUTH_SERVER}/api/v1/authentication/refresh`, { headers: { token: `${tokenRefresh}`, "Content-type": "application/json" } })
		return res
	},
	async validate(token) {
		const res = axios.get(`${process.env.REACT_APP_AUTH_SERVER}/api/v1/authentication/validated`, { headers: { token: `${token}`, "Content-type": "application/json" } })
		return res
	},
	recoverPass(email, s) {
		return axios.post(`https://pc-app-01.azurewebsites.net/api/v3/req_mail_admv2/?apiKey=${API_KEY}`, {
			"email": email,
			"s": s,
		})
	},
	updatePass(newPassword, passwordToken, s) {
		return axios.post(`https://pc-app-01.azurewebsites.net/api/v3/req_mail_admv2_novasenha/?apiKey=${API_KEY}`, {
			"Npassword": newPassword,
			"set_pass": passwordToken,
			"s": s,
		})
	}
}