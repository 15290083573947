import React from "react"
import { Container } from "./styles"
import { Nav, TopNav } from "../../components"
import { connect } from "react-redux"
import Helmet from "react-helmet"
import t from "../../../config/translate"
import { withRouter } from "react-router-dom"
import { SaleItems } from "./Painels/SaleItems"
import { TktMedio } from "./Painels/TktMedio"
import { PaymentType } from "./Painels/PaymentType"
import { SaleItemPos } from "./Painels/SaleItemPos"
import { SaleItemPosGroup } from "./Painels/SaleItemPosGroup"
import { PosImLocal } from "./Painels/PosImLocal"

function Monitor({ companyID, whitelabel_name, menu_keys }) {
	const ranges = [
		{
			name: t("monitor.range_today"),
			value: "bynow",
		},
		{
			name: `10 ${t("monitor.range_days")}`,
			value: "bytendays",
		},
		{
			name: `30 ${t("monitor.range_days")}`,
			value: "bymonth",
		},
		{
			name: t("monitor.range_year"),
			value: "byyear",
		},
	]

	return (
		<Container>
			<Helmet>
				<title>
					{menu_keys.monitor || t("monitor.title")} | {whitelabel_name}
				</title>
			</Helmet>

			<Nav />

			<div className="content_container">
				<TopNav pageTitle={menu_keys.monitor || t("monitor.title")} />
				<div className="row">
					<SaleItems companyID={companyID} ranges={ranges} />

					<TktMedio companyID={companyID} ranges={ranges} />

					<PaymentType companyID={companyID} ranges={ranges} />

					<SaleItemPos companyID={companyID} ranges={ranges} />

					<SaleItemPosGroup companyID={companyID} />

					<PosImLocal companyID={companyID} />
				</div>
			</div>
		</Container>
	)
}

function mapStateToProps({ auth, theme }) {
	return {
		companyID: auth.authedStore ? auth.authedStore.CompanyID : "",
		// companyGroupID: auth.authedStore ? auth.authedStore.CompanyGroupID : "",
		// token: auth.tokens ? auth.tokens.token : "",
		whitelabel_name: theme.whitelabel_name || "POS CONTROLE",
		menu_keys: theme.menu_keys || {},
	}
}

export default withRouter(connect(mapStateToProps)(Monitor))
