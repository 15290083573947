/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import { Toggle, Button, Toast } from '../../../shared_components'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import AddGroupDialog from './add_group_dialog'
import groupsAPI from '../../../services/groups'
import t from '../../../config/translate'
import { TableEditImage, Filter } from '../../components'
import { connect } from 'react-redux'
import { handleLogout } from '../../../config/store/actions/auth'
import { status } from '../../../utils/constants'

const { SearchBar, ClearSearchButton } = Search;


function GroupTable({ data, companyID, companyGroupID, dispatch, setData, allData }) {
  const [selected, setSelected] = useState([])
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(0)
  const [openFilter, setOpenFilter] = useState(false)

  const statusHeader = (column, colIndex) => {
    return (
      <div className='header_filter'>
        <p>{column.text}</p>
        <Filter
          open={openFilter}
          setOpen={setOpenFilter}
          Children={(
            <div style={{ marginBottom: 0 }} >
              <div onClick={() => changeFilterStatus(0)} className='filter_radio_item dropdown_item'>
                <ion-icon name={`radio-button-${selectedStatus === 0 ? 'on' : 'off'}-outline`}></ion-icon>
                <p>{t('all')}</p>
              </div>
              <div onClick={() => changeFilterStatus(status.on)} className='filter_radio_item dropdown_item'>
                <ion-icon name={`radio-button-${selectedStatus === status.on ? 'on' : 'off'}-outline`}></ion-icon>
                <p>{t('active')}</p>
              </div>
              <div onClick={() => changeFilterStatus(status.off)} className='filter_radio_item dropdown_item'>
                <ion-icon name={`radio-button-${selectedStatus === status.off ? 'on' : 'off'}-outline`}></ion-icon>
                <p>{t('inactive')}</p>
              </div>
            </div>
          )} />
      </div>
    )
  }

  const changeFilterStatus = (v) => {
    setSelectedStatus(v)
    setOpenFilter(false)
    if (v !== 0) {
      setData(allData.filter((d) => d.StatusID.toUpperCase() === v.toUpperCase()))
    } else {
      setData(allData)
    }

  }

  const submitToggle = (id, currentStatus) => {
    let auxItems = [...data]
    let newStatus = currentStatus === status.off ? status.on : status.off

    groupsAPI.editGroup(companyGroupID, id, 'StatusID', newStatus)
      .then((res) => {
        auxItems = auxItems.map((i) => {
          if (i.ProductGroupID === id) {
            i.StatusID = res.status === 200 ? newStatus : currentStatus
          }
          return i
        })
        setData(auxItems)
        res.status === 200 ? Toast('', t('success.toggle_group'), 'success') : Toast('', t('group_register.error_status'), 'danger')
      }).catch(() => {
        Toast('', t('group_register.error_status'), 'danger')
      })

  }


  const selectOptions = [
    { value: status.on.toUpperCase(), label: 'Habilitados' },
    { value: status.off, label: 'Desabilitados' }
  ];

  const columns = [
    {
      dataField: 'StatusID',
      text: t('active') || "",

      headerStyle: (colum, colIndex) => {
        return { width: '90px', textAlign: 'left', verticalAlign: 'initial', overflow: 'visible' };
      },
      headerFormatter: statusHeader,
      editable: false,
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <Toggle state={value.StatusID !== status.off} submitToggle={() => submitToggle(value.ProductGroupID, value.StatusID)} />
      ),
    },
    {
      dataField: 'ImageGroupBase64',
      text: t('image') || "",
      headerStyle: (colum, colIndex) => {
        return { width: '70px', textAlign: 'center', verticalAlign: 'initial' };
      },
      formatter: (editorProps, value, row, column, rowIndex, columnIndex) => {
        if(value.ImageGroupBase64){
         return <img style={{ maxHeight: 50, maxWidth: 50 }} src={"data:image/png;base64," + value.ImageGroupBase64} />
        } 
        return <div/>
      },
      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <TableEditImage {...editorProps} value={value} />
      )
    }, {
      headerStyle: (colum, colIndex) => {
        return { verticalAlign: 'initial' };
      },
      sort: true,
      dataField: 'Name',
      text: t('name') || "",
      validator: (newValue, row, column) => {
        const nameRegex = /^[0-9a-zA-Z+,-_% ]+$/
        if(!nameRegex.test(newValue)) {
          return {
            valid: false,
            message: t('error.group_name_type')
          }
        }
        if (newValue.length > 20 || newValue.length < 1) {
          return {
            valid: false,
            message: t('error.group_name_length')
          };
        }
        return true;
      }
    }];


  // const changeMultiple = () => {
  //   let auxItems = [...data]
  //   let auxSelected = data.filter((p) => selected.includes(p.ProductGroupID))
  //   let selectedIds = auxSelected.map((i) => i.ProductGroupID)
  //   auxItems = auxItems.map((i) => {
  //     if (selectedIds.includes(i.ProductGroupID)) {
  //       i['name'] = 'Modificado'
  //     }
  //     return i
  //   })
  //   setData(auxItems)

  // }

  const beforeSaveCell = (oldValue, newValue, row, column, done) => {
    const itemId = row.ProductGroupID
    const whatToChange = column.dataField
    let auxItems = [...data]
    if (newValue) {
      auxItems = auxItems.map((i) => {
        if (i.ProductGroupID === itemId) {
          i[whatToChange] = newValue
        }
        return i
      })

      setTimeout(() => {
        groupsAPI.editGroup(companyGroupID, itemId, whatToChange, newValue)
          .then((res) => {
            if (res.status !== 200) {
              Toast('', `${t('group_register.error_updating_1')} ${whatToChange} ${t('group_register.error_updating_2')}`, 'danger')
              auxItems = auxItems.map((i) => {
                if (i.ProductGroupID === itemId) {
                  i[whatToChange] = oldValue
                }
                return i
              })
            }
            setData(auxItems)
          }).catch(() => {
            auxItems = auxItems.map((i) => {
              if (i.ProductGroupID === itemId) {
                i[whatToChange] = oldValue
              }
              return i
            })
            setData(auxItems)
            Toast('', `${t('group_register.error_updating_1')} ${whatToChange} ${t('group_register.error_updating_2')}`, 'danger')
          })
        done(true);
      }, 0);

    } else { done(false) }

    return { async: true };
  }

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected([...selected, row.ProductGroupID])

    } else {
      setSelected(selected.filter(x => x !== row.ProductGroupID))
    }
  }

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.ProductGroupID);
    if (isSelect) {
      setSelected(ids)
    } else {
      setSelected([])
    }
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    selected: selected,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };

  const testFun = (event) => {
    const allTds = Array.from(document.getElementsByTagName('td'))
    allTds.map((i, key) => i.tabIndex = key)
    const currentTab = document.getElementsByClassName('react-bootstrap-table-editing-cell')[0]

    if (currentTab) {
      const currentTabIndex = currentTab.tabIndex


      switch (event.code) {
        case "ArrowUp":
          document.activeElement.blur()
          setTimeout(() => {
            const currentElement = allTds[currentTabIndex - (columns.length)]
            if (currentElement) {
              currentElement.click()
            }

          }, 50)
          break;
        case "ArrowDown":
          document.activeElement.blur()
          setTimeout(() => {
            const currentElement = allTds[currentTabIndex + (columns.length)]
            if (currentElement) {
              currentElement.click()
            }

          }, 50)
          break;
        case "ArrowRight":
          document.activeElement.blur()
          setTimeout(() => {
            const currentElement = allTds[currentTabIndex + 1]
            if (currentElement) {
              currentElement.click()
            }
          }, 50)
          break
        case "ArrowLeft":
          document.activeElement.blur()
          setTimeout(() => {
            const currentElement = allTds[currentTabIndex - 1]
            if (currentElement) {
              currentElement.click()
            }
          }, 50)
          break
      }
    }
  }

  const addGroup = (Name, ImageGroupBase64) => {
    let error = false
    if(Name.length <= 2) {
      Toast('', t('group_register.no_name'), 'danger')
      error = true
    }

    if((!ImageGroupBase64 || ImageGroupBase64.length < 10) && !error) {
      Toast('', t('group_register.no_image'), 'danger')
      error = true
    }
    if(!error) {
      groupsAPI.createGroup(companyGroupID, status.on, Name, ImageGroupBase64).then((res) => {
        if (res.status === 201) {
          const responseGroup = res.data
          setData((g) => [{
            ProductGroupID: responseGroup.ProductGroupID,
            Name: responseGroup.Name,
            ImageGroupBase64: responseGroup.ImageGroupBase64,
            StatusID: responseGroup.StatusID
          }].concat(g))
          setShowAddDialog(false)
        } else {
          Toast('', t('group_register.error'), 'danger')
        }
      })
  
    }

  }

  return (
    <div onKeyDown={(e) => testFun(e)} className='table_container'>

      <ToolkitProvider
        keyField="ProductGroupID"
        data={data}
        columns={columns}
        search

      >
        {
          props => {
            return (
              <div className='group_table'>
                <div className='table_title'>
                  <div className='table_add_container'>
                    <Button onClick={() => setShowAddDialog(true)} text={t('group_register.add')} />
                  </div>
                  <div className='table_search_container'>
                    <SearchBar placeholder={t('search')} {...props.searchProps} />
                    <ClearSearchButton text={t('clear')} {...props.searchProps} />
                  </div>

                </div>

                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  cellEdit={cellEditFactory({ mode: 'click', blurToSave: true, beforeSaveCell })}
                  striped
                  hover
                  pagination={paginationFactory()}
                />
              </div>
            )
          }
        }
      </ToolkitProvider>
      {showAddDialog &&
        <AddGroupDialog addGroup={addGroup} setOpen={setShowAddDialog} />
      }
    </div>

  );

}

export default connect()(GroupTable);

