import styled from 'styled-components'

export const Container = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 16px;
    outline: none;
    border: 0px;
    background: rgba(0,0,0,0.1);
    color: gray;
    text-transform: uppercase;
    margin: 4px;
    font-weight: 600;
  
    border-radius: 40px;
    
    p {
        font-size: 12px;
        margin: 0;
        white-space: nowrap;
    }
    &:hover {
        cursor: pointer;
        opacity: 0.8;
        transition: 0.3s;
    }

    &:active {
        transition: 0.3s;
        opacity: 0.6;
    }

`