import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    background: var(--color-background);
    font-family: var(--font-family-primary);

	.add_container{
		display: flex;
        align-items: center;
        justify-content: flex-start;
		padding: 10px;
	}

	.send_container{
		display: flex;
        align-items: center;
        justify-content: flex-start;
		padding: 10px;
	}

    .table_row{
        font-size: 14px;
    }

    .table_header{
        font-weight: var(--font-weight-medium);
        font-size: 13px;
    }

    .content_container{
        height: 100vh;
        overflow: auto;
        flex: 1;
    }

    .form_container {
        background: var(--color-light);
        padding: 20px;
        margin: 0 30px 30px 30px;
        border-radius: var(--border-radius-medium);
        box-shadow: var(--box-shadow-card);
        overflow: visible;
    }

	 .table_search_container {
        display: flex;
        flex: 1;
		justify-content: flex-start;
		margin: 0px 0px 30px 0px;
    }

	.textBuscar{
		width: 520px;
	}
	.buttonBuscar {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        width: 100px;
		height: 42px;
        min-width: 60px;
        outline: none;
        border: 0px;
        background: var(--color-primary);
        color: var(--color-light);
        text-transform: uppercase;
        font-weight: 600;
        border-radius: var(--border-radius-small);
		margin-top: 3px;
		margin-left: 5px;
        &:hover {
            cursor: pointer;
            opacity: 0.8;
            transition: 0.3s;
        }
        &:active {
            transition: 0.3s;
            opacity: 0.6;
        }
    }
`
export const GroupHeaderTable = styled.thead