import React, { useEffect, useState } from "react"
import t from "../../../../config/translate"
import { formatProductRevenue } from "../../../../utils/formatters"
import monitorAPI from "../../../../services/monitor"
import ProductRevenue from "./../ProductRevenue"

export function SaleItemPos({ companyID, ranges }) {
	const [productRevenueData, setProductRevenueData] = useState([])
	const [productRevenueRange, setProductRevenueRange] = useState("bynow")

	useEffect(() => {
		let componentMounted = true

		monitorAPI
			.getMonitorData(companyID, "saleitemspos", productRevenueRange)
			.then((res) => res.data)
			.then(formatProductRevenue)
			.then((data) => componentMounted && setProductRevenueData(data))
			.catch((err) => console.error("[SaleItemPos]", err.message))

		return () => {
			componentMounted = false
		}
	}, [companyID, productRevenueRange])

	return (
		<ProductRevenue
			title={t("monitor.graph_products")}
			ranges={ranges}
			data={productRevenueData}
			selectedRange={productRevenueRange}
			setRange={(v) => setProductRevenueRange(v)}
		/>
	)
}
