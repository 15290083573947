import styled from 'styled-components'

export const CONTAINER = styled.div`
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-main);
    margin: 0 0 10px;

    h5 {
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        color: var(--color-dark);
        margin: 10px;
    }

    img {
        max-height: 13px;
        margin-left: 10px;
    }

    .dropdown_container{
        position: relative;
        min-height: 50px;
    }

    .dropdown_background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
   
    .dropdown_add_button {
        min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
        font-size: 20px;
    }

    .dropdown_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        max-width: 100%;
        min-height: 50px;
        margin: 4px 0;
        border: 1.5px solid lightgray;
        border-radius: var(--border-radius-small);
        padding: 6px;
    }
    

    .dropdown_item_container {
        position: absolute;
        margin-top: 16px;
        border: 1px solid lightgray;
        border-radius: var(--border-radius-medium);
        background: white;
        max-height: 250px;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        transition: 0.5s;
        overflow-y: auto;
        visibility: show;
    }

    .dropdown_item_container_closed{
        position: absolute;
        margin-top: 16px;
        visibility: hidden;
        border: 1px solid lightgray;
        border-radius: var(--border-radius-medium);
        background: white;
        max-height: 0px;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        transition: 0.3s;
        overflow: hidden;
    }

    input  {
        font-weight: var(--font-weight-small);
        font-size: 14px;
        color: var(--color-dark);
        width: calc(100% - 54px);
        outline: none;
        background: none;
        border: none;
    }

    input::placeholder {
        color: var(--color-darkgray);
    }

    .dropdown_item {
        min-height: 50px;
        display: flex;
        align-items: center;
        padding-left: 18px;
        font-size: 18px;
        color: gray;
        cursor: pointer;
    }
    
    .dropdown_item:hover {
        filter: brightness(90%);
        transition: 0.3s;
    }

    .dropdown_chosen_item {
        background: lightgray;
        padding: 10px;
        margin: 10px;
        z-index: 2;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;

        p {
            margin: 0;
        }
    }

`