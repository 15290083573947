import styled from 'styled-components'

export const Container = styled.div`
 	display: flex;
    background: var(--color-background);
    font-family: var(--font-family-primary);
	
	.content_container{
        height: 100vh;
        overflow: auto;
        flex: 1;
    }
	.form_container {
        background: var(--color-light);
        padding: 20px;
        margin: 0 30px 30px 30px;
        border-radius: var(--border-radius-medium);
        box-shadow: var(--box-shadow-card);
        overflow: visible;
    }
	.section_title {
        font-size: 18px;
        padding: 10px 0px 10px 10px;
        margin: 40px 0 20px;
        font-weight: 600;
        border-bottom: 1px solid var(--color-primary);

        &:first-child {
            margin-top: 0px;
        }
    }
	.column {
        flex: 1;
        margin-right: 20px;

        &:last-child {
            margin-right: 0px;
        }
    }
	.group_buttons {
		display: flex;
        align-items: center;
        justify-content: flex-start;
	}
`