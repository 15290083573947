import React, { useEffect, useState } from 'react'
import { CONTAINER } from './styles'

function DROPDOWN({ itemKey, itemID, items, title, selected, selectedKey, setSelected, styles }) {
	const [open, setOpen] = useState(false)
	const [itemsToDisplay, setItemsToDisplay] = useState([])
	const [itemsToChoose, setItemsToChoose] = useState([])

	const handleSelected = (v) => {
		let auxSelected = selected.map(s => s[itemID])
		setSelected(!auxSelected.includes(v[itemID]) ? selected.concat(v) : selected.filter((d) => v[itemID] !== d[itemID]))
		setOpen(false)
	}

	useEffect(() => {
		let auxSelected = selected.map(s => s[itemID])
		setItemsToDisplay(items.filter(i => auxSelected.includes(i[itemID])))
		setItemsToChoose(items.filter(i => !auxSelected.includes(i[itemID])))
	}, [itemID, items, selected])


	return (
		<CONTAINER style={{ ...styles }}>
			{title && <h5>{title}</h5>}
			<div className='dropdown_container'>
				<div className='dropdown_title' >
					{itemsToDisplay.length < 1 &&
						<div className='dropdown_add_button'>
							<ion-icon name='add-outline' />
						</div>

					}
					<div>
						{itemsToDisplay.map((i, key) =>
							<div key={key} onClick={() => handleSelected(i)} className='dropdown_chosen_item'>
								<p>
									{i[itemKey]}
								</p>
								<ion-icon name="close-outline"></ion-icon>
							</div>
						)}
					</div>
					<div className='dropdown_background' onClick={() => setOpen(!open)}></div>
				</div>
				{!itemKey && itemsToChoose && itemsToChoose.length > 0 &&
					<div className={open ? 'dropdown_item_container' : 'dropdown_item_container_closed'}>
						{itemsToChoose.map((i, key) =>
							<div key={key} onClick={() => handleSelected(i)} className='dropdown_item'>
								{i}
							</div>
						)}
					</div>
				}
				{itemKey && itemsToChoose && itemsToChoose.length > 0 &&
					<div className={open ? 'dropdown_item_container' : 'dropdown_item_container_closed'}>
						{itemsToChoose.map((i, key) =>
							<div key={key} className='dropdown_item' onClick={() => handleSelected(i)}>
								{i[itemKey]}
							</div>
						)}
					</div>
				}
			</div>
		</CONTAINER>
	)


}

export default DROPDOWN