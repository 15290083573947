/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { Table, DropdownInput, Dropdown, TextInput, Button, Toast, DatePicker } from '../../../shared_components'
import { Container } from './styles'
import { Nav, TopNav } from '../../components'
import t from '../../../config/translate'
import productApi from '../../../services/products'
import { isFloat, hasDecimalPlaces } from '../../../utils/numberValidator'
import moment from 'moment'
import estoqueAPI from '../../../services/estoque'
import { css } from "@emotion/react"
import { ClipLoader } from "react-spinners"

function Insert() {
	const whitelabel_name = useSelector(state => state.theme.whitelabel_name)
	const pageTitle = t('report.adjustment')
	const CompanyID = useSelector(state => state.auth.authedStore.CompanyID)
	const UserId = useSelector(state => state.auth.company.UserId)

	const [data, setData] = useState([])

	const statusInitalValue = { statusValue: '', statusKey: '0' }
	const [statusValue, setStatusValue] = useState(statusInitalValue)

	const qtdInitialValue = 0
	const [qtd, setQtd] = useState(qtdInitialValue)

	const productInitialValue = { ProductID: '', Name: '' }
	const [product, setProduct] = useState(productInitialValue)

	const productsInitialValue = []
	const [products, setProducts] = useState(productsInitialValue)

	const [date, setDate] = useState(new Date())

	const [loading, setLoading] = useState(false)

	//const status = [{ statusValue: 'Entrada', statusKey: '22' }, { statusValue: t('out'), statusKey: '23' }, { statusValue: 'Ajuste', statusKey: '' }]
	const status = [{ statusValue: 'Entrada', statusKey: '22' }, { statusValue: t('out'), statusKey: '23' }]

	const search = { show: false, searchPlaceHolder: t('search'), searchButtonClearText: t('clear') }

	const setInitialValue = () => {
		setQtd(qtdInitialValue)
		setProduct(productInitialValue)
		setProducts(productsInitialValue)
	}

	const dropDownInputProdutsOnChange = (e) => {
		if (e === '') setProducts([])
		if (e.trim().length === 4) productApi.searchProduct(CompanyID, e).then(rst => { setProducts(rst.data) })
		if (e.trim().length === 6) productApi.searchProduct(CompanyID, e).then(rst => { setProducts(rst.data) })
		if (e.trim().length === 8) productApi.searchProduct(CompanyID, e).then(rst => { setProducts(rst.data) })
	}

	const sameEntry = (row, entry) => (entry.ProductID === row.ProductID && entry.statusKey === row.statusKey)
	const handleInsertClick = async () => {
		let msg = ''
		if (product.ProductID === "") msg += "Necessário informar um produto!\n"
		if (statusValue.statusKey === "0") msg += "Necessário informar status!\n"
		if (!isFloat(qtd) || qtd === 0) msg += 'Necessário informar uma quantidade!'
		if (product.UnitTypeName && product.UnitTypeName.toUpperCase() !== 'KG' && hasDecimalPlaces(qtd)) msg += 'Produto não aceita unidade com casas decimais!'

		if (msg !== '') {
			Toast('Atenção', msg, 'danger')
			return
		}

		estoqueAPI.getProductEstoque(CompanyID, product.ProductID)
			.then(rst => {
				let nQtd = parseFloat(qtd.replace(',', '.'))
				let nStatusValue = JSON.parse(JSON.stringify(statusValue))
				if (statusValue.statusKey === '') {
					nQtd = (nQtd - rst.data.Quantidade).toFixed(2)
					nStatusValue.statusKey = '22'
					nStatusValue.statusValue = 'Ajuste(Entrada)'
					if (nQtd < 0) {
						nQtd = -nQtd
						nStatusValue.statusKey = '23'
						nStatusValue.statusValue = 'Ajuste(Saida)'
					}
					setQtd(nQtd)
				}

				let prods = data.slice()
				let row = { ...product, ...nStatusValue, quantity: nQtd, estoque: rst.data.Quantidade, tipo: nStatusValue.statusValue }
				let add = true
				for (let i = 0;i < prods.length;i++) {
					if (sameEntry(row, prods[i])) {
						prods[i].quantity = parseFloat(row.quantity) + parseFloat(prods[i].quantity)
						add = false
						break
					}
				}

				if (add) prods.push(row)
				setData(prods)

				setInitialValue()
			})
			.catch(err => {
				console.log(err)
			})
	}

	const handleDeleteRowClick = (row) => {
		let prods = data.slice()
		let nProds = []
		for (let i = 0;i < prods.length;i++) {
			if (!sameEntry(row, prods[i])) nProds.push(prods[i])
		}
		setData(nProds)
	}

	const createRow = (row, field) => <div className="table_row">{row[field]}</div>
	const options = (row) => (
		<div>
			<Button
				icon="trash-outline"
				onClick={() => handleDeleteRowClick(row)}
			/>
		</div>
	)

	const colums = [
		{
			name: <div className="table_header">Tipo</div>,
			selector: row => row['tipo'],
			cell: row => createRow(row, 'tipo'),
			sortable: true
		},
		{
			name: <div className="table_header">Produto</div>,
			selector: row => row['Name'],
			cell: row => createRow(row, 'Name'),
			sortable: true
		},
		{
			name: <div className="table_header">Quantidade</div>,
			selector: row => row['quantity'],
			cell: row => createRow(row, 'quantity'),
			sortable: true
		},
		{
			name: <div className="table_header">Estoque atual</div>,
			selector: row => row['estoque'],
			cell: row => createRow(row, 'estoque'),
			sortable: true
		},
		{
			name: <div className="table_header">Opções</div>,
			cell: row => options(row),
		}
	]

	const dropDownStatusOnChange = (e) => setStatusValue(e)
	const textInputQtdOnChange = (e) => setQtd(e)
	const dropDownProdutsOnChange = (e) => setProduct(e)

	const handleInsert = async () => {
		if (loading) {
			Toast('Atenção', 'Existe um processo já em andamento!', 'info')
			return
		}
		if (data.length === 0) return
		setLoading(true)

		let saleIn = {
			UsersID: UserId,
			DatetimeSale: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
			SaleStatus: '22',
			items: []
		}

		let saleOut = {
			UsersID: UserId,
			DatetimeSale: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
			SaleStatus: '23',
			items: []
		}

		data.forEach(item => {
			let qtd = parseFloat(item.quantity.toString().replace(',', '.'))
			let price = parseFloat(item.SalePrice.toString().replace(',', '.'))

			if (item.statusKey === '22') {
				saleIn.items.push({
					ProductID: item.ProductID,
					Quantity: qtd,
					PriceAmount: Math.round((qtd * price) * 100) / 100,
					DatetimeSaleItem: moment(date).format('YYYY-MM-DDTHH:mm:ss')
				})
			}

			if (item.statusKey === '23') {
				saleOut.items.push({
					ProductID: item.ProductID,
					Quantity: qtd,
					PriceAmount: Math.round((qtd * price) * 100) / 100,
					DatetimeSaleItem: moment(date).format('YYYY-MM-DDTHH:mm:ss')
				})
			}
		})

		const ins = await estoqueAPI.insertStock(CompanyID, saleIn)
		const out = await estoqueAPI.insertStock(CompanyID, saleOut)

		Promise.all([ins, out])
			.then(() => {
				setInitialValue()
				setData([])
				setStatusValue(statusInitalValue)
				Toast('Atenção', 'Entrada do estoque realizada!', 'info')
				setLoading(false)
			})
			.catch(err => {
				Toast('Atenção', 'Por favor, tente novamente!', 'danger')
				setLoading(false)
			})
	}


	const override = css
		`
		display: block;
		border-color: red;
	`

	return (
		<Container>
			<Helmet><title>{pageTitle} | {whitelabel_name}</title></Helmet>
			<Nav />
			<div className='content_container'>
				<TopNav pageTitle={pageTitle} />
				<div className='form_container'>
					<div className='add_container'>
						<DatePicker
							initialValue={date}
							setDate={setDate}
							title='Data'
						/>
						<Dropdown
							placeholder={t('select')}
							items={status}
							onChange={dropDownStatusOnChange}
							itemKey='statusValue'
							value={statusValue}
							styles={{ margin: 5, width: 300 }}
							title={t('status')}
						/>
						<DropdownInput
							placeholder={t('search')}
							items={products}
							onChange={dropDownProdutsOnChange}
							inputChange={dropDownInputProdutsOnChange}
							itemKey='Name'
							value={product}
							styles={{ margin: 5, width: 300 }}
							title={t('product')}
							showIconArrowDown={false}
						/>
						<TextInput
							styles={{ margin: 5, width: 200 }}
							value={qtd}
							onChange={textInputQtdOnChange}
							title={t('quantity')}
						/>
						<Button
							text={t('insert')}
							onClick={handleInsertClick}
							styles={{ margin: 5, marginTop: 37.5, width: 102 }}
						/>
					</div>

					<Table
						data={data}
						colums={colums}
						title={'Alteração'}
						search={search}
						noDataComponent={t('noEntry')}
						rowPerPage="5"
					/>

					<div className='send_container'>
						<Button
							text={t('DoStockAdjustment')}
							styles={{ margin: 5, width: 200 }}
							onClick={handleInsert}
						/>
						<ClipLoader color="#ffffff" loading={loading} css={override} size={20} />
					</div>
				</div>
			</div>
		</Container>
	)
}

export default Insert
