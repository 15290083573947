import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    background: var(--color-background);
    font-family: var(--font-family-primary);
    
    .content_container{
        height: 100vh;
        overflow: auto;
        flex: 1;
    }

    @media screen and (max-width: 1500px) {
        .react-bootstrap-table table {
            display: block;
            overflow-x: auto;
        }
    }

    .table_container {
        background: var(--color-light);
        padding: 20px;
        margin: 0 30px 30px 30px;
        border-radius: var(--border-radius-medium);
        box-shadow: var(--box-shadow-card);
        overflow: visible;
    }
    .table_title {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap-reverse;
        max-width: 100%
    }

    .excel-file-upload {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            margin: 10px 10px 10px 0px;
            width: 200px;
            text-align: center;
            min-width: 100px;
            outline: none;
            border: 0px;
            background: var(--color-primary);
            color: var(--color-light);
            text-transform: uppercase;
            font-weight: 600;
            border-radius: var(--border-radius-small);
            
            &:hover {
                cursor: pointer;
                opacity: 0.8;
                transition: 0.3s;
            }
        
            &:active {
                transition: 0.3s;
                opacity: 0.6;
            }
    }

    input[type='file'] {
        display: none;
    }

    .table_add_container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-width: 250px;
        button {
            width: 200px;
            margin: 10px 10px 10px 0;
        }
    }

    @media screen and (min-width: 830px) {
        .table_add_container {
            width: 60%;
        }
        .table_search_container  {
            width: 40%;
        }
    }

    .table_row{
        font-size: 14px;
    }
    .table_header{
        font-weight: var(--font-weight-medium);
        font-size: 16px;
    }

    .table_search_container {
        display: flex;
        align-items: center;
        
        label {
            min-width: 60%;
            margin: 0 20px;
        }


        button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            flex: 1;
            max-width: 200px;
            outline: none;
            border: 0px;
            background: var(--color-primary);
            color: var(--color-light);
            text-transform: uppercase;
            font-weight: 600;
            border-radius: var(--border-radius-small);
            
            &:hover {
                cursor: pointer;
                opacity: 0.8;
                transition: 0.3s;
            }
        
            &:active {
                transition: 0.3s;
                opacity: 0.6;
            }
        }

        @media screen and (max-width: 1050px) {
            flex-direction: column;
            align-items: flex-end;
            flex-wrap: wrap;
            button {
                max-width: 140px;
            }
            label {
                margin: 20px 0 10px;
            }
        }
     

    
    }

    .group_table {
        display: flex;
        flex-direction: column;
        justify-content: center;
     
        input {
            height: 40px;
        }
    
    }

    .company_select_column {
        @media screen and (min-width: 1000px) {
            margin: 0 40px;
        }

        flex: 2; 
        min-width: 200px;
    }

    @media screen and (min-width: 600px) {
        .product_dialog_content {
            width: 60vw;
        }
    }



    .dialog_input_container {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        button {
            margin: 0 0 20px;
        }

        .input_row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;
        }

        .column {
            flex: 1;
            margin-right: 20px;
            
            &:last-child {
                margin-right: 0px;
            }
        }

        .price_row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 20px;
            width: inherit;
            padding: 0 0 0 10px;
            p {
                min-width: 55%;
            }
        }

    }

    .image_selector {
        display: flex;
        margin-bottom: 20px;
        p {
            margin-left: 20px;
        }
    }

    .input_title {
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        color: var(--color-dark);
        margin: 10px;
    }

    .photo_multiple_companies { 
        margin: 40px 0;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column; 
    }

    .photo_single_company { 
        margin: 44px 0;
        justify-content: space-around;
        align-items: center;
        text-align: center;
    }

    .header_filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;
        min-width: 70px;

        p {
            margin-bottom: 0;
        }
    }

    .error_import_dialog {
        margin: 20px;
    }

`