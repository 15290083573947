import React, { useEffect, useState } from 'react'
import { Container } from './styles'
import { Nav, TopNav } from '../../components'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import t from '../../../config/translate'
import { withRouter } from 'react-router-dom'
import qrAPI from '../../../services/config'
import QRCode from 'qrcode.react'
import { Button, PhotoInput, Toast, WhiteButton } from '../../../shared_components'
import { QrPhone } from '../../../assets/images'
import { CopyToClipboard } from 'react-copy-to-clipboard';

function QrConfig({ companyID, whitelabel_name }) {

	const [menuLogo, setMenuLogo] = useState('')
	const [logo64, setLogo64] = useState('')
	const [qrCode, setQrCode] = useState('')

	useEffect(() => {
		let isMounted = true

		if (companyID) {
			qrAPI.getQrConfig(companyID)
				.then(res => res.data)
				.then((data) => {
					if (isMounted) {
						setQrCode(data.URL)
						setLogo64(data.QrCodeBase64)
						setMenuLogo(data.LogoCardapioBase64)
					}
				}).catch(() => Toast('', t('qr_config.get_error'), 'danger'))
		}
		return () => { isMounted = false }
	}, [companyID])

	const submitConfig = () => {
		qrAPI.saveQrConfig(companyID, menuLogo, qrCode).then((res) => {
			if (res.data === 200) {
				Toast('', t('success.update_data'), 'success')
			} else { Toast('', t('error.update_data'), 'danger') }
		})
	}

	const copiedSuccessful = () => {
		Toast('', 'Link copiado', 'success')
	}

	return (
		<Container>
			<Helmet>
				<title>{t('digital_menu')} | {whitelabel_name}</title>
			</Helmet>

			<Nav />

			<div className='content_container'>
				<TopNav pageTitle={t('digital_menu')} />
				<div className='form_container'>

					<div>
						<div>
							O cardápio digital é uma ferramenta que garante agilidade e segurança aos seus clientes e funcionários.<br /><br />

							O cliente poderá acessar o cardápio pelo próprio celular e não precisará manusear o cardápio físico. Veja como é simples:<br />
							<div style={{ display: 'flex', margin: '10px 0', flexWrap: 'wrap' }}>
								<p style={{ whiteSpace: 'nowrap' }}>- Faça o download</p>
								{logo64 &&
									<a href={"data:image/png;base64," + logo64} download="Menu-QRCode.png">
										<WhiteButton icon='download-outline' />
									</a>
								}
								<p>do arquivo do <b>QRCode</b>, produza uma arte com a sua logomarca e coloque de forma visível aos seus clientes.<br /><br /></p>
							</div>




							Além disso você pode divulgar seu cardápio digital de várias formas:<br />
							<div style={{ display: 'flex', margin: '10px 0', flexWrap: 'wrap' }}>
								<p>- Copie o <b>Link</b></p>

								<CopyToClipboard text={qrCode}>
									<WhiteButton icon='copy-outline' onClick={() => copiedSuccessful()} styles={{ margin: '0', width: 35, height: 25 }} />
								</CopyToClipboard>

								<p>do seu cardápio digital e publique na Bio do seu Instagram/Facebook ou envie para os seus clientes pelo WhatsApp<br /><br /></p>
							</div>

						</div>
					</div>

					<div style={{ display: 'flex', flexWrap: 'wrap' }}>


						<div className='column'>
							<p className='section_title'>{t('qr_config.menu_config')}</p>
							<p className='input_title'>{t('qr_conifg.menu_logo')}</p>
							<PhotoInput styles={{ display: 'flex', marginBottom: 20 }} textStyles={{ marginLeft: 20 }} preview={menuLogo} setPreview={setMenuLogo} />
						</div>


						<div className='column'>

							<p className='section_title'>QR Code</p>
							<div style={{ alignItems: 'flex-end', marginBottom: 20 }} className='input_row'>
								{qrCode &&
									<div style={{ flex: 2 }} className='column'>
										<p>Este é o QR Code do seu cardápio digital. Copie o link ou baixe o QR Code para compartilhar com todos! </p>
										<div style={{ display: 'flex', flexWrap: 'wrap' }}>
											<p ><b>Link:</b> {qrCode}</p>
											<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', minWidth: 150 }}>

												<CopyToClipboard text={qrCode}>
													<WhiteButton icon='copy-outline' onClick={() => copiedSuccessful()} styles={{ margin: '0 16px', width: 35 }} />
												</CopyToClipboard>
												{logo64 &&
													<a href={"data:image/png;base64," + logo64} download="Menu-QRCode.png">
														<WhiteButton icon='download-outline' />
													</a>
												}

											</div>
										</div>
									</div>
								}
							</div>
							<div className='phone_qr_container'>
								<img src={QrPhone} alt='Phone with Menu' style={{ margin: '0 20px 20px 0' }} />

								{qrCode && <QRCode id={'qr-menu'} value={qrCode} style={{ height: 150, width: 150, margin: '0 0 20px 0px' }} />}

							</div>

						</div>

					</div>

					<Button onClick={() => submitConfig()} text={t('save')} />
				</div>
			</div>

		</Container>
	)
}



function mapStateToProps({ auth, theme }) {

	return {
		companyID: auth.authedStore ? auth.authedStore.CompanyID : '',
		companyGroupID: auth.authedStore ? auth.authedStore.CompanyGroupID : '',
		token: auth.tokens ? auth.tokens.token : '',
		whitelabel_name: theme.whitelabel_name || 'POS CONTROLE',
	}
}

export default withRouter(connect(mapStateToProps)(QrConfig))

