import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;

    p {
        margin: 0 20px;
    }

    .range_button {
        margin-left: 20px;
    }

    @media screen and (max-width: 800px) {
        flex-wrap: wrap;
        justify-content: flex-end;

        p {
            margin: 20px;
        }

    
    }
`