import React, { useEffect, useState } from "react";
import { BarGraph } from "../../../components";
import t from "../../../../config/translate";
import monitorAPI from "../../../../services/monitor";

export function TktMedio({ companyID, ranges }) {
  const [avgTicketData, setAvgTicketData] = useState([]);
  const [avgTicketRange, setAvgTicketRange] = useState("bynow");

  useEffect(() => {
    let componentMounted = true;

    monitorAPI
      .getMonitorData(companyID, "saleitemstktmedio", avgTicketRange)
      .then((res) => res.data)
      .then((data) => componentMounted && setAvgTicketData(data))
      .catch((err) => console.error("[TktMedio]", err.message));

    return () => {
      componentMounted = false;
    };
  }, [companyID, avgTicketRange]);

  return (
    <BarGraph
      data={avgTicketData}
      title={t("monitor.graph_avg_ticket")}
      categoryX="XLine"
      valueY="TktMedio"
      id={"saleitemstktmedio"}
      ranges={ranges}
      selectedRange={avgTicketRange}
      setRange={(v) => setAvgTicketRange(v)}
    />
  );
}
