import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
`
export const Background = styled.div`
    min-height: 100vh;
    background: lightgray;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100vw;
    height: 100%;
    z-index: 998;
`

export const DialogPopUp = styled.div`
    background: var(--color-light);
    border-radius: var(--border-radius-medium);
    width: 90%;
    max-height: 90vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: var(--color-dark);
    overflow: hidden;
    z-index: 999;

    @media screen and (min-width: 600px){
        margin: 0 auto;
        max-width: fit-content;
    }

    .dialog_title_container {
        width: 100%;
        color: var(--color-light);
        padding: 20px;
        background: var(--color-primary);
   
        h3 {
            margin: 0;
        }
    }

    .dialog_title {
        font-weight: 500;
        margin: 0 0px 20px 10px;
    }


    .dialog_content_container {
        max-height: 80vh;
        overflow: auto;
        padding: 20px;
    }



    .close_button {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 36px;
        height: 36px;
        padding: 0;
        color: var(--color-dark);
        border-radius: 16px;

        ion-icon {
          font-size: 24px;
        }
    }

    .dialog_section_title {
        font-size: 18px;
        padding: 10px 0px 10px 10px;
        margin: 40px 0 20px;
        font-weight: 600;
        border-bottom: 1px solid var(--color-primary);
    }
`
