import React, { useState } from 'react'
import { CONTAINER } from './styles'

function DROPDOWN({ styles, Children, open, setOpen}) {
    

    return (
        <CONTAINER style={{ ...styles }}>
            <div className='dropdown_container'>
                <div className='dropdown_title' onClick={() => setOpen(!open)}>
                    <ion-icon name="filter-outline"></ion-icon>
                </div>
              
                    <div className={open ? 'dropdown_item_container' : 'dropdown_item_container_closed'}>
                        {Children}
                    </div>
                
            </div>
        </CONTAINER>
    )


}

export default DROPDOWN