import React, { useState, useRef, useLayoutEffect } from 'react'
import { Container } from './styles'
import { Pill } from '../../../shared_components'
import AmCharts from "@amcharts/amcharts3-react";
import { useWindowSize } from '../../../hooks'
import t from '../../../config/translate'

function PieChart({ data, valueY, categoryX, setRange, ranges, id, title, selectedRange, subTitle }) {

    const [expanded, setExpanded] = useState(true)
    const [fullScreen, setFullScreen] = useState(false)
    const windowSize = useWindowSize()
    const config = {
        "type": "pie",
        "theme": "light",
        "dataProvider": data,
        "titleField": title,
        "valueField": valueY,
        "labelRadius": 5,
        "labelText": `[[${categoryX}]]`,
        "export": {
            "enabled": true
        },
        hideCredits: true,
    }

    const getGraphState = () => {
        let graphState = expanded ? 'bar_container_opened' : 'bar_container_closed'
        if (fullScreen) {
            graphState = 'full_screen_graph'
        }
        return graphState
    }

    return (
        <Container>
            <div className={getGraphState()}>
                <div className='bar_title_container'>
                    <div className='container_line'>
                        <div className='bar_title'>
                            <h5>{title}</h5>
                            {subTitle && <p>{subTitle}</p>}
                        </div>
                        <div className='bar_title_options'>
                            {windowSize.width > 1450 && <div className='bar_pill_container'>
                                {ranges && ranges.map((r, key) =>
                                    <Pill selected={selectedRange === r.value} key={key} text={r.name} onClick={() => setRange(r.value)} />
                                )}

                            </div>}


                            <div className='bar_graph_arrow_down' onClick={() => { setFullScreen(!fullScreen); setExpanded(true) }}>
                                <ion-icon name='resize-outline' />
                            </div>
                            {!fullScreen &&
                                <div className='bar_graph_arrow_down' onClick={() => setExpanded(!expanded)}>
                                    <ion-icon name='chevron-down-outline' />
                                </div>
                            }

                        </div>
                    </div>

                    {windowSize.width <= 1450 && <div className='bar_pill_container'>
                        {ranges && ranges.map((r, key) =>
                            <Pill selected={selectedRange === r.value} key={key} text={r.name} onClick={() => setRange(r.value)} />
                        )}

                    </div>}

                </div>


                <div className='bar_graph_container'>
                    <AmCharts.React style={{ width: "100%", height: data.length > 0 ? "400px" : '0px' }} options={config} hideCredits={true} />
                </div>
                {data.length === 0 &&
                    <div className='bar_graph_container'>
                        {t('monitor_graph.no_data')}
                    </div>
                }

            </div>


        </Container>
    )
}

export default PieChart