import React from 'react'
import {Container} from './styles'

function Button({text, id, tabIndex, type='button', styles, className, onClick, selected}) {
    return (
        <Container style={{background: selected && 'var(--color-accent-secondary)', color: selected && 'var(--color-accent-secondary-text)' }} 
                    id={id} tabIndex={tabIndex} type={type} onClick={onClick} className={className} styles={{...styles}}>
            <p>{text}</p>
        </Container>
    )
}

export default Button
