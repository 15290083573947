/* eslint-disable import/no-anonymous-default-export */
import base from './base'
export default {
	getRole(token) {
		if (token) return base.get(`/api-con/user/getRole`)
		else return new Promise((resolve) => resolve({ role: '' }))
	},
	getByCompany(companyID) {
		return base.get(`/api-con/user/company/${companyID}/getByCompany`)
	}
}