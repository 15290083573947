/* eslint-disable import/no-anonymous-default-export */
import base from './base'

export default {
	getSalesByGroups(companyID, initialDate, finalDate) {
		return base.get(`/api-con/company/${companyID}/reports/salesbygroup?initialDate=${initialDate}&finalDate=${finalDate}`)
	},
	getSalesByDay(companyID, initialDate, finalDate) {
		return base.get(`/api-con/company/${companyID}/reports/salesbyday?initialDate=${initialDate}&finalDate=${finalDate}`)
	},
	getClosingRegister(companyID, initialDate, finalDate) {
		return base.get(`/api-con/company/${companyID}/reports/cashierclosing?initialDate=${initialDate}&finalDate=${finalDate}`)
	},
	getFaturamentoProdutosPorPosFaturado(companyID, initialDate, finalDate) {
		return base.get(`/faturamento/reports/${companyID}/faturamento-produtos-por-pos-faturado?data_inicio=${initialDate}&data_fim=${finalDate}`)
	},
	getFaturamentoProdutosPorPosCourtesy(companyID, initialDate, finalDate) {
		return base.get(`/faturamento/reports/${companyID}/faturamento-produtos-por-pos-cortesia?data_inicio=${initialDate}&data_fim=${finalDate}`)
	},
	getFaturamentoProdutosBaixadosPorUsuario(companyID, initialDate, finalDate) {
		return base.get(`/faturamento/reports/${companyID}/faturamento-produtos-baixados-por-usuario?data_inicio=${initialDate}&data_fim=${finalDate}`)
	},
	getFaturamentoRankingPos(companyID, initialDate, finalDate) {
		return base.get(`/faturamento/reports/${companyID}/faturamento-ranking-pos?data_inicio=${initialDate}&data_fim=${finalDate}`)
	},
	getExtratoCashlessOnline(companyID, initialDate, finalDate) {
		return base.get(`/cashless/reports/${companyID}/extrato-cashless-online?data_inicio=${initialDate}&data_fim=${finalDate}`)
	},
	getListagemDeCredito(companyID, initialDate, finalDate) {
		return base.get(`/cashless/reports/${companyID}/listagem-de-credito?data_inicio=${initialDate}&data_fim=${finalDate}`)
	},
	getDetailedSales(companyID, initialDate, finalDate, page, perPage) {
		return base.get(`/api-con/company/${companyID}/reports/datailedsales?initialDate=${initialDate}&finalDate=${finalDate}&pag=${page}&range=${perPage}`)
	},
	getCanceledSales(companyID, initialDate, finalDate, page, perPage) {
		return base.get(`/api-con/company/${companyID}/reports/canceledSales?dateIni=${initialDate}&dateEnd=${finalDate}&pag=${page}&range=${perPage}`)
	},
	getBarCodeSales(companyID, initialDate, finalDate, page, perPage) {
		return base.get(`/api-con/company/${companyID}/reports/barCode?dateIni=${initialDate}&dateEnd=${finalDate}&pag=${page}&range=${perPage}`)
	},
	getListSaleIdentificaidor(companyID, initialDate, finalDate, page, perPage) {
		return base.get(`/api-con/company/${companyID}/reports/listIdentificador?dateIni=${initialDate}&dateEnd=${finalDate}&pag=${page}&range=${perPage}`)
	},
	getPayamentTypeSale(companyID, initialDate, finalDate, page, perPage) {
		return base.get(`/api-con/company/${companyID}/reports/payamentType?dateIni=${initialDate}&dateEnd=${finalDate}&pag=${page}&range=${perPage}`)
	},
	getSearchByIdSale(companyID, identificador) {
		return base.get(`/api-con/company/${companyID}/reports/serchById?identificador=${identificador}`)
	}
}