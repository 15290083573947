import styled from 'styled-components'

export const Container = styled.div`
 
        
    @media screen and (min-width: 1000px) {
        width: 48%;
        min-width: 400px;
    }

    @media screen and (min-width: 360px) and (max-width: 999px) {
        width: 100%;
        min-width: 350px;
    }

    @media screen and (max-width: 360px) {
        min-width: 300px;
    }

    .full_screen_graph {
        position: fixed;
        top: 0;
        left: 0;
        background: var(--color-light);
        padding: 50px;
        transition: 0.5s;
        height: 100vh;
        width: 100vw;
        z-index: 1000;
    }

  
    .bar_container_closed {
        background: rgba(255, 255, 255, 0.5);
        padding: 20px;
        margin: 0 30px 30px 30px;
        min-width: 320px;
        border-radius: var(--border-radius-medium);
        box-shadow: var(--box-shadow-card);
        overflow: hidden;

        .bar_title_container {
            border-bottom: none;
            transition: 0.5s;
        }

        .bar_graph_container {
            max-height: 0px;
            overflow: hidden;
            transition: 0.3s;
        }
    }

    .bar_container_opened {    
        max-width: 100%;
        background: var(--color-light);
        padding: 20px;
        margin: 0 30px 30px 30px;
        border-radius: var(--border-radius-medium);
        box-shadow: var(--box-shadow-card);
        overflow: visible;

        .bar_title_container {
            border-bottom: 1px solid lightgray;
            margin-bottom: 20px;
            transition: 0.5s;
        }
        .bar_graph_container {
            max-height: 500px;
            transition: 0.3s;
        }

        @media screen and (max-width: 400px) {
            margin: 0 2% 30px;
        }
        

    }


    .bar_title_container {
        width: 100%;
        padding-bottom: 10px;

        .container_line {
            display: flex;
            align-items: center; 
            justify-content: space-between;
        }

        .bar_title {
            display: flex;
            align-items: center;

            h5 {
                font-weight: 500;
                font-size: 18px;
            }
            p {
                font-size: 12px;
                color: gray;
                margin: 6px 0 0 6px;
            }
        }
        
        .bar_title_options {
            display: flex;
            max-width: 90%;
            align-items: center;
            justify-content: flex-end;
        }

        .bar_pill_container {
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 400px) {
                flex-wrap: wrap;
            }
    
        }
    }

    .bar_graph_arrow_down {
        padding: 10px;
        cursor: pointer;

    }



`