import React from 'react'
import { Container } from './style'

export default function PrintFicha({ printValues }) {
    return (
        <Container>
            <p>==============================</p>
            <img src={"data:image/png;base64," + printValues.logo} />
            <p className='header1'>{printValues.header1.Value}</p>
            <p className='header2'>{printValues.header2.Value}</p>
            <div className='productName'>Hamburguer</div>
            <p className='price'>R$15,00</p>
            <p className='message1'>{printValues.message1.Value}</p>
            <p className='message2'>{printValues.message2.Value}</p>
            <p className='message3'>{printValues.message3.Value}</p>


            <p>------------  1/1  ------------</p>



            <p>21/09/2021   14:48:27   V1.8.8</p>
            <p>00.000.000/0001-00    DINHEIRO</p>
        </Container>
    )
}