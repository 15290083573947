import React from 'react'
import DatePicker from '../date_picker'
import { Container } from './styles'
import moment from 'moment'
import Button from '../button'
import { useWindowSize } from '../../hooks'

function DateRange({ setInitialDate, setFinalDate, finalDate, initialDate, submit, mindate }) {
	const size = useWindowSize()
	return (
		<Container>
			{mindate !== undefined ? <DatePicker setDate={setInitialDate} minDate={mindate} maxDate={finalDate} placeholder={moment().format('DD/MM/YYYY')} />
				: <DatePicker setDate={setInitialDate} maxDate={finalDate} placeholder={moment().format('DD/MM/YYYY')} />
			}
			<p>até</p>
			<DatePicker setDate={setFinalDate} minDate={initialDate} maxDate={moment()} placeholder={moment().format('DD/MM/YYYY')} />
			{size.width > 800
				? <Button className='range_button' text={'Atualizar'} onClick={submit} />
				: <Button className='range_button' styles={{ maxWidth: 50, padding: 10 }} icon='refresh-outline' onClick={submit} />}
		</Container>

	)
}

export default DateRange